// Chakra Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Text, Button, Heading, useToast } from "@chakra-ui/react";

// Component Imports
import CommonHeader from "../common/CommonHeader";
import welcomeBg from "../../images/genz/welcomeBg.png";
import { toastFunctionToaster } from "../../utils/toasterFunction";
import useResponsiveHeight from "../../customHook/ResponsiveHeight";
import { getEmailId, getUserName } from "../../utils/localStorageIndex";
import {
	syBlack,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../constants/color";

// Api Imports
import { getBaseInfo } from "../../services/userServices";

function Dashboard() {
	const toast = useToast();
	const emailId = getEmailId();
	const userName = getUserName();
	const navigate = useNavigate();
	const height = useResponsiveHeight();
	const [loading, isLoading] = useState(false);

	const checkBaseUploadedDetails = () => {
		isLoading(true);
		let obj = {
			key: emailId,
		};
		getBaseInfo(obj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.preference_data?.preference_uuid) {
					navigate("/genz/complete", { state: { editGoal: true } });
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					navigate("/genz/details");
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	return (
		<Box
			mx="auto"
			h={height}
			bg={syThemeBgYellowColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
		>
			<Box p="30px">
				<CommonHeader />
				<Flex
					alignContent={"center"}
					justifyContent={"center"}
					flexWrap={"wrap"}
					textAlign={"center"}
					mt="4rem"
				>
					<Heading
						w="100%"
						color={syBlack}
						fontSize={"22px"}
						mb="3"
						textAlign={"center"}
					>
						Hi!{" "}
						<Text
							as={"span"}
							textAlign="center"
							mb="2"
							fontWeight={"700"}
						>
							{userName}
						</Text>
					</Heading>
					<Heading
						fontSize={"22px"}
						fontWeight={"700"}
						color={syBlack}
					>
						Welcome to{" "}
						<Text as="span" color={syThemeBgBlueColor}>
							Syval!
						</Text>
					</Heading>
					<Heading
						fontSize={"16px"}
						fontWeight={"700"}
						color={syBlack}
						mt="3rem"
					>
						Master Your
						<Text as="span" mx="1" color={syThemeBgBlueColor}>
							Money,
						</Text>
						Own Your
						<Text as="span" mx="1" color={syThemeBgBlueColor}>
							Life
						</Text>
					</Heading>
				</Flex>
			</Box>
			<Flex
				h="600px"
				alignItems={"center"}
				justifyContent={"center"}
				textAlign={"center"}
				bgImage={welcomeBg}
				bgRepeat="no-repeat"
				bgSize="cover"
			>
				<Button
					borderRadius={"50px"}
					size="lg"
					colorScheme="purple"
					onClick={() => {
						checkBaseUploadedDetails();
					}}
					isDisabled={loading ? true : false}
					isLoading={loading ? true : false}
				>
					Continue
				</Button>
			</Flex>
		</Box>
	);
}

export default Dashboard;
