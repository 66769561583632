// Chakra - React Imports
import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { Box, Img, Text, Flex, useToast, Spinner } from "@chakra-ui/react";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syThemeBgYellowColor,
	syGrey,
	syWhite,
	syBlack,
} from "../../../constants/color";

// Api Imports
import { fetchUsersWithPlan } from "../../../services/sharedServices";

function UsersGoalList({ userId }) {
	const toast = useToast();
	const [loading, isloading] = useState(false);
	const [userList, setUserList] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedUserId, setSelectedUserId] = useState("");

	useEffect(() => {
		fetchUserGoalPlans();
	}, []);

	const fetchUserGoalPlans = () => {
		isloading(true);
		let obj = {
			filter_key: "string",
			filter_value: "string",
			records_per_page: 10,
			page_id: currentPage,
		};
		fetchUsersWithPlan(obj)
			.then(res => {
				isloading(false);
				if (res?.result && res.data.length > 0) {
					setUserList(res?.data);
					setTotalPages(res?.total_pages);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setUserList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isloading(false);
				setUserList([]);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	function renderUserDetails() {
		if (userList && userList.length > 0) {
			var userGoals = userList.map((data, index) => (
				<Flex
					justifyContent={"space-between"}
					color={syWhite}
					p="8px"
					borderRadius={"5px"}
					mb="3"
					alignItems={"center"}
					bg={syBlack}
					cursor={"pointer"}
					onClick={() => {
						userId(data?.user_uuid);
						setSelectedUserId(data?.user_uuid);
					}}
				>
					<Box w="30%">
						<Text
							fontSize={"12px"}
							fontWeight={"600"}
							color={
								selectedUserId === data?.user_uuid
									? "green.500"
									: null
							}
						>
							{data?.user_email}
						</Text>
					</Box>
					<Flex
						w="68%"
						ml="2%"
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Text
							w="23%"
							textAlign={"center"}
							bg="purple.500"
							p="5px"
							borderRadius={"5px"}
							fontSize={"12px"}
							fontWeight={"600"}
						>
							{data?.total_goal_plans}
						</Text>
						<Text
							w="23%"
							textAlign={"center"}
							bg="yellow.500"
							p="5px"
							borderRadius={"5px"}
							fontSize={"12px"}
							fontWeight={"600"}
						>
							{data?.active_count}
						</Text>
						<Text
							w="23%"
							textAlign={"center"}
							bg="green.500"
							p="5px"
							borderRadius={"5px"}
							fontSize={"12px"}
							fontWeight={"600"}
						>
							{data?.completed_count}
						</Text>
						<Text
							w="23%"
							textAlign={"center"}
							bg="red.500"
							p="5px"
							borderRadius={"5px"}
							fontSize={"12px"}
							fontWeight={"600"}
						>
							{data?.canceled_count}
						</Text>
					</Flex>
				</Flex>
			));
			return userGoals;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text
						mb="5px"
						fontSize={14}
						fontWeight={"600"}
						color={syThemeBgYellowColor}
					>
						{"No Data Found!!"}
					</Text>
				</Box>
			);
		}
	}

	return (
		<div>
			<Box
				mt="3"
				h="calc(100vh - 20vh)"
				overflow="auto"
				css={{
					"&::-webkit-scrollbar": {
						width: "4px",
					},
					"&::-webkit-scrollbar-track": {
						width: "6px",
					},
					"&::-webkit-scrollbar-thumb": {
						background: { syGrey },
						borderRadius: "24px",
					},
				}}
			>
				<Flex
					justifyContent={"space-between"}
					color={syWhite}
					p="5px"
					// borderWidth={"1px"}
					// borderColor={syBorderGrey}
					borderRadius={"5px"}
					mb="3"
					alignItems={"center"}
				>
					<Box w="30%">
						<Text
							textAlign={"left"}
							bg="grey.500"
							p="5px"
							borderRadius={"5px"}
							color={syWhite}
							fontSize={"12px"}
							fontWeight={"600"}
						>
							Email Id
						</Text>
					</Box>
					<Flex
						w="70%"
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						{" "}
						<Text
							textAlign={"center"}
							bg="purple.500"
							p="5px"
							borderRadius={"5px"}
							mr="1"
							fontSize={"12px"}
							fontWeight={"600"}
						>
							Total
						</Text>
						<Text
							textAlign={"center"}
							bg="yellow.500"
							p="5px"
							borderRadius={"5px"}
							mr="1"
							fontSize={"12px"}
							fontWeight={"600"}
						>
							Active
						</Text>
						<Text
							textAlign={"center"}
							bg="green.500"
							p="5px"
							borderRadius={"5px"}
							mr="1"
							fontSize={"12px"}
							fontWeight={"600"}
						>
							Completed
						</Text>
						<Text
							textAlign={"center"}
							bg="red.500"
							p="5px"
							borderRadius={"5px"}
							mr="1"
							fontSize={"12px"}
							fontWeight={"600"}
						>
							Cancel
						</Text>
					</Flex>
				</Flex>
				{loading ? (
					<Flex alignItems="center" justifyContent="center" h="50vh">
						<Spinner size="xl" color={syWhite} />
					</Flex>
				) : (
					renderUserDetails()
				)}
				{totalPages > 1 && (
					<Box>
						<ResponsivePagination
							current={currentPage}
							total={totalPages}
							onPageChange={pageNumber => {
								setCurrentPage(pageNumber);
							}}
						/>
					</Box>
				)}
			</Box>
		</div>
	);
}

export default UsersGoalList;
