// React Chakra Imports
import { useNavigate } from "react-router-dom";
import { AiOutlineLock, AiOutlineSearch } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { Img, Box, Flex, Text, Input, Modal, Button, Grid, Icon, Heading, Select, GridItem, Spinner, useToast, ModalBody, InputGroup, ModalOverlay, ModalContent, ModalHeader, useDisclosure, ModalCloseButton, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import lockImg from "../../../images/genz/goal/lock.png";

// Component Imports
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import CommonHeader from "../../common/CommonHeader";
import CommonWidget from "../../common/CommonWidget";
import FooterProfile from "../../common/FooterProfile";
import { getUserName } from "../../../utils/localStorageIndex";
import UploadRewardImg from "../../../images/genz/upload-reward.png";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import { syGrey, syBlack, syPurple, syWhite, syBorderGrey, syThemeBgBlueColor, syThemeBgYellowColor } from "../../../constants/color";

// Api Imports
import { createGoalPlan, fetchUserGoalList, fetchGoalRewardIcon } from "../../../services/goalServices";
import UploadRewardImage from "./UploadRewardImage";
import UploadRewardImageCrop from "./UploadRewardImageCrop";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight";

function CreateGoal() {
	const toast = useToast();
	const navigate = useNavigate();
	const userName = getUserName();
	const [error, setError] = useState("");
	const height = useResponsiveHeight();
	const [goalName, setGoalName] = useState("");
	const [loading, isLoading] = useState(false);
	const [goalList, setGoalList] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedValue, setSelectedValue] = useState("");
	const [selectedGoalName, setSelectedGoalName] = useState("");
	const [rewardIconData, setRewardIconData] = useState([]);
	const [labelerPrompts, setLabelerPrompts] = useState([]);
	const [goalOptionValue, seGoalOptionValue] = useState([]);
	const [rewardLoading, setRewardLoading] = useState(false);
	const [selectedGoalErr, setSelectedGoalErr] = useState("");
	const [selectedRewardIcon, setSelectedRewardIcon] = useState(null);
	const [rewardIconSearchKey, setRewardIconSearchKey] = useState("");

	useEffect(() => {
		fetchGoalList();
	}, []);

	const fetchGoalList = () => {
		isLoading(true);
		let obj = {
			filter_key: "string",
			filter_value: "string",
			enabled: true,
			records_per_page: 100,
			page_id: 0,
		};
		fetchUserGoalList(obj)
			.then(res => {
				isLoading(false);
				if (res?.data?.[0]?.total_records > 0) {
					setGoalList(res?.data?.[0]?.records);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	useEffect(() => {
		if (selectedValue?.length) {
			fetchGoalReward();
		}
	}, [selectedValue]);

	const fetchGoalReward = () => {
		// setRewardLoading(true);
		let obj = {
			filter_key: "goal_uuid",
			filter_value: selectedValue,
		};
		fetchGoalRewardIcon(obj)
			.then(res => {
				setRewardLoading(false);
				if (res?.result && res?.data?.[0]?.icons?.length) {
					setRewardIconData(res?.data?.[0]?.icons);
				} else {
					setRewardIconData([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				setRewardLoading(false);
				setRewardIconData([]);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	useEffect(() => {
		if (goalList?.length) {
			const cloneArr = [];
			const cloneData = [...goalList];
			cloneData?.forEach((data, index) => {
				let obj = { name: data?.goal_name, id: data?.goal_uuid };
				cloneArr?.push(obj);
			});
			seGoalOptionValue(cloneArr);
		}
	}, [goalList]);

	const saveGoalDetails = () => {
		if (!goalName || !selectedValue) {
			if (!goalName) {
				setError("Goal name is required.");
			} else {
				setError("");
			}

			if (!selectedValue) {
				setSelectedGoalErr("Please select a goal.");
			} else {
				setSelectedGoalErr("");
			}

			return;
		}

		setError("");
		setSelectedGoalErr("");
		let obj = {
			goal_uuid: selectedValue,
			goal_name: goalName,
			goal_reward_icon: selectedRewardIcon ? selectedRewardIcon : "",
			goal_tags: labelerPrompts ? labelerPrompts.join(",") : [],
		};
		isLoading(true);
		createGoalPlan(obj)
			.then(res => {
				if (res?.data?.length) {
					isLoading(false);
					navigate("/genz/setup-goal", {
						state: {
							data: obj,
							goalPlanningId: res?.data[0]?.goal_planning_uuid,
						},
					});
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					isLoading(false);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);

				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const renderRewardIcon = () => {
		if (rewardIconData?.length) {
			let redIconData = rewardIconData;
			if (rewardIconSearchKey != null && rewardIconSearchKey.length >= 3) {
				redIconData = rewardIconData.filter(item => item?.goal_details?.toLowerCase()?.includes(rewardIconSearchKey?.toLowerCase()));
			}
			return redIconData.map((data, index) => {
				return (
					<Box
						w="30%"
						rounded={"lg"}
						borderColor={
							data?.goal_reward_icon === selectedRewardIcon
								? "#CCCCFF" //syPurple //"#fdc528"
								: syWhite
						}
						bg={
							data?.goal_reward_icon === selectedRewardIcon
								? "#CCCCFF" // syPurple //"#fdc528"
								: syWhite
						}
						onClick={() => {
							setSelectedRewardIcon(data?.goal_reward_icon);
						}}
						h={"100px"}
						// w={'100px'} >
					>
						<Box position={"relative"}>
							<Img
								key={index}
								// w="80px"
								// h="80px"
								// mb="2"
								// mr="2"
								// _hover={{'bg': syPurple, cursor:'pointer'}}
								objectFit={"contain"}
								src={data?.goal_reward_icon}
								//borderRadius={"10px"}
								// border={"2px"}
								padding={"1px"}
							/>
							<Box position={"absolute"} top="30px" left={"38%"}>
								<Img w="50%" src={lockImg}></Img>
							</Box>
						</Box>
						{/* <Icon as={AiOutlineLock} h={12} w={12} color="gray.500" />  */}
						{/* <Icon 
								as={AiOutlineLock} h={12} w={12}
								//pos="relative" 
								top="20%" left="50%" 
								color="red" 
								transform="translate(-50%,-50%)"
							/> */}
					</Box>
				);
			});
		}
	};

	const refreshAfterUpload = value => {
		if (value === "true") {
			fetchGoalReward();
			onClose();
		}
	};

	return (
		<Box
			// bg={syThemeBgYellowColor}
			bgGradient={["linear(to-b, #f1ffbd, #DAF66F)"]}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			minH={height}
			mx="auto"
			mb="4rem"
		>
			<Box px="20px" py="30px" pb={20}>
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box
					h={"100%"}
					mt="1rem"
					px="20px"
					py="30px"
					// bg={syThemeBgYellowColor}
					borderRadius={"8px"}
				>
					<Heading fontSize={"22px"} color={syBlack} textAlign={"center"}>
						Hi!{" "}
						<Text as="span" fontWeight={"700"}>
							{userName}
						</Text>
					</Heading>
					<Heading fontSize={"22px"} color={syBlack} textAlign={"center"} mt="1rem">
						Let’s{" "}
						<Text color={syPurple} as="span" fontWeight={"700"}>
							set up your goal!
						</Text>
					</Heading>
					<Box my="2rem">
						<Heading fontSize={"18px"} fontWeight={"700"} color={syBlack} mb="1rem" textAlign={"center"}>
							Name your goal
						</Heading>
						<Input bg="#fff" h="50px" type="text" value={goalName} borderWidth="1px" boxShadow="lg" borderColor={syBorderGrey} placeholder="Enter Your Goal" _hover={{ boxShadow: "none" }} onChange={e => setGoalName(e.target.value)} />
						{error && (
							<Text color="red.500" fontSize="14px" fontWeight={"bold"} mt="1rem">
								{error}
							</Text>
						)}
					</Box>
					<Box mb="2rem">
						<Heading fontSize={"18px"} fontWeight={"700"} color={syBlack} mb="1rem" textAlign={"center"}>
							What’s it about?
						</Heading>
						<Select
							bg={syWhite}
							h="50px"
							placeholder="Select goal"
							value={selectedValue}
							borderWidth="1px"
							boxShadow="lg"
							borderColor={syBorderGrey}
							onChange={e => {
								setSelectedValue(e.target.value);
								setRewardLoading(true);
								const selectedGoal = goalOptionValue?.find(goal => goal.id === selectedValue);
								setSelectedGoalName(selectedGoal?.name || "");
							}}
						>
							{goalOptionValue.map(goal => (
								<option key={goal.id} value={goal.id}>
									{goal.name}
								</option>
							))}
						</Select>
						{selectedGoalErr && (
							<Text color="red.500" fontSize="14px" fontWeight={"bold"} mt="1rem">
								{selectedGoalErr}
							</Text>
						)}
					</Box>
					{/* <Box mb="2rem">
						<Heading
							fontSize={"18px"}
							fontWeight={"700"}
							color={syBlack}
							mb="1rem"
							textAlign={"center"}
						>
							Add Hashtag
						</Heading>
						<TagsInput
							maxTags={10}
							w={"100%"}
							h={"40px"}
							value={labelerPrompts}
							onChange={tags => {
								const updatedTags = tags.map(tag =>
									tag.startsWith("#") ? tag : `#${tag}`
								);
								setLabelerPrompts(updatedTags);
							}}
							inputProps={{
								placeholder: "Hashtag...",
							}}
							isDisabled={loading ? true : false}
						/>
					</Box> */}
					<Box>
						<Heading fontSize={"18px"} fontWeight={"700"} color={syBlack} mb="1rem" textAlign={"center"}>
							Choose a reward
						</Heading>
						<InputGroup border="none" bg={syWhite} borderRadius={rewardIconSearchKey ? "0px" : "10px"} borderTopLeftRadius={"10px"} borderTopRightRadius={"10px"} mr={5}>
							<InputLeftElement pointerEvents="none" top="5px">
								<AiOutlineSearch />
							</InputLeftElement>
							<Input
								borderWidth="1px"
								boxShadow="lg"
								borderColor={syBorderGrey}
								type="text"
								placeholder="Search Badge"
								_hover={{ boxShadow: "none" }}
								h="50px"
								value={rewardIconSearchKey}
								// onChange={e => {
								// 	setRewardIconSearchKey(e.target.value);
								// }}
								onChange={e => {
									setRewardIconSearchKey(e.target.value);
									//setRewardLoading(true);
									// const selectedGoal = goalOptionValue?.find(
									// 	goal => goal.id === selectedValue
									// );
									// setSelectedGoalName(selectedGoal?.name || "");
								}}
							/>
							<InputRightElement pointerEvents="none" top="5px" mr={1}>
								<Button onClick={() => renderRewardIcon()}>
									<Icon as={AiOutlineSearch} />
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Flex
						w={"100%"}
						borderWidth={"0.5px"}
						borderStyle={"dashed"}
						rounded={"lg"}
						borderColor={"gray.500"}
						//minH={'200px'}
					>
						{selectedValue ? (
							<Flex justifyContent={"space-between"} flexWrap={"wrap"} rounded={"lg"} p="10px" w={"100%"} bg={syWhite}>
								<Box w={"100%"} textAlign={"center"} align={"center"} my="1rem">
									<Text fontWeight={"600"} w="90%" mx="auto" mb="1rem">
										Upload image to customize reward
									</Text>
									<Img mx="auto" onClick={onOpen} src={UploadRewardImg} />
									<Text mt={5} fontWeight={"600"} w="90%" mx="auto" mb="1rem">
										Search Results
									</Text>
								</Box>
								{
									rewardLoading ? (
										<Flex alignItems="center" justifyContent="center" mx="auto" p="10px" h="100px">
											<Spinner size="sm" color={syBlack} />
										</Flex>
									) : rewardIconData?.length ? (
										// <Grid templateColumns="repeat(3, 1fr)" gap={1}>
										<Flex alignItems={"center"} justifyContent={"space-between"} flexWrap={"wrap"}>
											{renderRewardIcon()}
										</Flex>
									) : (
										// </Grid>
										<Text textAlign={"center"} fontWeight={"600"} w="100%" p="5px" bg={syGrey} borderRadius={"5px"} my="2">
											No Image Available
										</Text>
									)
									// <Flex flexWrap={"wrap"}></Flex>
								}
							</Flex>
						) : null}
					</Flex>
					<Box textAlign={"center"} mt="2rem">
						<Button
							w={"70%"}
							borderRadius={"50px"}
							size="lg"
							color={"gray.100"}
							// colorScheme="#5F67EC"
							bg={syThemeBgBlueColor}
							onClick={saveGoalDetails}
							isDisabled={loading ? true : false}
							isLoading={loading ? true : false}
						>
							Next
						</Button>
					</Box>
				</Box>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose} size="sm">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader borderBottomWidth={"1px"}>Upload Reward Logo</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{/* <UploadRewardImage
							goalId={selectedValue}
							selectedGoalName={selectedGoalName}
							passSuccessFlag={refreshAfterUpload}
						/> */}
						<UploadRewardImageCrop goalId={selectedValue} selectedGoalName={selectedGoalName} passSuccessFlag={refreshAfterUpload} />
					</ModalBody>
				</ModalContent>
			</Modal>
			<FooterProfile position={"fixed"} bottom={"0px"} borderRadius="10px" userRoute="/genz/user-profile" />
		</Box>
	);
}

export default CreateGoal;
