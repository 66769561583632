// React Chakra Imports
import { IoIosSearch } from "react-icons/io";
import React, { useEffect, useState } from "react";
import {
	Box,
	Img,
	Text,
	Flex,
	Stack,
	Input,
	Modal,
	Button,
	Spinner,
	useToast,
	ModalBody,
	InputGroup,
	ModalHeader,
	ModalFooter,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	InputLeftAddon,
	InputRightAddon,
	ModalCloseButton,
} from "@chakra-ui/react";

// Component Imports
import rating from "../../../../images/genz/rating.png";
import ShopFriends from "../../../../images/genz/class/shop-friends.png";
import ShopFurniture from "../../../../images/genz/class/shop-furniture.png";
import ShopHobby from "../../../../images/genz/class/shop-hobby.png";
import ShopHomeMakeover from "../../../../images/genz/class/shop-homemakeover.png";
import ShopPopular from "../../../../images/genz/class/shop-popular.png";
import ShopSports from "../../../../images/genz/class/shop-sports.png";

import Friends from "../../../../images/genz/class/friends.png";
import Popular from "../../../../images/genz/class/popular.png";
import Hobby from "../../../../images/genz/class/hobby.png";
import Sports from "../../../../images/genz/class/sports.png";
import Makeover from "../../../../images/genz/class/makeover.png";
import Furniture from "../../../../images/genz/class/furniture.png";
import { toastFunctionToaster } from "../../../../utils/toasterFunction";
import {
	syBlack,
	syGrey,
	syPurple,
	syThemeBgBlueColor,
	syWhite,
} from "../../../../constants/color";

// Api Imports
import {
	fetchBuyingCapability,
	fetchClassBasedItems,
	fetchUserClasses,
	handleItemPurchase,
} from "../../../../services/goalServices";

// Api Imports

function DreamShop() {
	const ModalBgOpacityOverlay = () => (
		<ModalOverlay
			bg="blackAlpha.300"
			backdropFilter="blur(10px) hue-rotate(90deg)"
		/>
	);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [overlay, setOverlay] = React.useState(<ModalBgOpacityOverlay />);
	const toast = useToast();
	const [itemData, setItemData] = useState([]);
	const [itemDataCopy, setItemDataCopy] = useState([]);
	const [starValue, setStarValue] = useState(null);
	const [classData, setClassData] = useState([]);
	const [itemSelected, setSelectedItem] = useState(null);
	const [classSelected, setClassSelected] = useState('Hobby');
	const [itemClass, selectedItemClass] = useState('Hobby');
	const [itemLoading, setItemLoading] = useState(false);
	const [purchaseState, setPurchaseStatus] = useState("");
	const [selectedShopClass, setSelectedShopClass] = useState('Hobbies and Leisure');

	useEffect(() => {
		fetchUserClassesDetails();
		fetchCapabilityRatio();
		handleClassDataDetails(classSelected);
	}, []);

	const fetchUserClassesDetails = () => {
		let obj = {
			key: "string",
		};
		fetchUserClasses(obj)
			.then(res => {
				if (res?.data?.length && res?.result) {
					setClassData(res?.data);
				} else {
					setClassData([]);
				}
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const fetchCapabilityRatio = () => {
		let obj = {
			key: "string",
		};
		fetchBuyingCapability(obj)
			.then(res => {
				setStarValue(res?.data?.[0]?.stars);
			})
			.catch(err => {});
	};

	const renderClassData = () => {
		return classData?.map(data => handleIconData(data?.class_name));
	};

	const generateClassName = (classType) => {
		switch (classType) {
			case "Hobby":
				setSelectedShopClass('Hobbies and Leisure');
				return ShopHobby;
			case "Home Makeover":
				setSelectedShopClass('DIY & Room Upgrades');
				return ShopHomeMakeover;
			case "Furniture":
				setSelectedShopClass('Furniture & Decor');
				return ShopFurniture;
			case "Friends":
				setSelectedShopClass('Friends, Family and Pet');
				return ShopFriends;
			case "Sports":
				setSelectedShopClass('Tech and Mobility');
				return ShopSports;
			case "Popular":
				setSelectedShopClass('Popular');
				return ShopPopular;
			default:
				setSelectedShopClass('Hobbies and Leisure');
				return ShopHobby;
		}
	}

	// Todo:Handle Class Icon Based On Image
	const handleIconData = data => {
		const src = (() => {
			switch (data) {
				case "Hobby":
					return ShopHobby;
				case "Home Makeover":
					return ShopHomeMakeover;
				case "Furniture":
					return ShopFurniture;
				case "Friends":
					return ShopFriends;
				case "Sports":
					return ShopSports;
				case "Popular":
					return ShopPopular;
				default:
					return ShopHobby;
			}
		})();

		return (
			<Img
				h="50px"
				borderRadius="10px"
				w="50px"
				// mr="3"
				boxShadow="md"
				src={src}
				bgGradient={
					itemClass === data
						? "linear(180deg, #FFFFFF 0%, #DAF66F 100%)"
						: null
				}
				onClick={() => {
					generateClassName(data);
					handleClassDataDetails(data);
					setClassSelected(data);
					selectedItemClass(data);
				}}
			/>
		);
	};

	const handleClassDataDetails = data => {
		// setItemLoading(true);
		let obj = { 
			enabled: true, 
			class_name: data, 
			group_name: "general" 
		};
		fetchClassBasedItems(obj)
			.then(res => {
				// setItemLoading(false);
				if (res?.data && res?.status) {
					setItemData(res?.data);
					setItemDataCopy(res?.data);
				} else {
					setItemData([]);
					setItemDataCopy([]);
				}
			})
			.catch(err => {
				// setItemLoading(false);
				if (err) {
					setItemData([]);
				}
			});
	};

	// Todo:Render Shop Data
	const renderShopData = () => {
		return itemDataCopy?.map(data => (
			<Box
				key={data?.reward_item_uuid}
				w="48%"
				mx="auto"
				boxShadow={"md"}
				my="5px"
				mb="0.5rem"
				bg={'white'}
				borderRadius={"10px"}
				textAlign={"center"}
				p="10px"
				// bg={syWhite}
			>
				<Box mb="10px" h="100px" w="100px" mx="auto">
					<Img
						src={data?.reward_item_url}
						mx="auto"
						h="100%"
						w="100%"
						objectFit={"contain"}
					/>
				</Box>
				<Text fontSize={"12px"} fontWeight={"700"} mb="10px">
					{data?.reward_item_name}
				</Text>
				<Flex
					onClick={() => {
						if (data?.reward_item_price <= starValue) {
							setSelectedItem(data);
							setOverlay(<ModalBgOpacityOverlay />);
							onOpen();
						}
					}}
					justifyContent={"center"}
					alignItems={"center"}
					bg={
						data?.reward_item_price <= starValue
							? syThemeBgBlueColor
							: syGrey
					}
					w="90%"
					boxShadow={"lg"}
					borderRadius={"50px"}
					textAlign={"center"}
					color={syWhite}
				>
					<Text
						color={
							data?.reward_item_price <= starValue
								? syWhite
								: syBlack
						}
						fontSize={"12px"}
						fontWeight={"700"}
						mr="10px"
					>
						{data?.reward_item_price}
					</Text>
					<Img src={rating} />
				</Flex>
			</Box>
		));
	};

	const selectedItemPurchase = () => {
		let obj = {
			reward_item_uuid: itemSelected?.reward_item_uuid,
		};
		handleItemPurchase(obj)
			.then(res => {
				if (res?.result) {
					setPurchaseStatus(res?.message);
				}
			})
			.catch(err => {
				if (err) {
					setPurchaseStatus("Something went wrong!!");
				}
			});
	};

	// Todo:Handle Modal Close
	const handleClose = () => {
		onClose();
		setPurchaseStatus("");
	};

	const filterStoreData = e => {
		const searchValue = e.target.value;
		if (searchValue?.length) {
			const filtered = itemData.filter(item => item.reward_item_name.toLowerCase().includes(searchValue.toLowerCase().trim()));
			setItemDataCopy(filtered);
		} else {
			setItemDataCopy(itemData);
		}
	};

	return (
		<Box p="10px">
			<Stack spacing={4} my="1rem" boxShadow={"md"}>
				<InputGroup>
					<Flex 
						rounded={'lg'} py={1}
						w={'100%'} bg={'gray.100'} align={'center'} justify={'center'}>
						<Img src={rating} />
						<Text color={syPurple} fontWeight={"600"}  ml={2}>
							Stars Balance
						</Text>
						<Text as="span" fontWeight={"600"} color={syPurple} ml="2" mr={2}>
							{starValue}
						</Text>
						<Img src={rating} />
					</Flex>
				</InputGroup>
			</Stack>
			<Flex mb="2">{renderClassData()}</Flex>

			{itemLoading ? (
				<Flex alignItems="center" justifyContent="center" h="190px">
					<Spinner size="xl" color={syBlack} />
				</Flex>
			) : (
				<>

					{itemData?.length ? (
						<Box rounded={'lg'}>
							<Flex w={'100%'}>
								<Input
									placeholder="Search Store Items"
									size="md"
									borderRadius="10px"
									border="none"
									fontSize="12px"
									borderColor="#1c181e"
									bg="#fff"
									name="goal_name"
									_placeholder={{ color: "#000" }}
									color="#000"
									_hover={{ boxShadow: "none" }}
									mb="3"
									onChange={e => {
										filterStoreData(e);
									}}
								/>
							</Flex>
							<Text 
								color={syWhite}
								textAlign={"center"} fontWeight={"600"} mb="1rem">
								{selectedShopClass} Items
							</Text>
							<Flex justifyContent={"space-between"} flexWrap={"wrap"}>
								{renderShopData()}
							</Flex>
						</Box>
					) : null}
					
				</>
			)}
			<Modal
				isCentered
				isOpen={isOpen}
				onClose={() => {
					handleClose();
				}}
				size={"md"}
			>
				{overlay}
				<ModalContent w="80%">
					<>
						{purchaseState ? (
							<Flex
								alignItems={"center"}
								justifyContent={"center"}
								pt="8"
							>
								<Text fontWeight={"600"}>{purchaseState}</Text>
							</Flex>
						) : (
							<>
								<ModalHeader textAlign={"center"}>
									{itemSelected?.reward_item_name}
								</ModalHeader>
								<ModalCloseButton />
								<ModalBody>
									<Box h="200px" w="200px" mx="auto">
										<Img
											src={itemSelected?.reward_item_url}
											h="100%"
											w="100%"
											objectFit={"contain"}
										/>
										<Flex
											justifyContent={"center"}
											alignItems={"center"}
											w="100%"
											borderRadius={"50px"}
											textAlign={"center"}
											color={syWhite}
										>
											<Text
												fontSize={"20px"}
												fontWeight={"700"}
												mr="10px"
												color={syBlack}
											>
												{
													itemSelected?.reward_item_price
												}
											</Text>
											<Img src={rating} />
										</Flex>
									</Box>
								</ModalBody>
							</>
						)}
					</>
					<ModalFooter
						borderTopWidth={"1px"}
						mt={purchaseState ? "30px" : "60px"}
					>
						<Button
							onClick={() => {
								handleClose();
							}}
							colorScheme="red"
						>
							Close
						</Button>
						{purchaseState ? null : (
							<Button
								ml="2"
								colorScheme="green"
								onClick={() => {
									selectedItemPurchase();
								}}
							>
								Buy
							</Button>
						)}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default DreamShop;
