import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IconButton } from "@chakra-ui/react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { setExpandMinMenu } from "../../../store/actions/topNotificationActions";

function AdjustSideMenu() {
	const dispatch = useDispatch();

	const sideMenuStatus = useSelector(store => store.notificationHeader);
	const { sideMenuFlag } = sideMenuStatus;

	const maxMinSideMenu = () => {
		dispatch(setExpandMinMenu(!sideMenuFlag));
	};
	return (
		<IconButton
			bg="none"
			m="0"
			_hover={{ bg: "none" }}
			color={"white"}
			fontSize={sideMenuFlag ? "25" : "36"}
			icon={
				sideMenuFlag ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />
			}
			onClick={() => {
				maxMinSideMenu();
			}}
		/>
	);
}

export default AdjustSideMenu;
