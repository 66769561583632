// Chakra React Imports
import axios from "../../../../services/axios";
import React, { useState, useRef } from "react";
import { Box, Button, useToast, Input, Image } from "@chakra-ui/react";
import { toastFunctionToaster } from "../../../../utils/toasterFunction";
import { syBorderGrey, syGrey, syThemeBgBlueColor, syThemeBgGreenColor } from "../../../../constants/color";
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from "react-image-crop";
import { uploadUserProfileImage } from "../../../../services/userServices";

function UploadProfileImage({ passSuccessFlag }) {
	const toast = useToast();

	const imageRef = useRef();
	const [loading, isLoading] = useState(false);
	const [uploadFile, setUploadFile] = useState(null);
	const [cropResult, setCropResult] = useState(null);
	const [completedCrop, setCompletedCrop] = useState();
	const [imageToCrop, setImageToCrop] = useState(undefined);
	const randomFileName = Math.random().toString(36).slice(2) + ".png";
	const [crop, setCrop] = useState({
		aspect: 1,
		width: 200,
		height: 200,
		x: 5,
		y: 5,
	});

	function postObjectData(s3Data, selectedFile) {
		isLoading(true);

		const { url, fields } = s3Data;
		const payload = new FormData();

		// add all the other fields
		Object.entries(fields).forEach(([key, val]) => {
			payload.append(key, val);
		});
		// payload.append("Content-Type", "image/jpeg");
		// This must be the last line in the payload otherwise 412 error may occur
		// this is the file blob, eg from <input type="file">
		payload.append("file", selectedFile);

		// No need to manually set content-type header, your browser knows what to do
		// const { data: result } = axios.post(url, payload);
		const { data: result } = axios
			.post(url, payload)
			.then(res => {
				if (res?.data?.length > 0) {
					toast(toastFunctionToaster("File uploaded successfully", "success"));
					passSuccessFlag("true");
				} else {
					toast(toastFunctionToaster("File uploaded successfully", "success"));
					passSuccessFlag("true");
				}
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster("Something Went Wrong", "error"));
				}
			})
			.finally(() => {
				isLoading(false);
			});
	}

	const handleSubmitNew = async croppedImage => {
		if (imageRef && croppedImage) {
			isLoading(true);
			if (croppedImage) {
				try {
					let objData = {
						file_name: randomFileName,
						file_type: uploadFile.type,
						content_choice: "headshot",
					};
					const response = await uploadUserProfileImage(objData);
					if (response && response?.data && response?.data?.length > 0 && response.data[0]?.signedPackage?.signedUrl) {
						const finalUrl = response.data[0]?.signedPackage?.signedUrl;
						isLoading(false);
						const s3UploadResult = await postObjectData(finalUrl, uploadFile);
					} else {
						isLoading(false);
						toast(toastFunctionToaster(response?.message, "error"));
					}
				} catch (error) {
					toast(toastFunctionToaster("An error occurred during upload.", "error"));
				} finally {
					isLoading(false);
				}
			}
		}
	};

	// Todo:Upload Logo
	const onUploadFile = event => {
		if (event.target.files && event.target.files.length > 0) {
			const reader = new FileReader();

			reader.addEventListener("load", () => {
				const image = reader.result;

				setImageToCrop(image);
				setUploadFile(event.target.files[0]);
			});

			reader.readAsDataURL(event.target.files[0]);
		}
	};

	function onImageLoad(e) {
		const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 50,
				},
				1,
				width,
				height
			),
			width,
			height
		);
		setCrop(crop);
	}

	const getCroppedImg = () => {
		let croppedImage = null;
		if (imageRef.current && crop.width && crop.height) {
			const image = imageRef.current;
			const cropWidth = image.naturalWidth * (crop.width / 100);
			const cropHeight = image.naturalHeight * (crop.height / 100);
			const startX = image.naturalWidth * (crop.x / 100);
			const startY = image.naturalHeight * (crop.y / 100);

			const canvas = document.createElement("canvas");
			canvas.width = cropWidth;
			canvas.height = cropHeight;
			const ctx = canvas.getContext("2d");

			ctx.drawImage(image, startX, startY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

			const reader = new FileReader();
			canvas.toBlob(
				function (blob) {
					reader.readAsDataURL(blob);
					reader.onloadend = () => {
						dataURLtoFile(reader.result, randomFileName);
					};
				},
				"image/png",
				1
			);
		}
		return croppedImage;
	};

	const dataURLtoFile = (dataurl, filename) => {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let croppedImage = new File([u8arr], filename, { type: mime });
		setCropResult(croppedImage);
		handleSubmitNew(croppedImage);
	};

	return (
		<Box h="600px" mb="2" cursor={"pointer"}>
			<Input type="file" accept="image/*" onChange={onUploadFile} />
			<Box w={"100%"} align={"center"} justify={"center"}>
				{completedCrop ? (
					<Button
						cursor={"pointer"}
						mt={2}
						size={"sm"}
						color={"gray.600"}
						onClick={() => {
							getCroppedImg();
						}}
						position="absolute"
						colorScheme="green"
						textColor={"#fff"}
						isDisabled={loading ? true : false}
						isLoading={loading ? true : false}
					>
						Upload Select Image
					</Button>
				) : null}
			</Box>
			<Box mt={"45px"} borderWidth={"1px"} borderStyle={"dashed"} borderRadius={"10px"} position={"relative"} borderColor={syBorderGrey} w={"100%"} minH={"400px"}>
				{imageToCrop ? (
					<ReactCrop src={imageToCrop} crop={crop} minHeight={200} minWidth={200} maxHeight={200} maxWidth={200} aspect={1} onChange={(c, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)}>
						<Image maxHeight={"600px"} crossOrigin="anonymous" src={imageToCrop} ref={imageRef} onLoad={onImageLoad} />
					</ReactCrop>
				) : null}
			</Box>
		</Box>
	);
}

export default UploadProfileImage;
