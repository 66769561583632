// Chakra React Imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Img, Flex, Text, Slider, Icon, Tooltip, Heading, Spinner, SliderMark, SliderTrack, SliderThumb, SliderFilledTrack } from "@chakra-ui/react";
// import BarChart from "../../charts/BarChart";
// import { MdBarChart } from "react-icons/md";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { AiOutlineArrowUp, AiOutlineArrowDown, AiFillUpCircle, AiFillDownCircle } from "react-icons/ai";

// Component Imports
import WeekCalendar from "./WeekCalendar";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import graph from "../../../images/genz/dashboard/graph.png";
import upIcon from "../../../images/genz/dashboard/up-icon.png";
import { syBlack, syWhite, syBorderGrey, syArrowWhiteColor, syArrowGreenColor, syArrowRedColor, syThemeBgBlueColor, syThemeBgYellowColor } from "../../../constants/color";

function SaveUp({ progressData, activeGoals, loading, selected }) {
	const navigate = useNavigate();
	Chart.register(...registerables);

	const data = {
		labels: ["Red", "Blue", "Yellow"],
		datasets: [
			{
				label: "# of Votes",
				data: [12, 19, 33],
				backgroundColor: "rgba(255, 99, 132, 0.2)",
				borderColor: "rgba(255, 99, 132, 1)",
				borderWidth: 1,
			},
		],
	};

	const renderActiveGoals = () => {
		if (activeGoals?.length) {
			return (
				<>
					<Box w={"100%"} align={"start"}>
						<Text color={syBlack} fontWeight={"700"} mb="4">
							It’s time to save !!
						</Text>
					</Box>
					{activeGoals
						?.filter(dx => dx?.highlight_plan)
						?.map((data, index) => {
							return (
								<Box
									bg={data?.goal_readiness ? (data?.highlight_plan ? syThemeBgYellowColor : syArrowWhiteColor) : "red.200"}
									p="10px"
									borderRadius={"10px"}
									mb="1.5rem"
									key={index}
									boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
									onClick={() => {
										let obj = {
											goal_uuid: data?.goal_type_uuid,
											goal_name: data?.goal_name,
											goal_reward_icon: data?.goal_reward_icon ? data?.goal_reward_icon : "",
											goal_amount: data?.goal_amount,
											goal_tags: data?.prompt ? data?.prompt.join(",") : [],
										};
										if (!data?.goal_readiness) {
											navigate("/genz/setup-goal", {
												state: {
													data: obj,
													goalPlanningId: data?.goal_planning_uuid,
												},
											});
										}
										if (data?.goal_readiness) {
											navigate("/genz/goal-event", {
												state: {
													data: data,
													goalPlanningId: data?.goal_planning_uuid,
												},
											});
										}
									}}
								>
									<Flex alignItems={"center"}>
										<Box position={"relative"} mr="1rem">
											<Img w="100px" h="100px" mx="auto" src={data?.goal_reward_icon ? data?.goal_reward_icon : standImg}></Img>
											{data?.goal_reward_icon ? null : (
												<Box position={"absolute"} top="30px" left={"30%"}>
													<Img w="50%" src={lockImg}></Img>
												</Box>
											)}
										</Box>
										{data?.goal_readiness ? (
											<Box w="calc(100% - 120px)">
												<Text as="span" fontSize={"14px"} bg={syBlack} color={syWhite} px="5" borderRadius={"20px"}>
													{data?.goal_duration_days} days left
												</Text>
												<Heading fontSize={"20px"} fontWeight={"700"} fontStyle={"italic"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"} mb="3">
													{data?.goal_name}
												</Heading>
												<Slider id="slider" defaultValue={data?.saved_amount} min={0} max={data?.goal_amount} colorScheme="blue" my="1rem" w="200px" isReadOnly ml="2">
													{/* Slider Marks */}
													<SliderMark value={0} mt="4" ml="0" fontSize="12px" fontWeight="700">
														$0
													</SliderMark>
													<SliderMark value={Math.round(data?.goal_amount / 4)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
														${Math.round(data?.goal_amount / 4)}
													</SliderMark>
													<SliderMark value={Math.round(data?.goal_amount / 2)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
														${Math.round(data?.goal_amount / 2)}
													</SliderMark>
													<SliderMark value={data?.goal_amount} mt="4" ml="-8" fontSize="12px" fontWeight="700" textAlign="center">
														${data?.goal_amount}
													</SliderMark>

													{/* Slider Track */}
													<SliderTrack h="15px" borderRadius="10px">
														<SliderFilledTrack />
													</SliderTrack>

													<SliderThumb>
														{selected === 0 ? (
															<Tooltip mb="1" className="demo" hasArrow bg="blue.500" color="white" fontSize={"xs"} placement="top" isOpen={true} label={`$${data?.saved_amount}`}>
																<Box />
															</Tooltip>
														) : null}
													</SliderThumb>
												</Slider>
											</Box>
										) : (
											<Box w="calc(100% - 120px)">
												<Heading fontSize={"20px"} textWrap="noWrap" whiteSpace={"noWrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
													{data?.goal_name}
												</Heading>
												<Heading fontSize={"16px"} my="2">
													Goal Planning requires your attention...
												</Heading>
												<Heading fontSize={"16px"}>Please select and complete.</Heading>
											</Box>
										)}
									</Flex>
								</Box>
							);
						})}
				</>
			);
		} else {
			return null;
			// <Text p="10px" color={syBlack} bg={syThemeBgYellowColor} borderRadius={"10px"} fontWeight={"700"}>
			// 	No Plan Available
			// </Text>
		}
	};

	const renderOnGoingGoals = () => {
		if (activeGoals?.length) {
			return (
				<>
					{activeGoals
						?.filter(dx => dx?.highlight_plan === false && dx?.goal_readiness === true)
						?.map((data, index) => {
							return (
								<>
									<Box w={"100%"} align={"start"}>
										<Text color={syBlack} fontWeight={"700"} mb="4">
											Ongoing Goals
										</Text>
									</Box>
									<Box
										bg={data?.goal_readiness ? (data?.highlight_plan ? syThemeBgYellowColor : syArrowWhiteColor) : "red.200"}
										p="10px"
										borderRadius={"10px"}
										mb="1.5rem"
										key={index}
										boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
										onClick={() => {
											let obj = {
												goal_uuid: data?.goal_type_uuid,
												goal_name: data?.goal_name,
												goal_reward_icon: data?.goal_reward_icon ? data?.goal_reward_icon : "",
												goal_amount: data?.goal_amount,
												goal_tags: data?.prompt ? data?.prompt.join(",") : [],
											};
											if (!data?.goal_readiness) {
												navigate("/genz/setup-goal", {
													state: {
														data: obj,
														goalPlanningId: data?.goal_planning_uuid,
													},
												});
											}
											if (data?.goal_readiness) {
												navigate("/genz/goal-event", {
													state: {
														data: data,
														goalPlanningId: data?.goal_planning_uuid,
													},
												});
											}
										}}
									>
										<Flex alignItems={"center"}>
											<Box position={"relative"} mr="1rem">
												<Img w="100px" h="100px" mx="auto" src={data?.goal_reward_icon ? data?.goal_reward_icon : standImg}></Img>
												{data?.goal_reward_icon ? null : (
													<Box position={"absolute"} top="30px" left={"30%"}>
														<Img w="50%" src={lockImg}></Img>
													</Box>
												)}
											</Box>
											{data?.goal_readiness ? (
												<Box w="calc(100% - 120px)">
													<Text as="span" fontSize={"14px"} bg={syBlack} color={syWhite} px="5" borderRadius={"20px"}>
														{data?.goal_duration_days} days left
													</Text>
													<Heading fontSize={"20px"} fontWeight={"700"} fontStyle={"italic"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"} mb="3">
														{data?.goal_name}
													</Heading>
													<Slider id="slider" defaultValue={data?.saved_amount} min={0} max={data?.goal_amount} colorScheme="blue" my="1rem" w="200px" isReadOnly ml="2">
														{/* Slider Marks */}
														<SliderMark value={0} mt="4" ml="0" fontSize="12px" fontWeight="700">
															$0
														</SliderMark>
														<SliderMark value={Math.round(data?.goal_amount / 4)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
															${Math.round(data?.goal_amount / 4)}
														</SliderMark>
														<SliderMark value={Math.round(data?.goal_amount / 2)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
															${Math.round(data?.goal_amount / 2)}
														</SliderMark>
														<SliderMark value={data?.goal_amount} mt="4" ml="-8" fontSize="12px" fontWeight="700" textAlign="center">
															${data?.goal_amount}
														</SliderMark>

														{/* Slider Track */}
														<SliderTrack h="15px" borderRadius="10px">
															<SliderFilledTrack />
														</SliderTrack>

														<SliderThumb>
															{selected === 0 ? (
																<Tooltip mb="1" className="demo" hasArrow bg="blue.500" color="white" fontSize={"xs"} placement="top" isOpen={true} label={`$${data?.saved_amount}`}>
																	<Box />
																</Tooltip>
															) : null}
														</SliderThumb>
													</Slider>
												</Box>
											) : (
												<Box w="calc(100% - 120px)">
													<Heading fontSize={"20px"} textWrap="noWrap" whiteSpace={"noWrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
														{data?.goal_name}
													</Heading>
													<Heading fontSize={"16px"} my="2">
														Goal Planning requires your attention...
													</Heading>
													<Heading fontSize={"16px"}>Please select and complete.</Heading>
												</Box>
											)}
										</Flex>
									</Box>
								</>
							);
						})}
				</>
			);
		} else {
			return null;
			// <Text p="10px" color={syBlack} bg={syThemeBgYellowColor} borderRadius={"10px"} fontWeight={"700"}>
			// 	No Plan Available
			// </Text>
		}
	};

	const renderIncompleteGoals = () => {
		if (activeGoals?.length) {
			return (
				<>
					{activeGoals
						?.filter(dx => dx?.highlight_plan === false && dx?.goal_readiness === false)
						?.map((data, index) => {
							return (
								<>
									<Box w={"100%"} align={"start"}>
										<Text color={syBlack} fontWeight={"700"} mb="4">
											Incomplete Goals
										</Text>
									</Box>
									<Box
										bg={data?.goal_readiness ? (data?.highlight_plan ? syThemeBgYellowColor : syArrowWhiteColor) : "red.200"}
										p="10px"
										borderRadius={"10px"}
										mb="1.5rem"
										key={index}
										boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)"
										onClick={() => {
											let obj = {
												goal_uuid: data?.goal_type_uuid,
												goal_name: data?.goal_name,
												goal_reward_icon: data?.goal_reward_icon ? data?.goal_reward_icon : "",
												goal_amount: data?.goal_amount,
												goal_tags: data?.prompt ? data?.prompt.join(",") : [],
											};
											if (!data?.goal_readiness) {
												navigate("/genz/setup-goal", {
													state: {
														data: obj,
														goalPlanningId: data?.goal_planning_uuid,
													},
												});
											}
											if (data?.goal_readiness) {
												navigate("/genz/goal-event", {
													state: {
														data: data,
														goalPlanningId: data?.goal_planning_uuid,
													},
												});
											}
										}}
									>
										<Flex alignItems={"center"}>
											<Box position={"relative"} mr="1rem">
												<Img w="100px" h="100px" mx="auto" src={data?.goal_reward_icon ? data?.goal_reward_icon : standImg}></Img>
												{data?.goal_reward_icon ? null : (
													<Box position={"absolute"} top="30px" left={"30%"}>
														<Img w="50%" src={lockImg}></Img>
													</Box>
												)}
											</Box>
											{data?.goal_readiness ? (
												<Box w="calc(100% - 120px)">
													<Text as="span" fontSize={"14px"} bg={syBlack} color={syWhite} px="5" borderRadius={"20px"}>
														{data?.goal_duration_days} days left
													</Text>
													<Heading fontSize={"20px"} fontWeight={"700"} fontStyle={"italic"} whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"} mb="3">
														{data?.goal_name}
													</Heading>
													<Slider id="slider" defaultValue={data?.saved_amount} min={0} max={data?.goal_amount} colorScheme="blue" my="1rem" w="200px" isReadOnly ml="2">
														{/* Slider Marks */}
														<SliderMark value={0} mt="4" ml="0" fontSize="12px" fontWeight="700">
															$0
														</SliderMark>
														<SliderMark value={Math.round(data?.goal_amount / 4)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
															${Math.round(data?.goal_amount / 4)}
														</SliderMark>
														<SliderMark value={Math.round(data?.goal_amount / 2)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
															${Math.round(data?.goal_amount / 2)}
														</SliderMark>
														<SliderMark value={data?.goal_amount} mt="4" ml="-8" fontSize="12px" fontWeight="700" textAlign="center">
															${data?.goal_amount}
														</SliderMark>

														{/* Slider Track */}
														<SliderTrack h="15px" borderRadius="10px">
															<SliderFilledTrack />
														</SliderTrack>

														<SliderThumb>
															{selected === 0 ? (
																<Tooltip mb="1" className="demo" hasArrow bg="blue.500" color="white" fontSize={"xs"} placement="top" isOpen={true} label={`$${data?.saved_amount}`}>
																	<Box />
																</Tooltip>
															) : null}
														</SliderThumb>
													</Slider>
												</Box>
											) : (
												<Box w="calc(100% - 120px)">
													<Heading fontSize={"20px"} textWrap="noWrap" whiteSpace={"noWrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
														{data?.goal_name}
													</Heading>
													<Heading fontSize={"16px"} my="2">
														Goal Planning requires your attention...
													</Heading>
													<Heading fontSize={"16px"}>Please select and complete.</Heading>
												</Box>
											)}
										</Flex>
									</Box>
								</>
							);
						})}
				</>
			);
		} else {
			return null;
			// <Text p="10px" color={syBlack} bg={syThemeBgYellowColor} borderRadius={"10px"} fontWeight={"700"}>
			// 	No Plan Available
			// </Text>
		}
	};

	return (
		<Box>
			<Box bg={syWhite} p="20px" borderRadius={"10px"} boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)">
				<Flex justifyContent={"space-between"}>
					<Text color={syBorderGrey} fontWeight={"700"}>
						Total Syval Funds
					</Text>
					<Box>
						{progressData?.monthly_growth == 0 ? (
							<Flex justifyContent={"flex-end"}>
								<Text fontWeight={"600"} color={syArrowGreenColor}>
									{progressData?.monthly_growth}
									{"%"}
								</Text>
							</Flex>
						) : (
							<Flex justifyContent={"flex-end"}>
								{progressData?.monthly_growth_dir === "up" ? <Icon mt={1} as={AiFillUpCircle} color={syArrowGreenColor} /> : <Icon mt={1} as={AiFillDownCircle} color={syArrowRedColor} />}
								{/* <Img src={upIcon} transform="rotate(-180deg)" /> */}
								<Text fontWeight={"600"} color={progressData?.monthly_growth_dir === "up" ? syArrowGreenColor : syArrowRedColor}>
									{progressData?.monthly_growth}
									{"%"}
								</Text>
							</Flex>
						)}
						<Text fontSize={"12px"} fontWeight={"600"}>
							Compared to last month
						</Text>
					</Box>
				</Flex>

				<Flex justifyContent={"space-between"}>
					<Box mt="2rem">
						<Text color={syThemeBgBlueColor} fontSize={"26px"} fontWeight={"700"}>
							$ {progressData?.total_saving}
						</Text>
						{progressData?.monthly_growth > 0 ? (
							<Text fontSize={"12px"} fontWeight={"700"}>
								You are doing amazing!!
							</Text>
						) : null}
					</Box>
					<Box h="100px" rounded={"lg"} borderWidth={"0.5px"} borderColor={"gray.300"} mt="auto" w="200px">
						{progressData?.graph_data?.length > 0 ? <Bar data={progressData?.graph_data[0]} /> : null}
						{/* <BarChart
							chartData={progressData?.graph_data ? progressData?.graph_data : []}
							chartOptions={newOptions}
						/> */}
						{/* <Img src={graph} /> */}
					</Box>
				</Flex>
			</Box>
			<Box my="2rem">
				<WeekCalendar />
			</Box>
			<Box mb="6rem">
				{loading ? (
					<Flex alignItems="center" justifyContent="center" mt="5rem">
						<Spinner size="xl" color={syWhite} />
					</Flex>
				) : (
					<Flex w={"100%"}>
						{activeGoals?.length > 0
						?<Flex w={'100%'} direction={'column'}>
							<Flex w={"100%"} direction={"column"}>
								{renderActiveGoals()}
							</Flex>
							<Flex w={"100%"} direction={"column"}>
								{renderOnGoingGoals()}
							</Flex>
							<Flex w={"100%"} direction={"column"}>
								{renderIncompleteGoals()}
							</Flex>
						</Flex>
						:<Flex w={'100%'} direction={'column'} p={5}>
							<Heading color={'gray.100'}>
								No ongoing goals right now. Tap + to start or set your next one.
							</Heading>
						</Flex>}
					</Flex>
				)}
			</Box>
		</Box>
	);
}

export default SaveUp;
