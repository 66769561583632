import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Input, Spinner } from "@chakra-ui/react";

// Component Imports
import { getUserName } from "../../../utils/localStorageIndex";
import ResponsivePagination from "react-responsive-pagination";
import {
	syGrey,
	syWhite,
	syBlack,
	syThemeBgGreenColor,
	syThemeBgYellowColor,
} from "../../../constants/color";

// Api Imports
import { fetchUserList } from "../../../services/sharedServices";

function UserList({ selectedId }) {
	const [loading, isloading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedUser, setSelectedUser] = useState("");

	useEffect(() => {
		fetchUserDetails();
	}, [currentPage]); // Update fetch when page changes

	const fetchUserDetails = () => {
		isloading(true);
		let objBody = {
			enabled: true,
			filter_key: getUserName(),
			filter_value: "string",
			records_per_page: 10,
			page_id: currentPage,
		};
		fetchUserList(objBody)
			.then(res => {
				isloading(false);
				if (res.data.length > 0) {
					setTotalPages(res.data[0].total_pages);
					setTableData(res.data[0].records);
				} else {
					setTotalPages(0);
					setTableData([]);
				}
			})
			.catch(err => {
				isloading(false);
			});
	};

	const handleSearch = e => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);
	};

	const filteredData = tableData.filter(data => {
		// Implement filtering logic here
		return (
			data?.first_name?.toLowerCase().includes(searchTerm) ||
			data?.last_name?.toLowerCase().includes(searchTerm) ||
			data?.phone_number?.includes(searchTerm) ||
			data?.email?.toLowerCase().includes(searchTerm)
		);
	});

	function renderUserDetails() {
		if (filteredData && filteredData.length > 0) {
			var usersResult = filteredData.map((data, index) => (
				<Flex
					p="10px"
					key={data?.uuid}
					borderWidth={"0.5px"}
					borderColor={syGrey}
					cursor={"pointer"}
					mb="2"
					bg={
						selectedUser === data?.uuid ? syThemeBgGreenColor : null
					}
					color={selectedUser === data?.uuid ? syBlack : syWhite}
					borderRadius={"10px"}
					onClick={() => {
						setSelectedUser(data?.uuid);
						selectedId(data?.uuid);
					}}
				>
					<Box>
						<Text mb="5px" fontSize={"14px"} w="100%">
							<Text as="span">
								{data?.first_name
									? data?.first_name
									: "Not Available"}
							</Text>
							<Text as="span" ml="2">
								{data?.last_name
									? data?.last_name
									: "Not Available"}
							</Text>
						</Text>

						<Text as="span">
							{data?.email ? data?.email : "Not Available"}
						</Text>
					</Box>
				</Flex>
			));
			return usersResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Users Found!!"}
					</Text>
				</Box>
			);
		}
	}

	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" color={syWhite} />
				</Flex>
			) : (
				<Box py="10px">
					<Input
						type="search"
						placeholder="Search User"
						value={searchTerm}
						onChange={handleSearch} // Handle input change
						color={syWhite}
						w="100%"
					/>

					<Box
						mt="3"
						h="calc(100vh - 30vh)"
						overflow="auto"
						css={{
							"&::-webkit-scrollbar": {
								width: "4px",
							},
							"&::-webkit-scrollbar-track": {
								width: "6px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: { syGrey },
								borderRadius: "24px",
							},
						}}
					>
						{renderUserDetails()}
					</Box>
					{totalPages > 1 && (
						<Box>
							<ResponsivePagination
								current={currentPage}
								total={totalPages}
								onPageChange={pageNumber => {
									setCurrentPage(pageNumber);
								}}
							/>
						</Box>
					)}
				</Box>
			)}
		</>
	);
}

export default UserList;
