// React Chakra Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Button, useToast } from "@chakra-ui/react";

// Component Imports
import { AiOutlineLogout } from "react-icons/ai";
import { toastFunctionToaster } from "../../utils/toasterFunction";
import { clearLocalStorage, getEmailId } from "../../utils/localStorageIndex";

// Api Imports
import { applicationLogout } from "../../services/sharedServices";
import { syWhite } from "../../constants/color";

function Logout({ size, borderRadius, ml, icon }) {
	const toast = useToast();
	const emailId = getEmailId();
	const navigate = useNavigate();
	const [loading, isloading] = useState(false);

	// Todo:Sytem Logout With All Storage Cleared
	const systemLogout = () => {
		isloading(true);
		var apiData = { email: emailId };
		applicationLogout(apiData)
			.then(res => {
				isloading(false);
				if (res.result) {
					toast(toastFunctionToaster(res?.message, "success"));
					navigate("/login");
					clearLocalStorage();
				} else {
					toast(toastFunctionToaster(res?.message, "success"));
				}
			})
			.catch(err => {
				isloading(false);
				toast(toastFunctionToaster(err?.message, "success"));
			});
	};

	return (
		<>
			{icon ? (
				<IconButton
					ml={ml}
					icon={<AiOutlineLogout />}
					bg="#c21c1c"
					p="5px"
					color={syWhite}
					borderRadius={"5px"}
					onClick={() => {
						systemLogout();
					}}
					h="25px"
					w="25px"
					isDisabled={loading ? true : false}
					isLoading={loading ? true : false}
				/>
			) : (
				<Button
					colorScheme="red"
					size={size}
					borderRadius={borderRadius}
					ml={ml}
					onClick={() => {
						systemLogout();
					}}
					isDisabled={loading ? true : false}
					isLoading={loading ? true : false}
				>
					Logout
				</Button>
			)}
		</>
	);
}

export default Logout;
