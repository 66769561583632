import { useMemo } from "react";

const useResponsiveHeight = () => {
	return useMemo(
		() => ({
			base: "950px",
			sm: "950px",
			md: "950px",
			lg: "auto",
			xl: "auto",
		}),
		[]
	);
};

export default useResponsiveHeight;
