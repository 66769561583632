// React Chakra Imports
import React, { useEffect, useState } from "react";
import { Box, Img, Tabs, Tab, Spacer, Text, Grid, GridItem, Flex, Input, Modal, Button, TabList, Slider, Heading, Spinner, TabPanel, useToast, TabPanels, ModalBody, SliderMark, SliderThumb, SliderTrack, ModalOverlay, ModalContent, useDisclosure, ModalCloseButton, SliderFilledTrack } from "@chakra-ui/react";

// Component Imports
import moment from "moment";
import DreamShop from "./DreamShop";
import { useNavigate } from "react-router-dom";
import DreamLifeSavedEvent from "./DreamLifeSavedEvent";

import StandImg from "../../../../images/genz/goal/stand.png";
import { getUserName } from "../../../../utils/localStorageIndex";
import Cup from "../../../../images/genz/cup.png";
import Star from "../../../../images/genz/star.png";
import { toastFunctionToaster } from "../../../../utils/toasterFunction";
import DreamLifeCard from "../../../../images/genz/goal/dreamlife/card.png";
import DreamLifeAward from "../../../../images/genz/goal/dreamlife/award.png";
import DreamLifeBgImg from "../../../../images/genz/goal/dreamlife/dreamLifeBg.png";
import RoomFloor from "../../../../images/genz/goal/dreamlife/room-floor.png";
import ShelfInRoom from "../../../../images/genz/goal/dreamlife/shelfInRoom.png";
import DreamCompleteGiftImg from "../../../../images/genz/goal/dreamlife/gift.png";
import { syWhite, syBlack, syArrowGreenColor, syThemeBgBlueColor, syThemeBgYellowColor, syPurple, syGreen, syBorderGrey } from "../../../../constants/color";
import rating from "../../../../images/genz/rating.png";

// Api Imports
import { getDreamLifeDashboardData, getDreamLifeDashboardActiveData } from "../../../../services/userServices";

import { fetchSelectedGoalPlan } from "../../../../services/goalServices";

import { handleItemPurchased } from "../../../../services/goalServices";

function DreamLife({ tabValue }) {
	const toast = useToast();
	const navigate = useNavigate();
	const userName = getUserName();
	const [loading, isloading] = useState(false);
	const [goalsToday, setGoalsToday] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [activeDetails, setActiveDetails] = useState([]);
	const [completedGoals, setCompletedGoals] = useState([]);
	const [purchasedItems, setPurchasedItems] = useState([]);
	const [purchasedItemsCopy, setPurchasedItemsCopy] = useState([]);
	const [roomDecoList, setRoomDecoList] = useState([]);
	const [modalOpenCount, setModalOpenCount] = useState(0);
	const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
	const [completedGoalData, setCompletedGoalData] = useState({});
	const { isOpen: isSecondOpen, onOpen: onSecondOpen, onClose: onSecondClose } = useDisclosure();

	useEffect(() => {
		fetchDashboardDetails();
		fetchDashboardActiveDetails();
		fetchPurchaseItems();
	}, [tabValue]);

	const fetchDashboardDetails = () => {
		isloading(true);
		let obj = {
			key: "string",
		};
		getDreamLifeDashboardData(obj)
			.then(res => {
				isloading(false);
				if (res?.data?.[0].status) {
					setCompletedGoals(res?.data?.[0]?.completed);
					setRoomDecoList(res?.data[0]?.room_deco);
				}
			})
			.catch(err => {
				if (err) {
					isloading(false);
					toast(toastFunctionToaster(err?.message, "success"));
				}
			});
	};

	const fetchDashboardActiveDetails = () => {
		let obj = {
			key: "string",
		};
		getDreamLifeDashboardActiveData(obj)
			.then(res => {
				//toast(toastFunctionToaster(res?.message, "success"));
				setActiveDetails(res?.data[0]?.data?.[0]?.active_goal_plans);
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "success"));
				}
			});
	};

	const fetchPurchaseItems = () => {
		let obj = {
			filter_key: "string",
			filter_value: "string",
		};
		handleItemPurchased(obj)
			.then(res => {
				if (res?.result && res?.data?.length) {
					setPurchasedItems(res?.data);
					setPurchasedItemsCopy(res?.data);
				} else {
					setPurchasedItems([]);
					setPurchasedItemsCopy([]);
				}
			})
			.catch(err => {
				if (err) {
					setPurchasedItems([]);
					setPurchasedItemsCopy([]);
				}
			});
	};

	const navigateToGoalEvent = data => {
		navigate("/genz/goal-event", {
			state: {
				data: data,
				goalPlanningId: data?.goal_planning_uuid,
			},
		});
	};

	// The fetchGoalDetails function remains the same
	const fetchGoalDetails = goalPlanningId => {
		isloading(true);
		let obj = {
			goal_planning_uuid: goalPlanningId,
		};

		return fetchSelectedGoalPlan(obj)
			.then(res => {
				isloading(false);
				if (res?.result) {
					setCompletedGoalData(res?.data[0]);
					// toast(toastFunctionToaster(res?.message, "success"));
				} else {
					// toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isloading(false);
				if (err) {
					// toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	function renderCompletedGoal() {
		if (completedGoals && completedGoals.length > 0) {
			var completedGoalResult = completedGoals.map((data, index) => (
				<Flex
					w="32%"
					mr="1%"
					mb="1.5rem"
					h="100px"
					bg={"white"}
					p={1}
					rounded={"lg"}
					flexWrap={"wrap"}
					alignItems={"center"}
					justifyContent={"center"}
					onClick={() => {
						// navigateToGoalEvent(data);
						onOpen();
						fetchGoalDetails(data?.goal_planning_uuid);
						// setCompletedGoalData(data);
					}}
				>
					<Img
						h="60%"
						w={"100%"}
						objectFit={"contain"}
						// border={"3px"}
						// borderStyle={"dotted"}
						// borderRadius={"10px"}
						borderColor={syArrowGreenColor}
						src={data?.goal_reward_icon ? data?.goal_reward_icon : StandImg}
					/>
					<Text fontSize={"12px"} fontWeight={"600"} textAlign={"center"}>
						{data?.goal_name}
					</Text>
				</Flex>
			));
			return completedGoalResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text fontSize={16} color={syThemeBgYellowColor}>
						{"No Goals Found!!"}
					</Text>
				</Box>
			);
		}
	}

	function renderActiveGoal() {
		if (activeDetails && activeDetails.length > 0) {
			var activeGoalResult = activeDetails.map((data, index) => (
				<Flex
					w="32%"
					mr="1%"
					mb="1.5rem"
					h="100px"
					flexWrap={"wrap"}
					alignItems={"center"}
					justifyContent={"center"}
					rounded={"lg"}
					bg={"gray.100"}
					onClick={() => {
						navigateToGoalEvent(data);
					}}
				>
					<Img
						h="60%"
						w={"100%"}
						border={"3px"}
						objectFit={"contain"}
						// borderStyle={"dotted"}
						// borderRadius={"10px"}
						// borderColor={syBorderGrey}
						p="5px"
						src={data?.goal_reward_icon ? data?.goal_reward_icon : StandImg}
					/>
					<Text fontSize={"12px"} fontWeight={"600"} textAlign={"center"}>
						{data?.goal_name}
					</Text>
				</Flex>
			));
			return activeGoalResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Active Goals Found!!"}
					</Text>
				</Box>
			);
		}
	}

	const renderPurchasedItems = () => {
		if (purchasedItemsCopy && purchasedItemsCopy.length > 0) {
			var purchasedItemsResult = purchasedItemsCopy.map((data, index) => (
				<Box w="49%" boxShadow={"md"} border={"2px"} 
					borderRadius={"10px"} borderColor={syThemeBgBlueColor} 
					//borderStyle={"dotted"} 
					textAlign={"center"} p="1px" bg={syWhite} mr="1%" mb="1.5rem">
					<Img h="80%" w={"100%"} p="10px" objectFit={"contain"} src={data?.reward_transaction_image_url ? data?.reward_transaction_image_url : StandImg} />
					<Flex w={"100%"} align={"center"} px={1} mb={1}>
						<Text fontSize={"12px"} fontWeight={"600"} textAlign={"left"}>
							{data?.item_name?.reward_item_name}
						</Text>
						<Spacer />
						<Img h={"16px"} w={"16px"} ml={1} src={rating} />
						<Text fontSize={"12px"} fontWeight={"600"} textAlign={"center"}>
							{data?.reward_transaction_stars}
						</Text>
					</Flex>
				</Box>
			));
			return purchasedItemsResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Purchased Items"}
					</Text>
				</Box>
			);
		}
	};

	// Update the useEffect that filters the goals
	// useEffect(() => {
	// 	const today = moment().format("YYYY-MM-DD");
	// 	// Filter the goals with target_completion_date on or after today
	// 	const goalsForToday = completedGoals.filter(goal => {
	// 		const goalCompletionDate = moment(goal.target_completion_date);
	// 		return goalCompletionDate.isSameOrAfter(moment(today), "day");
	// 	});
	// 	setGoalsToday(goalsForToday);

	// 	if (goalsForToday.length > 0) {
	// 		onSecondOpen();
	// 		setModalOpenCount(0);
	// 	}
	// }, [completedGoals, onOpen, onSecondOpen]);

	const handleNextGoal = () => {
		if (modalOpenCount <= 1) {
			onSecondClose();
		}
		setModalOpenCount(modalOpenCount + 1);
		// If the count is less than 2 (0, 1) show next goal
		if (modalOpenCount < 2) {
			if (currentGoalIndex < goalsToday.length - 1) {
				setCurrentGoalIndex(currentGoalIndex + 1);
			}
		} else {
			onSecondClose();
		}
	};

	// const renderRoomDecoration = () => {
	// 	if (roomDecoList && roomDecoList.length > 0) {
	// 		const allUrls = roomDecoList.flatMap(subArray => subArray.map(item => (item !== null ? item.url : null)).filter(url => url !== null && url !== undefined));
	// 		let leftValue = 10;
	// 		console.log(allUrls);
	// 		var allImages = allUrls.map((item, index) => (
	// 			<GridItem w="100%">
	// 				<Img w={"20px"} h={"20px"} objectFit={"contain"} src={item} />
	// 			</GridItem>
	// 		));
	// 		return allImages;
	// 	}
	// };

	// Todo:Filter Data

	const renderRoomDecoration = () => {
		if (roomDecoList && roomDecoList.length > 0) {
			// Flatten the list and filter out null/undefined URLs
			const allUrls = roomDecoList.flatMap(subArray => subArray.map(item => (item !== null ? item.url : null)).filter(url => url !== null && url !== undefined));

			// Map existing URLs to GridItem components
			const allImages = allUrls.map((item, index) => (
				<GridItem w="100%" key={`image-${index}`}>
					<Img w={"12px"} h={"12px"} objectFit={"contain"} src={item} />
				</GridItem>
			));

			// Calculate the remaining count to reach 20
			const remainingCount = 20 - allUrls.length;

			// Add divs with blue background for remaining count
			const blueBackgroundDivs = Array.from({ length: remainingCount }, (_, index) => (
				<GridItem w="100%" key={`placeholder-${index}`}>
					<Box style={{ width: "12px", height: "12px", backgroundColor: syBorderGrey, borderRadius: "50px" }}></Box>
				</GridItem>
			));

			// Combine and return all elements
			return [...allImages, ...blueBackgroundDivs];
		}

		return null; // Return null if roomDecoList is empty or undefined
	};

	const filterData = e => {
		const searchValue = e.target.value;
		if (searchValue?.length) {
			const filtered = purchasedItems.filter(item => item.item_name.reward_item_name.toLowerCase().includes(searchValue.toLowerCase().trim()));
			setPurchasedItemsCopy(filtered);
		} else {
			setPurchasedItemsCopy(purchasedItems);
		}
	};

	return (
		<Box borderRadius={"10px"} mb="2rem">
			<Box px="10px" pt="30px" bg={syWhite} rounded={"lg"}>
				{/* <Text textAlign={"center"} fontWeight="700">
					{userName} DreamLife
				</Text> */}
				<Flex w={"100%"} align={"center"} justify={"center"}>
					<Text fontSize={"xs"}>Click in shelf to decorate your rewards and click anywhere in room to decorate your purchase!!</Text>
				</Flex>
				<Box position={"relative"} mt="3">
					<Img src={DreamLifeBgImg} />
					<Box position={"absolute"} bottom="34%" right={"14%"}>
						<Img
							onClick={() => {
								navigate("/genz/dreamlife/manage-shelf");
							}}
							src={ShelfInRoom}
						/>
					</Box>
					<Box position={"absolute"} bottom="9%" right={"32%"}>
						<Img
							onClick={() => {
								navigate("/genz/dreamlife/manage-room");
							}}
							w={"97%"}
							src={RoomFloor}
						/>
					</Box>
					<Box position={"absolute"} bottom="23%" left={"34%"} transform="rotate(335deg)">
						<Grid templateColumns="repeat(4, 1fr)" gap={1}>
							{renderRoomDecoration()}
						</Grid>
					</Box>
				</Box>
			</Box>
			{/* <Text
				textAlign={"center"}
				fontWeight={"600"}
				textDecoration={"underline"}
			>
				Edit
			</Text> */}
			{loading ? (
				<Flex alignItems="center" h="30vh" justifyContent="center">
					<Spinner size="lg" color={syBlack} />
				</Flex>
			) : (
				<Tabs w="95%" mx="auto" mt="3" bg={null}>
					<TabList>
						<Tab
							color={"white"}
							w="50%"
							_selected={{
								fontWeight: 600,
								color: syGreen,
								borderBottomColor: syGreen,
							}}
						>
							Rewards
						</Tab>
						<Tab
							color={"white"}
							w="50%"
							_selected={{
								fontWeight: 600,
								color: syGreen,
								borderBottomColor: syGreen,
							}}
						>
							Shop
						</Tab>
					</TabList>

					<TabPanels mt="2">
						<TabPanel p="0px">
							<Tabs onChange={index => setSelectedIndex(index)}>
								<TabList borderBottom={0}>
									<Tab borderBottom={0} _focus={{ boxShadow: "none" }}>
										<Box 
											bgGradient={selectedIndex === 0 ? "linear(180deg, #FFFFFF 0%, #DAF66F 100%)" : null} 
											bg={selectedIndex === 0 ? null : 'gray.300'}
											boxShadow={"md"} p="3" h="60px" w="150px" borderRadius={"10px"}>
											<Img src={Cup} h="60%" w="100%" objectFit={"contain"} />
											Goal Achieved
										</Box>
									</Tab>
									<Tab borderBottom={0} pl="0" _focus={{ boxShadow: "none" }}>
										<Box
											bgGradient={selectedIndex === 1 ? "linear(180deg, #FFFFFF 0%, #DAF66F 100%)" : null}
											bg={selectedIndex === 1 ? null : 'gray.300'}
											onClick={() => {
												fetchPurchaseItems();
											}}
											boxShadow={"md"}
											p="3"
											h="60px"
											w="150px"
											borderRadius={"10px"}
										>
											<Img src={Star} h="60%" w="100%" objectFit={"contain"} />
											Purchased
										</Box>
									</Tab>
								</TabList>

								<TabPanels>
									<TabPanel>
										<Box>
											<Text textAlign={"center"} fontWeight={"600"} color={syWhite} mb="0.5rem">
												Achievements
											</Text>
											<Flex flexWrap={"wrap"} alignItems={"center"}>
												{renderCompletedGoal()}
											</Flex>
											<Text textAlign={"center"} fontWeight={"600"} color={syWhite} my="0.5rem">
												Work’n on it!!
											</Text>
											<Flex flexWrap={"wrap"} alignItems={"center"} p="10px">
												{renderActiveGoal()}
											</Flex>
										</Box>
									</TabPanel>
									<TabPanel>
										<Input
											placeholder="Search Purchased Items"
											size="md"
											borderRadius="10px"
											border="none"
											fontSize="12px"
											borderColor="#1c181e"
											bg="#fff"
											name="goal_name"
											_placeholder={{ color: "#000" }}
											color="#000"
											_hover={{ boxShadow: "none" }}
											mb="3"
											onChange={e => {
												filterData(e);
											}}
										/>
										<Flex justifyContent={"space-between"} flexWrap={"wrap"}>
											{renderPurchasedItems()}
										</Flex>
									</TabPanel>
								</TabPanels>
							</Tabs>
						</TabPanel>
						<TabPanel p="0px">
							<DreamShop />
						</TabPanel>
					</TabPanels>
				</Tabs>
			)}

			{completedGoalData?.goal_plan?.length > 0 && completedGoalData?.goal_events?.length ? <DreamLifeSavedEvent completedData={completedGoalData} open={isOpen} onClose={onClose} /> : null}
			{/* Achievement Unlocked Modal */}
			<Modal
				isCentered
				isOpen={isSecondOpen}
				onClose={() => {
					handleNextGoal();
				}}
			>
				<ModalOverlay />
				<ModalContent m="5" borderRadius="10px" bg={syThemeBgBlueColor}>
					<ModalCloseButton top="15px" left="7px" bg="#fff" />
					<Heading fontSize={"20px"} color={syThemeBgYellowColor} pt="15px" textAlign={"center"}>
						New Achievements
					</Heading>

					<ModalBody m="4" bg="#eff0fd" borderRadius={"10px"} p="10px" py="20px" opacity={1}>
						<Box>
							<Box position={"relative"}>
								<Img src={DreamCompleteGiftImg} mx="auto" />
								<Box transform="translate(-50%, -50%)" position={"absolute"} top="55%" left="50%">
									<Img w="40%" mx="auto" borderRadius={"10px"} src={goalsToday[currentGoalIndex]?.goal_reward_icon} />
								</Box>
							</Box>
							<Heading fontSize={"20px"} textAlign={"center"} my="1rem" color={syBlack}>
								{" "}
								{goalsToday[currentGoalIndex]?.goal_name}
							</Heading>
							<Flex justifyContent={"center"} bg={syBlack} color={syWhite} width={"fit-content"} mx="auto" px="4" borderRadius={"50px"}>
								<Text fontSize={"14px"}>
									{moment(goalsToday[currentGoalIndex]?.target_completion_date).format("MMMM").slice(0, 4)} {moment().format("D")}
								</Text>{" "}
								-
								<Text fontSize={"14px"}>
									{moment().format("MMMM").slice(0, 4)} {moment().format("D")}
								</Text>
							</Flex>
							<Box w="90%" mx="auto">
								<Slider id="slider" defaultValue={goalsToday[currentGoalIndex]?.goal_amount} min={0} max={goalsToday[currentGoalIndex]?.goal_amount} colorScheme="blue" my="1rem" w="100%" isReadOnly>
									{/* Slider Marks */}
									<SliderMark value={0} mt="4" ml="0" mx="auto" fontSize="12px" fontWeight="700">
										$0
									</SliderMark>
									<SliderMark value={Math.round(goalsToday[currentGoalIndex]?.goal_amount / 4)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
										${Math.round(goalsToday[currentGoalIndex]?.goal_amount / 4)}
									</SliderMark>
									<SliderMark value={Math.round(goalsToday[currentGoalIndex]?.goal_amount / 2)} mt="4" ml="-2" fontSize="12px" fontWeight="700" textAlign="center">
										${Math.round(goalsToday[currentGoalIndex]?.goal_amount / 2)}
									</SliderMark>
									<SliderMark value={goalsToday[currentGoalIndex]?.goal_amount} mt="4" ml="-8" fontSize="12px" fontWeight="700" textAlign="center">
										${goalsToday[currentGoalIndex]?.goal_amount}
									</SliderMark>

									{/* Slider Track */}
									<SliderTrack h="15px" borderRadius="10px">
										<SliderFilledTrack />
									</SliderTrack>

									<SliderThumb></SliderThumb>
								</Slider>
							</Box>
							<Box textAlign={"center"} mt="1.5rem">
								<Button
									borderRadius={"30px"}
									boxShadow={"md"}
									color={syThemeBgBlueColor}
									bg={syThemeBgYellowColor}
									_hover={{ boxShadow: "none" }}
									onClick={() => {
										handleNextGoal();
									}}
								>
									Add to my dream life
								</Button>
							</Box>
						</Box>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default DreamLife;
