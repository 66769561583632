import React, { useEffect, useState } from "react";
import { Flex, 
    Box, 
    Button,
	Link,
    Img,
    Switch,
    Spinner,
    Text, Grid, GridItem,
    useToast,
} from "@chakra-ui/react";
import { AiFillLeftCircle, 
    AiFillRightCircle,
    AiFillEdit,
 } from "react-icons/ai";
import {
	syGrey,
	syBgGrey,
	syWhite,
    syBlack,
    syThemeLabelColor,
    syThemeBgYellowColor,
	syBorderGrey,
	syThemeBgRedColor
} from "../../../constants/color";
// import UsersList from "../users/UsersList";
// import UserList from "./UserList";
// import RewardUserList from "./RewardUserList";
// import UsersList from "../users/UsersList";
// import UsersGoalList from "./UsersGoalList";
// import GoalBasedUsers from "./GoalBasedUsers";

import { toastFunctionToaster } from "../../../utils/toasterFunction";


// Api Imports
import {
	fetchPromoCategories,
    listingPromoItemsApi,
    apiPromoItemStatus
} from "../../../services/goalServices";

import CreatePromoItem from "./CreatePromoItem";
import UploadPromoItemImage from "./UploadPromoItemImage";


function YouTubePromoHome() {
    const toast = useToast();
	const [configurationCollapseFlag, setConfiguationCollapseFlag] =
		useState(true);

	const [userId, selectedUserId] = useState("");
    const [loading, setLoading] = useState(false);
    const [promoCategories, setPromoCategories] = useState([]);
	const [isEditClicked, setIsEditClicked] = useState(false);
    const [promoItemsList, setPromoItemsList] = useState([]);
    const [selectedCatId, setSelectedCatId] = useState(null);
	const [selectedPromoItem, setSelectedPromoItem] = useState(null);
    

	useEffect(() => {
		getPromoCategories();
       renderPromoList();
	}, []);

    // Todo:Fetch Reward Group List
	const getPromoCategories = () => {
		let obj = {
			promo_name: "string",
		};
		fetchPromoCategories(obj)
			.then(res => {
				if (res?.data[0]?.status && res?.data[0]?.categories?.length > 0) {
					setPromoCategories(res?.data[0]?.categories);
				} else {
					setPromoCategories([]);
				}
			})
			.catch(err => {
				if (err) {
					setPromoCategories([]);
				}
			});
	};

    // Todo:Fetch Reward Store List
	const renderPromoList = (selectedCategory) => {
		setLoading(true);
        let categoryName = selectedCatId ? selectedCatId : 'all';
        if (selectedCategory != null){
            categoryName = selectedCategory;
        }
		let obj = {
			enabled: true,
            records_per_page: 0,
            page_id: 0,
			filter_key: "promo_category",
			filter_value: categoryName //selectedCatId ? selectedCatId : 'all',
		};
		listingPromoItemsApi(obj)
			.then(res => {
				setLoading(false);
				if (res?.data[0]?.records?.length > 0) {
					setPromoItemsList(res?.data[0]?.records);
				} else {
					setPromoItemsList([]);
				}
			})
			.catch(err => {
				setLoading(false);
				if (err) {
					setPromoItemsList([]);
				}
			});
	};

    const renderPromoCategories = () => {
        if (promoCategories?.length) {
            return promoCategories.map((data, index) => {
                return (
                    <Flex w={'100%'} 
                        _hover={{bg:'gray.500', cursor:'pointer'}}
                        align={'center'}>
                        <Text
                            onClick={() => {
                                setSelectedCatId(data?.id);
                                renderPromoList(data?.id);
                            }}
                            key={data + index}
                            bg={selectedCatId === data?.id ? 'green.700' : null}
                            mr="3"
                            color={syWhite}
                            borderRadius={"10px"}
                            textAlign={"center"}
                            p="1rem"
                            fontWeight={"bold"}
                        >
                            {data?.label}
                        </Text>
                    </Flex>
                );
            });
        }
    };

    	// Todo:Handle Reward Status
	const handlePromoItemStatus = data => {
		let obj = {
			promo_uuid: data?.promo_uuid,
		};
		apiPromoItemStatus(obj)
			.then(res => {
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));
					renderPromoList(true);
				} else {
					toast(toastFunctionToaster(res?.message, "err"));
				}
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "success"));
				}
			});
	};


    // Todo:Render store reward List
	function renderPromoItemsList() {
		if (promoItemsList && promoItemsList.length > 0) {
			return promoItemsList.map((data, index) => (
				<Flex
					p="10px"
					bg={"#1c181e"}
					color={syWhite}
					borderLeft={"10px"}
					borderStyle={"solid"}
					borderLeftColor={
						data?.promo_enabled ? "green.200" : "red.200"
					}
					mb="4"
					flexWrap={"wrap"}
					key={data?.promo_uuid}
				>
                    <Flex w={'100%'} direction={'column'}>
                        <Flex w="100%" alignItems={"center"}>
                            <Text fontSize={"20px"} mr="3">
                                {data?.promo_name}
                            </Text>
                            <Flex>
                                <Button
                                    size="xs"
                                    colorScheme="purple"
                                    mr="2"
                                    textTransform={"capitalize"}
                                >
                                    {data?.promo_gender}
                                </Button>
                                <Button
                                    size="xs"
                                    colorScheme="pink"
                                    mr="2"
                                    textTransform={"capitalize"}
                                >
                                    {data?.promo_category}
                                </Button>
                            </Flex>
                        </Flex>
                        <Flex w="100%" alignItems={"center"}>
                            <Text fontSize={"20px"} mr="3">
                                {data?.promo_price}
                            </Text>
                            <Text fontSize={"20px"} mr="3">
                                {data?.promo_source}
                            </Text>
                        </Flex>
						<Flex w="100%" alignItems={"center"}>
							<Link
								target="_blank"
								href={data?.promo_url}
								mr="1"
							>
								<Text color={'blue.300'}>{data?.promo_url}</Text>
							</Link>
						</Flex>
                    </Flex>
					<Flex
						justifyContent={"space-between"}
						w="100%"
						alignItems={"center"}
					>
						{data?.promo_photo_url ? (
							<Img
								mt="2"
								h="100px"
								w="100px"
								objectFit={"cover"}
								borderRadius={"10px"}
								src={
									data?.promo_photo_url
										? data?.promo_photo_url
										: ""
								}
							/>
						) : (
							<Text
								fontSize={"sm"}
								fontWeight={"500"}
								color={"red.200"}
							>
								No Image Uploaded
							</Text>
						)}
						<Flex>
							<Switch
								colorScheme="green"
								mr="2"
								isChecked={data?.promo_enabled}
								onChange={() => {
									handlePromoItemStatus(data);
								}}
							/>
							<AiFillEdit
								fontSize={"20"}
								my="2"
								color={syThemeLabelColor}
								onClick={() => {
									setSelectedPromoItem(data);
									setIsEditClicked(prev => !prev);
								}}
							/>
                            {data?.promo_uuid
							?<UploadPromoItemImage
								promoId={data?.promo_uuid}
								passSuccessFlag={refreshPromoData}
							/>:null}
						</Flex>
					</Flex>
				</Flex>
			));
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					{selectedCatId == null
					?<Text
						mb="5px"
						fontSize={"16px"}
						fontWeight={"bold"}
						color={syThemeBgYellowColor}
					>
						{"Please select a Promo Category from the right side panel..."}
					</Text>
					:<Text
						mb="5px"
						fontSize={"16px"}
						fontWeight={"bold"}
						color={syThemeBgRedColor}
					>
						{"No Promo Items Found for the selected Promo Category!!"}
					</Text>}
				</Box>
			);
		}
	}

    // Todo:refrest data after icon upload
	const refreshPromoData = data => {
		if (data) {
			renderPromoList();
		}
	};

    return (
		<Flex h="90vh">
			<Box
				w={!configurationCollapseFlag ? "95%" : "80%"}
				bg={syBgGrey}
				p="3"
				borderRadius={"10px"}
				overflow="hidden"
			>
                <Flex w={'100%'} direction={'column'}>
                    <Flex alignItems={"center"} borderBottom={"1px"} mb="4" pb="4">
                        <Box
                            as="span"
                            flex="1"
                            textAlign="left"
                            color={syWhite}
                            fontWeight={"600"}
                            fontSize="20px"
                        >
                            Reward Store
                        </Box>
                        <CreatePromoItem
                            promoCategories={promoCategories}
                            editData={selectedPromoItem}
                            isEditClicked={isEditClicked}
                            triggerCall={renderPromoList}
                        />
                    </Flex>
                </Flex>
                <Box pt="10px" borderTopWidth={"1px"} borderColor={syBgGrey}>
                    <Text color={syWhite} fontWeight={"600"} fontSize="16px">
                        Promo Items List
                    </Text>
                    {loading ? (
                        <Flex alignItems="center" justifyContent="center" h="50vh">
                            <Spinner size="xl" color={syWhite} />
                        </Flex>
                    ) : (
                        <Box
                            mt="1rem"
                            h="calc(100vh - 250px)"
                            overflow="auto"
                            css={{
                                "&::-webkit-scrollbar": {
                                    width: "4px",
                                },
                                "&::-webkit-scrollbar-track": {
                                    width: "6px",
                                },
                                "&::-webkit-scrollbar-thumb": {
                                    background: "#fff",
                                    borderRadius: "24px",
                                },
                            }}
                        >
                            {renderPromoItemsList()}
                    </Box>
                    )}
                </Box>
			</Box>
			{configurationCollapseFlag ? (
				<Box
					w="20%"
					position="relative"
					p="3"
					borderRadius="15px"
					bg={syBgGrey}
					ml="2"
				>
					<Text color={syWhite} pb="15px" fontWeight={"600"}>
                        Promotion Categories
					</Text>
					<Box
						position="absolute"
						top="0"
						right="0"
						mr="2"
						mt="3"
						zIndex="999"
					>
						<AiFillRightCircle
							color="#fff"
							cursor="pointer"
							size="20px"
							bg={syGrey}
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
						/>
					</Box>
					<Box
						w="100%"
						h="90vh"
						overflow={"hidden"}
						overflowY="auto"
						borderTopWidth={"1px"}
						borderTopColor={syBorderGrey}
						css={{
							"&::-webkit-scrollbar": {
								width: "4px",
							},
							"&::-webkit-scrollbar-track": {
								width: "6px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: { syBgGrey },
								borderRadius: "24px",
							},
						}}
					>
                        {renderPromoCategories()}
					</Box>
				</Box>
			) : (
				<Box
					w="5%"
					p="3"
					borderRadius="10px"
					bg={syBgGrey}
					h="100%"
					ml="2"
					pl="5"
				>
					<Box w={"100%"} h="100%">
						<AiFillLeftCircle
							cursor="pointer"
							color="#fff"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
							size="20px"
						/>
						<Text
							fontSize="16px"
							color="#fff"
							mt="2"
							css={{
								writingMode: "vertical-lr",
								textOrientation: "mixed",
							}}
						>
							Expand Promotion Categories
						</Text>
					</Box>
				</Box>
			)}
		</Flex>
	);
}

export default YouTubePromoHome;
