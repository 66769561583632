import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const fetchGoalsList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().goalList, data, config);
};

export const handleGoalStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().enableDisableGoal, data, config);
};

export const createGoalData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addGoalDetails, data, config);
};

export const updateGoalData = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().editGoalDetails, data, config);
};

export const uploadGoalHeadshot = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().uploadGoalHeadshot, data, config);
};

export const fetchUserGoalList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().fetchUserGoal, data, config);
};

export const createGoalPlan = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().createGoalPlan, data, config);
};

export const updateGoalPlan = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().updateGoalPlan, data, config);
};

export const fetchGoalRewardIcon = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().goalRewardIcon, data, config);
};

export const createGoalEvent = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addGoalEvent, data, config);
};

export const fetchResourceGoalListData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().resourceListData, data, config);
};
export const updateResourceImgData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().resourceImageText, data, config);
};
export const manageResourceContData = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().resourceContManage, data, config);
};

export const fetchResourceList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().resourceList, data, config);
};

export const fetchGoalListWithResource = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().goalListWithResource, data, config);
};

export const storeResourceIcon = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(
		serviceAPIs().uploadGoalResourceIcon,
		data,
		config
	);
};

export const fetchSelectedGoalPlan = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().selectedGoalData, data, config);
};

// Reward Store Service

export const createRewardStoreItem = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().addNewStoreItem, data, config);
};

export const editRewardStoreItem = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().updateStoreItem, data, config);
};

export const handleStoreStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().manageStoreStatus, data, config);
};

export const fetchStoreRewardClass = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listStoreRewardClass, data, config);
};

export const fetchStoreRewardGroup = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listStoreRewardGroup, data, config);
};

export const fetchStoreRewardList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listStoreRewardList, data, config);
};

export const uploadRewardIcon = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().uploadRewardIcon, data, config);
};

export const fetchRewardListTransaction = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listRewardTranaction, data, config);
};

export const fetchUserClasses = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listUserClass, data, config);
};

export const fetchUserGroup = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().listUserGroup, data, config);
};

export const fetchBuyingCapability = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().capabilityRatio, data, config);
};

export const fetchClassBasedItems = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(
		serviceAPIs().listClassBasedDetails,
		data,
		config
	);
};

export const handleItemPurchase = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().purchaseItem, data, config);
};

export const handleItemPurchased = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().purchasedItems, data, config);
};

// ----------- Banking Data --------

export const getBankingDetailsInfo = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().connBankInfo, data, config);
};
export const getBankingLinkToken = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().connGetLinkToken, data, config);
};
export const getExchangeLinkToken = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().connExchangeLinkToken, data, config);
};


// ---------- Promo Data  -----------
export const fetchPromoCategories = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().fetchAdminPromoCats, data, config);
};

export const addPromoItemApi = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().addPromoItem, data, config);
};

export const updatePromoItemApi = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().updatePromoItem, data, config);
};

export const listingPromoItemsApi = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().listPromoItem, data, config);
};
export const apiPromoItemStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().managePromoItem, data, config);
};
export const apiPromoItemImgUpload = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().imagePromoItem, data, config);
};
