// React Chakra Imports
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { usePreview } from "react-dnd-preview";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Box, Img, Icon, Tabs, Tab, Spacer, Text, Flex, Modal, Button, TabList, Slider, Heading, Spinner, TabPanel, useToast, TabPanels, ModalBody, SliderMark, SliderThumb, SliderTrack, ModalOverlay, ModalContent, useDisclosure, ModalCloseButton, SliderFilledTrack, Grid } from "@chakra-ui/react";

// Component Imports
import moment from "moment";

import StandImg from "../../../images/genz/goal/stand.png";
import { getUserName } from "../../../utils/localStorageIndex";
import Cup from "../../../images/genz/cup.png";
import Star from "../../../images/genz/star.png";
import RackBoard from "../../../images/genz/goal/dreamlife/rack-board.png";
import TrophyBase from "../../../images/genz/goal/dreamlife/trophy-base.png";
import DreamLifeCard from "../../../images/genz/goal/dreamlife/card.png";
import DreamLifeAward from "../../../images/genz/goal/dreamlife/award.png";
import DreamLifeBgImg from "../../../images/genz/goal/dreamlife/dreamLifeBg.png";
import ShelfInRoom from "../../../images/genz/goal/dreamlife/shelfInRoom.png";
import DreamCompleteGiftImg from "../../../images/genz/goal/dreamlife/gift.png";
import { syWhite, syBlack, syBorderGrey, syArrowGreenColor, syThemeBgBlueColor, syThemeBgYellowColor } from "../../../constants/color";
import rating from "../../../images/genz/rating.png";

import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import CommonWidget from "../../common/CommonWidget";

// Api Imports
import { getDreamLifeTrophiesData, saveDreamLifeTrophiesData } from "../../../services/userServices";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import { AiOutlineStop } from "react-icons/ai";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const Backend = isMobile ? TouchBackend : HTML5Backend;

// Drag and Drop Item Type
const IMAGE_TYPE = "IMAGE";

// Image Slot Component
const ImageSlot = ({ image, onDrop, onRemove }) => {
	const [{ isOver }, drop] = useDrop({
		accept: IMAGE_TYPE,
		drop: item => {
			onDrop(item.image);
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	});
	return (
		<Box
			position={"relative"}
			ref={drop}
			w={"120px"}
			h={"100px"}
			rounded={"lg"}
			// borderWidth={'1px'}
			borderColor={isOver ? "green.300" : "gray.300"}
			// bg={image ? 'green.50' : 'gray.50'}
			align={"center"}
			justify={"center"}
		>
			{image ? (
				<Box position={"relative"} h={"100%"} w={"100%"}>
					<Img objectFit="contain" w={"80%"} h={"100%"} rounded={"lg"} src={image.url} alt={image.name} />
					<Icon position={"absolute"} bottom={0} left={"40%"} onClick={onRemove} as={AiOutlineStop} size={16} />
				</Box>
			) : (
				<Img w={"100%"} h={"100%"} rounded={"lg"} src={TrophyBase} alt={"Tropgy Base"} />
				// <Text>Place achivement trophy here</Text>
			)}
		</Box>
	);
};

// Draggable Image Component
const DraggableImage = ({ image, onDragStart }) => {
	const [{ isDragging }, drag] = useDrag({
		type: IMAGE_TYPE,
		item: { image },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	});

	return (
		<Box ref={drag} bg={"purple.50"} rounded={"lg"} mt={1} w="100px" maxW={"100px"} h={"160px"} mr="1%" p={1} cursor={"pointer"} borderWidth={isDragging ? "2px" : "0.5px"} borderColor={isDragging ? "red.300" : "gray.300"} borderStyle={isDragging ? "dashed" : "dotted"}>
			<Img h={"120px"} objectFit={"contain"} w={"90px"} rounded={"lg"} src={image.url} alt={image.name} />
			<Flex w={"100%"} h={"40px"} align={"center"} justify={"center"}>
				<Text fontSize={"12px"} fontWeight={"600"} textAlign={"center"}>
					{image.name}
				</Text>
			</Flex>
		</Box>
	);
};

function ManageShelf({}) {
	const toast = useToast();
	const navigate = useNavigate();
	const userName = getUserName();
	const [loading, isloading] = useState(false);
	const [goalsToday, setGoalsToday] = useState([]);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [completedGoals, setCompletedGoals] = useState([]);
	const [trophiesList, setTrophiesList] = useState([]);
	const [availableImages, setAvailableImages] = useState([]);

	const [rows, setRows] = useState([
		[null, null, null],
		[null, null, null],
		[null, null, null],
	]);

	// Add a new row of 3 empty slots
	// const addRow = () => {
	//     setRows(prevRows => [...prevRows, [null, null, null]]);
	// };

	useEffect(() => {
		fetchDashboardDetails();
	}, []);

	const fetchDashboardDetails = () => {
		isloading(true);
		let obj = {
			type: "trophy",
		};
		getDreamLifeTrophiesData(obj)
			.then(res => {
				isloading(false);
				if (res?.data[0]?.status) {
					setCompletedGoals(res?.data[0]?.goals);
					setTrophiesList(res?.data[0]?.trophies);
					updateImageList(res?.data[0]?.goals);
				}
			})
			.catch(err => {
				if (err) {
					isloading(false);
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const saveTrophiesDetails = allRows => {
		if (allRows && allRows?.length > 0) {
			isloading(true);
			let obj = {
				type: "trophy",
				items: JSON.stringify(allRows),
			};
			saveDreamLifeTrophiesData(obj)
				.then(res => {
					isloading(false);
					if (res?.data[0]?.status) {
						// setCompletedGoals(res?.data[0]?.goals);
						// updateImageList(res?.data[0]?.goals);
					}
				})
				.catch(err => {
					if (err) {
						isloading(false);
						toast(toastFunctionToaster(err?.message, "error"));
					}
				});
		}
	};

	const updateImageList = imgListObj => {
		if (imgListObj != null && imgListObj?.length > 0) {
			const newImages = imgListObj?.map(imgObj => ({
				id: Date.now() + Math.random(),
				url: imgObj?.goal_reward_icon ? imgObj?.goal_reward_icon : "https://syvalpub.s3-us-west-1.amazonaws.com/images/23ae5757-a3d0-493c-9a8d-936593f5536d-image-plane.png",
				name: imgObj?.goal_name,
			}));
			setAvailableImages(prev => [...prev, ...newImages]);
		}
	};

	useEffect(() => {
		if (trophiesList != null && trophiesList?.length > 0) {
			// Process the array and save to state
			const processedData = trophiesList.map(row =>
				row.map(item => {
					// Check if item is null
					if (item === null) {
						return null;
					}
					// Return the object if it's not null
					return {
						id: item?.id,
						url: item?.url,
						name: item?.name,
					};
				})
			);

			setRows(processedData);
		}
	}, [trophiesList]);

	// const updateRackItems = (trophyObj) => {
	//     if (trophyObj != null && trophyObj?.length > 0){
	//         const newRows = trophyObj.map(rowItems => ({
	//             return rowItems.map( tItem => ({
	//                 if (tItem != null){
	//                     return
	//                 }
	//             }))

	//         }))
	//         setRows(newRows);
	//     }
	// }

	const navigateToGoalEvent = data => {
		navigate("/genz/goal-event", {
			state: {
				data: data,
				goalPlanningId: data?.goal_planning_uuid,
			},
		});
	};

	const handleDrop = (rowIndex, slotIndex, image) => {
		// Todo:Check Null and return name if exist in row arr
		const itemAlreadyChecked = rows.some(row => row.some(slot => slot?.name === image.name));
		if (itemAlreadyChecked) {
			toast(toastFunctionToaster(image?.name + " is already placed on shelf", "error"));
			return; // Exit early
		}
		setRows(prevRows => {
			const newRows = prevRows.map(row => [...row]);
			// Remove image from available images
			setAvailableImages(prev => prev.filter(img => img.id !== image.id));
			// Remove image from any existing slot
			newRows.forEach(row => {
				const existingIndex = row.findIndex(slot => slot?.id === image.id);
				if (existingIndex !== -1) {
					row[existingIndex] = null;
				}
			});

			// Place image in new slot
			newRows[rowIndex][slotIndex] = image;
			saveTrophiesDetails(newRows);
			return newRows;
		});
	};

	// Remove image from a slot
	const handleRemove = (rowIndex, slotIndex) => {
		setRows(prevRows => {
			const newRows = prevRows.map(row => [...row]);
			const removedImage = newRows[rowIndex][slotIndex];

			// Add back to available images if not null
			if (removedImage) {
				setAvailableImages(prev => [...prev, removedImage]);
			}

			// Clear the slot
			newRows[rowIndex][slotIndex] = null;
			saveTrophiesDetails(newRows);
			return newRows;
		});
	};

	function renderCompletedGoal() {
		if (availableImages && availableImages.length > 0) {
			var completedGoalImage = availableImages.map(image => <DraggableImage key={image.id} image={image} />);
			return completedGoalImage;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text fontSize={16} color={syThemeBgYellowColor}>
						{"No Goals Found!!"}
					</Text>
				</Box>
			);
		}
	}

	return (
		<Box
			bgGradient={["linear(to-b, #a4a9f4, #2e3160)"]}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			// mx="auto"
			minH="940px"
		>
			<Box px="10px" py="10px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box px="10px" py="10px">
					<Flex alignContent={"center"} justifyContent={"center"} flexWrap={"wrap"} textAlign={"center"} mt="1rem" mb={3}>
						<Heading fontSize={"22px"} fontWeight={"600"} color={syWhite}>
							My Trophy Case
						</Heading>
					</Flex>
					<Flex w={"100%"} align={"center"} pr={1}>
						<DndProvider
							// backend={HTML5Backend}
							// // backend={TouchBackend}
							backend={Backend}
							options={{ enableMouseEvents: true }}
						>
							<Box bg={syWhite} borderRadius={"10px"} mb="2rem">
								<Box w={"350px"} h={"380px"} px="10px" pt="30px">
									{/* <Text textAlign={"center"} fontWeight="700">
                                        {userName} DreamLife
                                    </Text> */}
									{/* <Box position={"relative"} mt="3">
                                        <Img src={DreamLifeBgImg} />
                                        <Box position={"absolute"} bottom="34%" right={"14%"}>
                                            <Img src={ShelfInRoom} />
                                        </Box>
                                        <Box position={"absolute"} bottom="20%" left={"95px"}>
                                            <Img src={DreamLifeCard} />
                                        </Box>
                                    </Box> */}
									<Flex w={"100%"} px={5} direction={"column"}>
										{rows.map((row, rowIndex) => (
											<Flex w={"100%"} h={"120px"} backgroundImage={RackBoard} backgroundSize={"cover"} backgroundRepeat={"no-repeat"} backgroundPosition={"center"} key={rowIndex}>
												{row.map((image, slotIndex) => (
													<ImageSlot
														key={slotIndex}
														image={image}
														onDrop={droppedImage => {
															// handleDrop(rowIndex, slotIndex, droppedImage);
															saveTrophiesDetails(handleDrop(rowIndex, slotIndex, droppedImage));
														}}
														onRemove={() => {
															handleRemove(rowIndex, slotIndex);
														}}
													/>
												))}
											</Flex>
										))}
									</Flex>
								</Box>
								{/* <Box w={'100%'} align={'center'} px={10}>
                                    <Button 
                                        onClick={addRow}
                                        size={'xs'}
                                    >
                                        Add Rack
                                    </Button>
                                </Box> */}
								{loading ? (
									<Flex alignItems="center" h="30vh" justifyContent="center">
										<Spinner size="lg" color={syBlack} />
									</Flex>
								) : (
									<Box w={"100%"} pl={5} mt={10} mb={20}>
										<Text textAlign={"center"} fontWeight={"600"} color={syThemeBgBlueColor} mb="0.5rem">
											Achievements
										</Text>
										<Flex w={"100%"} direction={"column"}>
											<Flex maxH={"180px"} overflowX={"auto"} overflowY={"hidden"} w={"320px"} scrollBehavior={"smooth"}>
												<Grid p={1} w={"100%"} templateColumns="repeat(30, 1fr)" gap={1}>
													{renderCompletedGoal()}
												</Grid>
											</Flex>
										</Flex>
									</Box>
								)}
							</Box>
						</DndProvider>
					</Flex>
				</Box>
				<Box w={"100%"} h={"100px"}></Box>
			</Box>
			<FooterProfile position={"fixed"} bottom={"0px"} borderRadius="10px" userRoute="/genz/user-profile" />
		</Box>
	);
}

export default ManageShelf;
