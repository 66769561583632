// Chakra - React Imports
import React, { useEffect, useState } from "react";
import {
	Img,
	Box,
	Image,
	Text,
	Flex,
	Button,
	Drawer,
	Spinner,
	Grid,
	Spacer,
	Modal,
	ModalOverlay,
	ModalContent,
	SimpleGrid,
	InputGroup,
	Input,
	InputLeftElement,
	DrawerBody,
	DrawerHeader,
	useDisclosure,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports
import UploadResourceIcon from "./UploadResourceIcon";
import {
	syBgGrey,
	syWhite,
	syBorderGrey,
	syThemeBgYellowColor,
	syThemeBgBlueColor,
	syGrey,
	syBlack,
} from "../../../constants/color";

// Api Imports
import { fetchResourceGoalListData, 
	manageResourceContData,
	updateResourceImgData } from "../../../services/goalServices";

function ResourceList({ goalData }) {
	const btnRef = React.useRef();
	const [loading, isloading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [resourceGoal, setResourceGoal] = useState([]);
	const [uploadPanel, isUploadPanel] = useState(null);
	const [detailPanel, isDetailPanel] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [imageText, setImageText] = useState(null);
	const [selectedResUuid, setSelectedResUuid] = useState(null);
	

	useEffect(() => {
		fetchResourceListData();
	}, []);

	const fetchResourceListData = () => {
		isloading(true);
		let obj = {
			resource_uuid: goalData?.resource[0]?.resource_uuid,
		};
		fetchResourceGoalListData(obj)
			.then(res => {
				isloading(false);
				if (res?.data?.length) {
					setResourceGoal(res?.data[0]);
				}
			})
			.catch(err => {
				isloading(false);
			});
	};

	const saveResourceDetails = () => {
		if (imageText?.length > 0 && selectedResUuid != null){
			isloading(true);
			let obj = {
				resource_uuid: selectedResUuid,
				resource_text: imageText
			};
			updateResourceImgData(obj)
				.then(res => {
					isloading(false);
					if (res?.data[0]?.status) {
						fetchResourceListData();
						handleCloseData();
					}
				})
				.catch(err => {
					isloading(false);
				});
			}
	};

	const manageResourceContent = (selectedResUuid) => {
		if (selectedResUuid != null){
			isloading(true);
			let obj = {
				resource_cont_uuid: selectedResUuid,
			};
			manageResourceContData(obj)
				.then(res => {
					isloading(false);
					if (res?.data[0]?.status) {
						fetchResourceListData();
					}
				})
				.catch(err => {
					isloading(false);
				});
			}
	};

	const renderGoalDetails = (goalType) => {
		if (resourceGoal?.resource_content?.length) {
			let allGoals = resourceGoal;
			if (goalType == 'enabled'){
				allGoals = resourceGoal?.resource_content?.filter(data => {
					return data.resource_content_enabled === true
				})
			} else if (goalType == 'disabled'){
				allGoals = resourceGoal?.resource_content?.filter(data => {
					return data.resource_content_enabled === false
				})
			}
			return allGoals?.map((data, index) => {
				return (
					<Flex w={'150px'} 
						borderWidth={'2px'}
						rounded={'lg'}
						align={'center'}
						justify={'center'}
						borderColor={
							data?.added_by_user ? ( 
								'blue'
							) : (
								data?.resource_content_enabled ? 'green' : 'red'
							)
						}
						direction={'column'}>
						<Img
							h="100px"
							w="100px"
							mr="2"
							border="1px"
							borderRadius={"5px"}
							src={data?.resource_content_url}
						/>
						<Flex w={'150px'} direction={'column'} align={'center'}>
							<Text color={'gray.100'}>
								{data?.resource_content_details ? data?.resource_content_details : 'No Details'}
							</Text>
							<Button 
								onClick={() => manageResourceContent(data?.resource_content_uuid)}
								size={'xs'}>
								{data?.resource_content_enabled ? 'Disable' : 'Enable'}
							</Button>
							<Button 
								onClick={() => {
									isUploadPanel(false);
									isDetailPanel(true);
									setImageText(data?.resource_content_details ? data?.resource_content_details  : null);
									setSelectedResUuid(data?.resource_content_uuid);
									setImageUrl(data?.resource_content_url);
									onOpen();
								}}
								mt={1} w={'90px'} size={'xs'}>
								{data?.resource_content_details ? '+ Update Info' : '+ Add Info'}
							</Button>
						</Flex>
					</Flex>
				);
			});
		} else {
			return (
				<Text fontWeight={"600"} color={syWhite}>
					No Resource Images...
				</Text>
			);
		}
	};

	const refreshGoalData = value => {
		if (value === "true") {
			fetchResourceListData();
			handleCloseData();
		}
	};

	const handleCloseData = () => {
		onClose();
	};

	return (
		<Box bg={syBgGrey} p="2" borderRadius={"5px"}>
			{loading ? (
				<Flex alignItems="center" justifyContent="center">
					<Spinner size="sm" p="10px" color={syWhite} />
				</Flex>
			) : (
				<Flex w={'100%'} p={2} justifyContent="space-between" alignItems="center">
					<Flex w={'100%'} direction={'column'}>
						<Flex w={'100%'}>
							<Text color={'red'}>Red:</Text>
							<Text ml={1} color={'gray'}>Disabled</Text>
							<Text ml={2} color={'green'}>Green: Enabled</Text>
							<Text ml={1} color={'gray'}>Enabled</Text>
							<Text ml={2} color={'blue'}>Blue:</Text>
							<Text ml={1} color={'gray'}>Added by Users</Text>
							<Spacer />
							<Button
								w={'100px'}
								size="xs"
								ref={btnRef}
								colorScheme="purple"
								onClick={() => {
									onOpen();									
									isUploadPanel(true);
									isDetailPanel(false);
								}}
							>
								Add Image
							</Button>
						</Flex>
						<Flex w={'100%'} p={2} alignItems="center" rounded={'md'} border={'1px'} borderColor={'yellow.100'}>
							<Grid w={'100%'} templateColumns="repeat(6, 1fr)" gap="6">
							{renderGoalDetails('enabled')}
							</Grid>
						</Flex>
						<Flex w={'100%'} p={2} alignItems="center" rounded={'md'} border={'1px'} borderColor={'yellow.100'}>
							<Grid w={'100%'} templateColumns="repeat(6, 1fr)" gap="6">
							{renderGoalDetails('disabled')}
							</Grid>
						</Flex>
					</Flex>
				</Flex>
			)}
			<Drawer
				isOpen={isOpen}
				onClose={() => {
					handleCloseData();
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={syThemeBgBlueColor}>
					<DrawerHeader color={syWhite} borderBottomWidth={"0.5px"}>
						{uploadPanel  && !detailPanel? 'Update Resource Icon' : null}
						{detailPanel  && !uploadPanel ? 'Add Image Details' : null}
					</DrawerHeader>
					<DrawerCloseButton color="#d7d7d7" />
					<DrawerBody>
						{uploadPanel  && !detailPanel
						?<UploadResourceIcon
							resourceGoal={resourceGoal}
							passSuccessFlag={refreshGoalData}
						/>:null}
						{detailPanel  && !uploadPanel 
						?<Flex w={'100%'} direction={'column'} color={'gray.100'}>
							<Text>Please input Details about the given goal resource</Text>
							<Image src={imageUrl} h={'100px'} w={'100px'} />
							<InputGroup                    
                                border="none"
                                bg={syBlack}
                                w={'100%'}
                                borderRadius={'lg'}
                                borderTopLeftRadius={"10px"}
                                borderTopRightRadius={"10px"}
                            >
                                <Input
                                w={'100%'}
                                    borderWidth="1px"
                                    boxShadow="lg"
                                    borderColor={syBorderGrey}
                                    type="text"
                                    placeholder=" Image Description"
                                    _hover={{ boxShadow: "none" }}
                                    h="50px"
                                    value={imageText}
                                    onChange={e => {
                                        setImageText(e.target.value);
                                    }}
                                />
                            </InputGroup>
							{imageText?.length > 0
							?<Button
								mt={1}
								w={'200px'}
								size={'md'}
								onClick={ () => {
									saveResourceDetails();
								}}
							>
								Save Image Description
							</Button>
							:<Button
								mt={1}
								w={'200px'}
								size={'md'}
								disabled={true}
								color={'gray.300'}
							>
								Save Image Description
							</Button>}
						</Flex>
						:null}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}

export default ResourceList;
