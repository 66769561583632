// Chakra Imports
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, 
    Img, 
    Image,
    Flex,
    Grid,
    Icon,
    Heading,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Modal,
    ModalHeader,
	ModalBody,
	ModalOverlay,
	ModalContent,
	useDisclosure,
    ModalCloseButton,
    useToast,
    SlideFade,
    Text, Button, 
    SimpleGrid,
    Spacer} from "@chakra-ui/react";

// Component Imports
import syRocket from "../../images/boarding/rocket.png";
// import AvantGrade from '../../images/promo/AvantGradeHoliday.jpeg';
// import ClassicalHoliday from '../../images/promo/ClassicalHolidayMastreo.jpeg';
// import CountryChristmas from '../../images/promo/CountryChristmasCharmer.jpeg';
// import CozyNiche from '../../images/promo/CozyNicheCollector.jpeg';
// import ElectricHoliday from '../../images/promo/ElectricHolidayVibes.jpeg';
// import FestiveBeats from '../../images/promo/FestiveBeatsVibe.jpeg';
// import GlamRockstar from '../../images/promo/GlamRockstar.jpeg';
// import GlobalFestive from '../../images/promo/GlobalFestiveExplorer.jpeg';
// import RapRoyale from '../../images/promo/RapRoyale.jpeg';
// import SoulfulSnowflake from '../../images/promo/SoulfulSnowflake.jpeg';

import christmaslights from "../../images/promo/christmaslights.png";
import snowback from '../../../src/images/promo/snowback.jpg';
import { toastFunctionToaster } from "../../utils/toasterFunction";
import { AiOutlineClose} from "react-icons/ai";

import {
    syBlack,
    syGrey,
	syPurple,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../constants/color.js";
import useResponsiveHeight from "../../customHook/ResponsiveHeight.js";

// Api Services
import { getWishlistData, getCategoryData, updateCategoryData } from "../../services/userServices";

function Wishlist() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
	const height = useResponsiveHeight();
	const { isOpen, onOpen, onClose } = useDisclosure();

    const [customizeOpen, setCustomizeOpen] = useState(false);

    const OverlayOne = () => (
        <DrawerOverlay
            // bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        //   bg='blackAlpha.100'
        //   backdropFilter='blur(1px) hue-rotate(90deg)'
        />
    )
    const [overlay, setOverlay] = React.useState(<OverlayOne />)

    const personalityKeywords = useState([
        {'id': 'AvantGarde', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/AvantGardeHoliday30.jpg'},
        {'id': 'ClassicalHoliday', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/ClassicalHolidayMastreo30.jpg'},
        {'id': 'CountryChristmas', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/CountryChristmasCharmer30.jpg'},
        {'id': 'CozyNiche', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/CozyNicheCollector30.jpg'},
        {'id': 'ElectricHoliday', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/ElectricHolidayVibes30.jpg'},
        {'id': 'FestiveBeats', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/FestiveBeatsVibe30.jpg'},
        {'id': 'GlamRockstar', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/GlamRockstar30.png'},
        {'id': 'GlobalFestive', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/GlobalFestiveExplorer30.jpg'},
        {'id': 'RapRoyale', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/RapRoyale30.jpg'},
        {'id': 'SoulfulSnowflake', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/SoulfulSnowflake30.jpg'}
    ])

    const prefId = state?.prefId ? state?.prefId : null;

    const [fetchLoading, setFetchLoading] = useState(false);
    const [wishlistItems, setWishistItems] = useState([]);
    const [categoryItems, setCategoryItems] = useState([]);
    const [personalityId, setPersonalityId] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [pageBg, setPageBg] = useState(snowback);

    useEffect(() => {
		fetchWishlistDetails();
	}, [prefId]);

	// The fetchGoalDetails function remains the same
	const fetchWishlistDetails = () => {
        if (prefId != null){
            setFetchLoading(true);
            let obj = {
                preferenceId: prefId,
            };
            return getWishlistData(obj)
                .then(res => {
                    setFetchLoading(false);
                    if (res?.result) {
                        if (res?.data[0]?.status && res?.data[0]?.items?.length > 0)
                        {
                            setWishistItems(res?.data[0]?.items);
                            if (res?.data[0]?.personality_id){
                                setPersonalityId(res?.data[0]?.personality_id);
                                setBackgroundImage(updatePersonalityBackground(res?.data[0]?.personality_id));
                            }
                            // toast(toastFunctionToaster(res?.message, "success"));
                        } else {
                            toast(toastFunctionToaster(res?.message, "error"));
                        }
                    } else {
                        toast(toastFunctionToaster(res?.message, "error"));
                    }
                })
                .catch(err => {
                    setFetchLoading(false);
                    if (err) {
                        toast(toastFunctionToaster(err?.message, "error"));
                    }
                });
        }
	};

    // The fetchGoalDetails function remains the same
	const fetchCategoryItems = (promo_category) => {
        if (promo_category != null){
            setFetchLoading(true);
            let obj = {
                preferenceId: prefId,
                promo_category: promo_category,
            };
            return getCategoryData(obj)
                .then(res => {
                    setFetchLoading(false);
                    if (res?.result) {
                        if (res?.data[0]?.status && res?.data[0]?.items?.length > 0)
                        {
                            setCategoryItems(res?.data[0]?.items);
                            // toast(toastFunctionToaster(res?.message, "success"));
                        } else {
                            toast(toastFunctionToaster(res?.message, "error"));
                        }
                    } else {
                        toast(toastFunctionToaster(res?.message, "error"));
                    }
                })
                .catch(err => {
                    setFetchLoading(false);
                    if (err) {
                        toast(toastFunctionToaster(err?.message, "error"));
                    }
                });
        }
	};

    const handleUpdateItem = (newItem) => {
        setWishistItems(prevItems => prevItems.map(item => 
          item.promo_uuid === selectedItem?.promo_uuid ? newItem : item
        ));
    };

    const replaceWishlistItem = (id, newItem) => {
        setSelectedItem(newItem);
        setWishistItems(prevItems => prevItems.map(item => 
          item?.promo_uuid === id ? newItem : item
        ));

    };


    // const handleUpdateItem = (newItem) => {
    //     const allItems = [...wishlistItems];

    //     for (let i = 0; i < allItems.length; i++) {
    //         if (allItems[i]?.promo_uuid == newItem?.promo_uuid){

    //         }
    //     }

    //     setWishistItems(prevItems => prevItems.map(item => 
    //       item.promo_uuid === selectedItem?.promo_uuid ? newItem : item
    //     ));
    // };

    const updatePersonalityBackground = (pxId) => {
        if (pxId != null || personalityId != null){
            let pId = pxId || personalityId;
            pId = pId.replaceAll(" ","");
            pId = pId.replaceAll("-","");
            for (var item in personalityKeywords[0]){
                if (pId.toLowerCase().includes(personalityKeywords[0][item]['id'].toLowerCase()) === true){
                    setPageBg(personalityKeywords[0][item]['image']);
                    return(personalityKeywords[0][item]);

                }
            }
            return snowback;
        }
        return snowback;
    }

    const renderWishlistItems = () => {
		if (wishlistItems?.length) {
			return wishlistItems?.map((data, index) => {
				return (
					<Flex key={index} 
                        p={1}
                        rounded={'lg'}
                        bg={data?.promo_uuid === selectedItem?.promo_uuid ? '#e0b0ff' : null}
                        // borderWidth={'1px'}
                        // borderWidth={data?.promo_uuid === selectedItem?.promo_uuid ? '1px' : '0px'}
                        // borderColor={data?.promo_uuid === selectedItem?.promo_uuid ? syPurple : null}
                        align={'center'} justify={'center'}>
						<Img
							h="150px"
							w="150px"
							mr="2"
                            onClick={() => {
                                setSelectedItem(data);
                                setCategoryItems([]);
                                fetchCategoryItems(data?.promo_category, true);
                                onOpen();
                            }}
                            rounded={'lg'}
                            src={data?.promo_photo_url}
						/>
					</Flex>
				);
			});
        }
	};

    const renderCategoryItems = () => {
		if (categoryItems?.length) {
			return categoryItems?.map((data, index) => {
                if (data?.promo_uuid != selectedItem?.promo_uuid 
                        && data?.promo_photo_url != null && data?.promo_enabled != false){
				return (
					<Flex key={index} 
                        p={2}
                        onClick={() => {
                            // handleUpdateItem(data);
                            replaceWishlistItem(selectedItem?.promo_uuid, data);
                        }}
                        align={'center'}>
						<Img
							h="120px"
							minW="150px" maxW={'150px'}
							mr="2"
							src={data?.promo_photo_url}
						/>
					</Flex>
				);
                }
			});
        }
	};

    const handleModalCloseData = () => {
        onClose();
        setSelectedItem(null);
    }

	return (
		<Box
            backgroundImage={pageBg ? pageBg : snowback}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={'center'}
            color={'black'}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
        >
            <Box textAlign={"center"} textColor={'gray.100'}>
                <Image src={christmaslights} />
                <Heading
                    rounded={'lg'}
                    // bg={'gray.600'}
                    fontSize={'4xl'}
                    textAlign={"center"}
                    fontWeight="500"
                    color={'gray.700'}
                >
                    {personalityId ? personalityId : 'Holiday Wishlist'}
                </Heading>
                <Heading
                    rounded={'lm'}
                    mt={4}
                    fontSize={'lg'}
                    textAlign={"center"}
                    fontWeight="500"
                    color={'gray.900'}
                >
                    Here are some items we think you'll love, based on your listening habits!!
                </Heading>
                <Text mt={4} fontSize={'sm'} color={'gray.700'}>
                    Click on each item to customize
                </Text>
                {prefId != null
                ?<Box w={'100%'} 
                    rounded={'lg'}
                    // boxShadow={'lg'}
                    // borderColor={'gray.500'}
                    // borderWidth={'0.5px'}
                    // h={'calc(100vh - 350px)'} 
                    h={'600px'}
                    mt="1rem" textAlign={"center"}>
                    <Flex w={'100%'} h={'60%'}>
                        <SimpleGrid columns={2} spacing={5}>
                            {renderWishlistItems()}
                        </SimpleGrid>
                    </Flex>
                    <Flex w={'100%'} align={'center'} direction={'column'} justify={'center'}>
                        {/* <Button
                            size={'lg'}
                            w={'200px'}
                            rounded={'xl'}
                            align={'center'}
                            justify={'center'}
                            color={syGrey}
                            bg={syBlack}
                            // onClick={() => {
                            //     navigate("/promotion")
                            // }}
                            >
                            View Wishlist
                        </Button> */}
                        <Button
                            size={'lg'}
                            w={'250px'}
                            mt={5}
                            boxShadow={'xl'}
                            // borderRadius={'10%'}
                            rounded={'2xl'}
                            align={'center'}
                            justify={'center'}
                            color={syGrey}
                            bg={syBlack}
                            onClick={() => {
                                navigate("/promotion/wishlist/share", {
                                    state: { 
                                        preferenceId: prefId,
                                        wishlistItems: wishlistItems,
                                        personalityId: personalityId,
                                        pageBg: pageBg
                                    },
                                });
                            }}
                            >
                            Save & Share
                        </Button>
                    </Flex>
                </Box>
                :<Box w={'100%'} 
                    rounded={'lg'}
                    boxShadow={'lg'}
                    borderColor={'red.500'}
                    borderWidth={'0.5px'}
                    h={'calc(100vh - 250px)'} mt="1rem" textAlign={"center"}>
                        <Flex w={'100%'} justify={'center'} align={'center'} direction={'column'} >
                            <Heading mt={5} w={'60%'} color={'black'} fontSize={'2xl'}>
                                You don't have proper credentials to access this page..
                            </Heading>
                            <Button
                                mt={10}
                                rounded={'lg'}
                                w={'80%'}
                                align={'center'}
                                justify={'center'}
                                bg={syGrey}
                                onClick={() => {
                                    navigate("/promotion")
                                }}
                                >
                               Restart your Wishlist..
                            </Button>
                        </Flex>
                </Box>}
            </Box>
            <Drawer
                p={1}
                // backdropFilter="auto"
                // backdropBlur="28px"
                w={'90%'}
                mr={10}
                maxHeight={'160px'}
                height={'160px'}
                placement='bottom'
				isOpen={isOpen}
				onClose={handleModalCloseData}
				//closeOnOverlayClick={goalCompletedFlag ? false : true}
			>
				{/* {overlay} */}
                {/* <SlideFade in={isOpen}> */}
				<DrawerContent
                    // containerProps={{ justifyContent: 'flex-center', paddingRight: '2rem' }}
					m={5}
                    p={1}
					rounded={'lg'}
				>
                        <DrawerHeader py={1}>
                            <Flex w={'100%'}>
                                <Text>Replace your item</Text>  
                                {/* <Spacer /> 
                                <Icon as={AiOutlineClose}
                                    onClick = {() => {
                                        setCustomizeOpen(false);
                                        setSelectedItem(null);
                                        onClose();
                                    }}
                                /> */}
                            </Flex>
                        </DrawerHeader>
						<DrawerCloseButton/>
						<DrawerBody 
                            p={0}
                            >
                            <Flex w={'100%'} direction={'column'}>
                                <Flex 
                                    w={'100%'} 
                                    rounded={'lg'}
                                    maxH={'180px'}
                                    overflowX={'auto'}
                                    overflowY={'hidden'}
                                    >
                                    <Grid templateColumns='repeat(100, 1fr)' gap={2}>
                                        {renderCategoryItems()}
                                    </Grid>
                                </Flex>
                            </Flex>
                        </DrawerBody>
				</DrawerContent>
                {/* </SlideFade> */}
			</Drawer>
		</Box>
	);
}

export default Wishlist;
