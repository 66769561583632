// Chakra Imports
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Img,
	Box,
	Flex,
	Text,
	Select,
	Button,
	Heading,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import CommonHeader from "../common/CommonHeader";
import bulb from "../../images/genz/lightBulb.png";
import CommonWidget from "../common/CommonWidget";
import { getEmailId } from "../../utils/localStorageIndex";
import useResponsiveHeight from "../../customHook/ResponsiveHeight";
import { toastFunctionToaster } from "../../utils/toasterFunction";
import { addBaseInfo, getBaseInfo } from "../../services/userServices";
import {
	syBlack,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../constants/color";

function GoalDetails() {
	const toast = useToast();
	const emailId = getEmailId();
	const navigate = useNavigate();
	const { state } = useLocation();
	const editGoalFlag = state?.goalFlag;
	const height = useResponsiveHeight();
	const [loading, isLoading] = useState(false);
	const [pastExpectation, setPastExpectation] = useState("0");
	const [syvalSource, setSyvalSource] = useState("Friends and Family");
	const [monthlyExpectation, setMonthlyExpectation] = useState("50");

	useEffect(() => {
		// checkBaseUploadedDetails();
	}, [editGoalFlag]);

	const checkBaseUploadedDetails = () => {
		isLoading(true);
		let obj = {
			key: emailId,
		};
		getBaseInfo(obj)
			.then(res => {
				isLoading(false);
				if (res?.data[0]?.preference_uuid) {
					setSyvalSource(res?.data?.[0]?.syval_source);
					setPastExpectation(res?.data?.[0]?.past_month_saving);
					setMonthlyExpectation(
						res?.data?.[0]?.monthly_saving_expectation
					);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const storeBaseInfo = () => {
		isLoading(true);
		let obj = {
			syval_source: syvalSource,
			amount_currency: "$",
			monthly_saving_expectation: parseInt(monthlyExpectation),
			past_month_saving: parseInt(pastExpectation),
		};
		addBaseInfo(obj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					navigate("/genz/complete", { state: { editGoal: true } });
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	return (
		<Box
			h={height}
			bg={syThemeBgBlueColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Flex
					alignContent={"center"}
					justifyContent={"center"}
					flexWrap={"wrap"}
					textAlign={"center"}
					mt="2rem"
				>
					<Heading
						fontSize={"22px"}
						fontWeight={"700"}
						color={syWhite}
					>
						Help us know you better!
					</Heading>
				</Flex>
				<Flex alignItems={"center"} justifyContent={"center"} mt="4rem">
					<Img src={bulb} />
				</Flex>
				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syThemeBgYellowColor}
					borderRadius={"8px"}
				>
					<Box mb="2rem">
						<Heading
							fontSize={"18px"}
							fontWeight={"600"}
							color={syBlack}
							mb="1rem"
							textAlign={"center"}
						>
							How did you hear about Syval?
						</Heading>
						<Select
							bg={syWhite}
							size="lg"
							_focus={{
								borderColor: syWhite,
								boxShadow: "none",
							}}
							value={syvalSource}
							onChange={e => {
								setSyvalSource(e.target.value);
							}}
						>
							<option value="Friends and Family">
								Friends and Family
							</option>
							<option value="TikTok">TikTok</option>
							<option value="Instagram">Instagram</option>
							<option value="Linkedin">Linkedin</option>
							<option value="Other">Other</option>
						</Select>
					</Box>
					<Box mb="2rem">
						<Heading
							fontSize={"18px"}
							fontWeight={"600"}
							color={syBlack}
							mb="1rem"
							textAlign={"center"}
						>
							How much did you plan to save each month?
						</Heading>
						<Select
							bg={syWhite}
							size="lg"
							_focus={{
								borderColor: syWhite,
								boxShadow: "none",
							}}
							value={monthlyExpectation}
							onChange={e => {
								setMonthlyExpectation(e.target.value);
							}}
						>
							<option value="0">$ 0</option>
							<option value="50">$1 -$50</option>
							<option value="100">$50 - $100</option>
							<option value="200">$100 - $200</option>
							<option value="500">$200 - $500</option>
							<option value="501">More than $500</option>
						</Select>
					</Box>
					<Box mb="2rem">
						<Heading
							fontSize={"18px"}
							fontWeight={"600"}
							color={syBlack}
							mb="1rem"
							textAlign={"center"}
						>
							How much did you actually save in the past month?
						</Heading>
						<Select
							bg={syWhite}
							size="lg"
							_focus={{
								borderColor: syWhite,
								boxShadow: "none",
							}}
							value={pastExpectation}
							onChange={e => {
								setPastExpectation(e.target.value);
							}}
						>
							<option value="0">$ 0</option>
							<option value="50">$1 -$50</option>
							<option value="100">$50 - $100</option>
							<option value="200">$100 - $200</option>
							<option value="500">$200 - $500</option>
							<option value="501">More than $500</option>
						</Select>
					</Box>
					<Box textAlign={"center"}>
						<Button
							borderRadius={"50px"}
							size="lg"
							colorScheme="purple"
							onClick={() => {
								storeBaseInfo();
							}}
						>
							Continue
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default GoalDetails;
