// Chakra React Imports
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import React, { useState, useEffect } from "react";
import { Box, Flex, Img, Text, VStack, Heading } from "@chakra-ui/react";

// Component Imports
import calenderImg from "../../../images/genz/calender-white.png";
import { syBlack, syPurple, syThemeBgYellowColor, syWhite } from "../../../constants/color";

const WeekCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [weekDates, setWeekDates] = useState([]);
	const [toggleCalender, setToggleCalender] = useState(false);

	// Function to get the week dates based on the selected date
	const getWeekDates = date => {
		const start = moment(date).subtract(3, "days"); // Start 3 days before
		const dates = [];
		for (let i = 0; i < 7; i++) {
			dates.push(moment(start).add(i, "days"));
		}
		return dates;
	};

	// Function to update week dates only when selecting from the calendar
	const updateWeekDatesFromCalendar = date => {
		setWeekDates(getWeekDates(date));
		setSelectedDate(date);
	};

	// Initialize the week dates on component mount
	useEffect(() => {
		setWeekDates(getWeekDates(selectedDate));
	}, []);

	const isSelectedDate = date => moment(date).isSame(selectedDate, "day");

	const handleDateClick = date => {
		setSelectedDate(date.toDate()); // Update selected date only
	};

	const handleCalendarChange = date => {
		updateWeekDatesFromCalendar(date); // Update week range and selected date
	};

	const selectedMonthYear = moment(selectedDate).format("MMMM YYYY");

	return (
		<VStack align="center">
			<Box borderRadius="lg" w="100%">
				<Flex justifyContent={"space-between"} alignItems="center" my="1rem">
					<Heading color={syBlack} as="h3" size="md" mb={4}>
						{selectedMonthYear}
					</Heading>
					<Img
						src={calenderImg}
						onClick={() => {
							setToggleCalender(!toggleCalender);
						}}
					/>
				</Flex>
				{toggleCalender ? <Calendar onChange={handleCalendarChange} value={selectedDate} w="100%" /> : null}
				<Flex justifyContent={"space-between"} mt="1rem">
					{weekDates.map((date, index) => (
						<Box key={index}>
							<Box p="10px" borderRadius={"30px"} textAlign={"center"} bg={isSelectedDate(date) ? syThemeBgYellowColor : "#8f95f2"} color={isSelectedDate(date) ? syPurple : "inherit"} onClick={() => handleDateClick(date)} boxShadow="0px 5px 5px 0px rgba(0, 0, 0, 0.75)">
								<Text color={isSelectedDate(date) ? syPurple : syPurple} fontWeight={600}>
									{date.format("dd")}
								</Text>
								<Text color={isSelectedDate(date) ? syPurple : syWhite} fontWeight={"bold"}>
									{date.format("DD")}
								</Text>
							</Box>
							{isSelectedDate(date) ? <Box w="10px" mt="3" mx="auto" borderRadius={"10px"} h="10px" bg={syThemeBgYellowColor} /> : null}
						</Box>
					))}
				</Flex>
			</Box>
		</VStack>
	);
};

export default WeekCalendar;
