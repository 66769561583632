// Chakra Imports
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";
import { Box, 
    Img, 
    Image,
    Flex,
    Heading,
    useToast,
    Modal,
	ModalBody,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	TableContainer,
	ModalCloseButton,
    Text, Button, 
    SimpleGrid} from "@chakra-ui/react";

// Component Imports
import syRocket from "../../images/boarding/rocket.png";
import stripe from '../../images/promo/strip30.png';

import christmaslights from "../../images/promo/christmaslights.png";
import promoback01 from '../../../src/images/promo/promoback01.jpg';
import snowback from '../../../src/images/promo/snowback.jpg';
import { toastFunctionToaster } from "../../utils/toasterFunction";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { AiOutlineShareAlt, AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";

import {
    syBlack,
    syGrey,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../constants/color.js";
import useResponsiveHeight from "../../customHook/ResponsiveHeight.js";

// Api Services
import { saveWishlistData } from "../../services/userServices";

function WishlistShare() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();

    const height = useResponsiveHeight();
    const imageRef = useRef();

    const preferenceId = state?.preferenceId ? state?.preferenceId : null;
    const wishlistItems = state?.wishlistItems ? state?.wishlistItems : null;
    const personalityId = state?.personalityId ? state?.personalityId : 'Holiday Wishlist';
    const pageBg = state?.pageBg ? state?.pageBg : snowback;

    const [fetchLoading, setFetchLoading] = useState(false);
    const [sharedUuid, setSharedUuid] = useState(null);
    const [base64DivImg, setBase64DivImg] = useState(null);

    const OverlayOne = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        //   bg='blackAlpha.100'
        //   backdropFilter='blur(1px) hue-rotate(90deg)'
        />
    )
    const [overlay, setOverlay] = React.useState(<OverlayOne />)

      
    const renderWishlistItems = () => {
		if (wishlistItems?.length) {
			return wishlistItems?.map((data, index) => {
				return (
					<Flex key={index} 
                        p={2}
                        justify={'center'}
                        align={'center'}>
						<Img
							h="120px"
							w="120px"
							mr="2"
                            rounded={'lg'}
							src={data?.promo_photo_url}
						/>
					</Flex>
				);
			});
		} 
	};

    // The fetchGoalDetails function remains the same
	const saveWishlistForShare = () => {
        if (preferenceId != null && wishlistItems?.length > 0){
            setFetchLoading(true);
            let obj = {
                preferenceId: preferenceId,
                wishlistItems: JSON.stringify({"items": wishlistItems})
            };
            return saveWishlistData(obj)
                .then(res => {
                    setFetchLoading(false);
                    if (res?.result) {
                        if (res?.data[0]?.status && res?.data[0]?.shareUuid)
                        {
                            setSharedUuid(res?.data[0]?.shareUuid);
                            onEncodeImageClick();
                            onOpen();
                            //toast(toastFunctionToaster(res?.message, "success"));
                            // shareOnMobile({
                            //     text: "Checkout my personalized wishlist, inspired by my favorite YouTube videos!",
                            //     url: 'https://mysyval.com/promotion/wishlist2024?promoId=' +sharedUuid,
                            //     title: "Wishlist 2024",
                            //     images: [base64DivImg],
                            //   });
 
                        } else {
                            toast(toastFunctionToaster(res?.message, "error"));
                        }
                    } else {
                        toast(toastFunctionToaster(res?.message, "error"));
                    }
                })
                .catch(err => {
                    setFetchLoading(false);
                    if (err) {
                        toast(toastFunctionToaster(err?.message, "error"));
                    }
                });
        }
	};
    
    const onSaveImageClick = useCallback(() => {
        if (imageRef.current === null) {
          return
        }
    
        toPng(imageRef.current, { cacheBust: true, })
          .then((dataUrl) => {
            const link = document.createElement('a')
            link.download = 'syval-wishlist.png'
            link.href = dataUrl
            link.click()
          })
          .catch((err) => {
            console.log(err)
          })
    }, [imageRef])


    const onEncodeImageClick = useCallback(() => {
        if (imageRef.current === null) {
          return
        }
    
        toPng(imageRef.current, { cacheBust: true, })
          .then((dataUrl) => {
            setBase64DivImg(dataUrl)
          })
          .catch((err) => {
            console.log(err)
          })
    }, [imageRef])

    const handleModalCloseData = () => {
        onClose();
    }

    const webShare = async (title, text, blob) => {
        const data = {
          files: [
            new File([blob], 'file.png', {
              type: blob.type,
            }),
          ],
          title: title,
          text: text,
        };
        try {
          if (!(navigator.canShare(data))) {
            throw new Error("Can't share data.", data);
          }
          await navigator.share(data);
        } catch (err) {
          console.error(err.name, err.message);
        }
    };

	return (
		<Box
            backgroundImage={stripe}
            backgroundSize={'cover'}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={'center'}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
		>
            {wishlistItems != null && wishlistItems?.length > 0
			?<Flex w={'100%'} direction={'column'}>
                <Box 
                    h={'600px'}
                    ref={imageRef}
                    rounded={'lg'}
                    backgroundImage={pageBg ? pageBg : snowback}
                    backgroundSize={'cover'}
                    backgroundRepeat={'no-repeat'}
                    backgroundPosition={'center'}       
                    textAlign={"center"} mt={20}>
                    <Image src={christmaslights} h={'100px'} />
                    <Heading
                        rounded={'lg'}
                        // bg={'gray.100'}
                        fontSize={'3xl'}
                        textAlign={"center"}
                        fontWeight="500"
                        color={'gray.700'}
                    >
                        {personalityId}
                    </Heading>
                    <Box w={'100%'} 
                        rounded={'lg'}
                        h={'400px'} mt="1rem" textAlign={"center"}>
                        <Flex w={'100%'} h={'100%'} align={'center'} justify={'center'}>
                            <SimpleGrid columns={2} spacing={10}>
                                {renderWishlistItems()}
                            </SimpleGrid>
                        </Flex>
                    </Box>
                </Box>
                <Box w={'100%'} align={'center'} mt={5}> 
                    <Flex w={'100%'} align={'center'} direction={'column'} justify={'center'}>
                        {/* <Button
                            size={'lg'}
                            w={'200px'}
                            rounded={'xl'}
                            align={'center'}
                            justify={'center'}
                            color={syGrey}
                            bg={syBlack}
                            boxShadow={'lg'}
                            borderColor={'gray.500'}
                            borderWidth={'0.5px'}
                            onClick={() => {
                                onSaveImageClick()
                            }}
                            >
                            Save Picture
                        </Button> */}
                        <Button
                            size={'lg'}
                            w={'200px'}
                            mt={5}
                            rounded={'xl'}
                            align={'center'}
                            justify={'center'}
                            color={syGrey}
                            bg={syBlack}
                            boxShadow={'lg'}
                            borderColor={'gray.500'}
                            borderWidth={'0.5px'}
                            onClick={() => {
                                saveWishlistForShare()
                            }}
                            >
                            Save & Share
                        </Button>
                    </Flex>
                </Box>
            </Flex>
            :<Box w={'100%'} 
                rounded={'lg'}
                boxShadow={'lg'}
                borderColor={'red.500'}
                borderWidth={'0.5px'}
                h={'calc(100vh - 250px)'} mt="1rem" textAlign={"center"}>
                    <Text>Please run the promotion from start again</Text>
            </Box>}
            <Modal
                height={'80vh'}
				isOpen={isOpen}
				onClose={handleModalCloseData}
				size={"sm"}
				//closeOnOverlayClick={goalCompletedFlag ? false : true}
			>
				{overlay}
				<ModalContent
                    containerProps={{ justifyContent: 'flex-center', paddingRight: '2rem' }}
					m="5"
                    p={10}
                    bg={syBlack}
					borderRadius="10px"
					// bgImage={progressCompleteBg}
					bgRepeat="no-repeat"
					bgSize="cover"
                    color={syGrey}
				>
                    <Text mb={5} fontStyle={'bold'} fontSize={'lg'}>Shareing Text:</Text>
                    <Flex w={'100%'} direction={'column'} 
                        boxShadow={'lg'}
                        rounded={'lg'}
                        bg={'gray.800'}
                        borderColor={'gray.100'}
                        borderWidth={'1px'}
                        borderStyle={"dotted"}
                        p={2} >
                    <Text>Checkout my personalized wishlist, inspired by my favorite YouTube videos!</Text>
                    <Text>{'https://mysyval.com/promotion/wishlist2024?promoId=' +sharedUuid}</Text>
                    {base64DivImg
                    ?<Image src={base64DivImg} />:null}
                    </Flex>
                    <Button
                        mt={1}
                        colorScheme='pink' 
                        w={'50px'}
                        leftIcon={<AiOutlineShareAlt />}
                        onClick={() => {
                            webShare("Wishlist 2024", "Checkout my personalized wishlist, inspired by my favorite YouTube videos!", [base64DivImg])
                            // shareOnMobile({
                            //   text: "Checkout my personalized wishlist, inspired by my favorite YouTube videos!",
                            //   url: 'https://mysyval.com/promotion/wishlist2024?promoId=' +sharedUuid,
                            //   title: "Wishlist 2024",
                            //   images: [base64DivImg],
                            // });
                        }}
                        >
                    </Button>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default WishlistShare;
