export const userRole = [
	{
		roleName: "Admin",
		group: [100],
	},
	{
		roleName: "User",
		group: [200],
	},
];
