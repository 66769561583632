// React Chakra Imports
import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Input, Button, useToast } from "@chakra-ui/react";

// Component Imports
import { toastFunctionToaster } from "../../../../utils/toasterFunction";

import {
	syBorderGrey,
	syThemeLabelColor,
	syWhite,
} from "../../../../constants/color";
import Logout from "../../../common/Logout";
// Api Imports
import {
	getUserDetails,
	updateUserDetails,
} from "../../../../services/userServices";

function ProfileDetails({ isOpen }) {
	const toast = useToast();
	const [error, setError] = useState({});
	const [loading, isloading] = useState(false);
	const [userForm, setUserForm] = useState({
		first_name: "",
		last_name: "",
		phone_number: "",
		email: "",
		role: "",
		pet_name: "",
		photo_url: "",
	});

	useEffect(() => {
		if (isOpen) {
			fetchUserDetails();
		}
	}, [isOpen]);

	const fetchUserDetails = () => {
		getUserDetails()
			.then(res => {
				if (res?.result) {
					setUserForm(res?.data[0]);
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const validateFieldForm = () => {
		let fields = userForm;
		let errors = {};
		let formIsValid = true;

		if (!fields["first_name"]) {
			formIsValid = false;
			errors["first_name"] = "*First Name is required.";
		}
		if (!fields["last_name"]) {
			formIsValid = false;
			errors["last_name"] = "*Last Name is required.";
		}

		setError(errors);
		return formIsValid;
	};

	// Todo:Update Form On Change
	const onUpdateField = e => {
		const nextFormState = {
			...userForm,
			[e.target.name]: e.target.value,
		};
		setUserForm(nextFormState);
	};

	// Todo:Submit User Data
	const updateUserData = event => {
		event.preventDefault();
		if (validateFieldForm()) {
			isloading(true);
			updateUserDetails(userForm)
				.then(res => {
					isloading(false);
					if (res?.result) {
						toast(toastFunctionToaster(res?.message, "success"));
					} else {
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					isloading(false);
					if (err) {
						toast(toastFunctionToaster(err?.message, "success"));
					}
				});
		}
	};
	return (
		<Box>
			<Box
				borderWidth={"1px"}
				borderColor={syBorderGrey}
				borderRadius={"5px"}
				position={"relative"}
				mt="1rem"
			>
				<Text
					pl="15px"
					fontSize={"14px"}
					position={"absolute"}
					zIndex={"2"}
					top="5px"
					fontWeight={600}
					color={syThemeLabelColor}
				>
					First Name
				</Text>
				<Input
					value={userForm.first_name}
					name="first_name"
					type="text"
					height={"60px"}
					fontWeight={"bold"}
					border={"none"}
					pt="10px"
					_active={{
						boxShadow: "none",
						borderColor: syWhite,
					}}
					onChange={onUpdateField}
				></Input>
			</Box>
			{error?.first_name ? (
				<Text fontSize="sm" color={"red"} mt="1" fontWeight={"600"}>
					{error?.first_name}
				</Text>
			) : null}
			<Box
				borderWidth={"1px"}
				borderColor={syBorderGrey}
				borderRadius={"5px"}
				position={"relative"}
				mt="1rem"
			>
				<Text
					pl="15px"
					fontSize={"14px"}
					position={"absolute"}
					zIndex={"2"}
					top="5px"
					fontWeight={600}
					color={syThemeLabelColor}
				>
					Last Name
				</Text>
				<Input
					value={userForm.last_name}
					name="last_name"
					type="text"
					height={"60px"}
					fontWeight={"bold"}
					border={"none"}
					pt="10px"
					_active={{
						boxShadow: "none",
						borderColor: syWhite,
					}}
					onChange={onUpdateField}
				></Input>
			</Box>
			{error?.last_name ? (
				<Text fontSize="sm" color={"red"} mt="1" fontWeight={"600"}>
					{error?.last_name}
				</Text>
			) : null}
			<Box
				borderWidth={"1px"}
				borderColor={syBorderGrey}
				borderRadius={"5px"}
				position={"relative"}
				mt="1rem"
			>
				<Text
					pl="15px"
					fontSize={"14px"}
					position={"absolute"}
					zIndex={"2"}
					top="5px"
					fontWeight={600}
					color={syThemeLabelColor}
				>
					Email
				</Text>
				<Input
					value={userForm.email}
					name="email"
					type="text"
					height={"60px"}
					fontWeight={"bold"}
					border={"none"}
					pt="10px"
					disabled="true"
					_active={{
						boxShadow: "none",
						borderColor: syWhite,
					}}
				></Input>
			</Box>
			<Box
				borderWidth={"1px"}
				borderColor={syBorderGrey}
				borderRadius={"5px"}
				position={"relative"}
				mt="1rem"
			>
				<Text
					pl="15px"
					fontSize={"14px"}
					position={"absolute"}
					zIndex={"2"}
					top="5px"
					fontWeight={600}
					color={syThemeLabelColor}
				>
					Phone Number
				</Text>
				<Input
					value={userForm.phone_number}
					name="phone_number"
					type="text"
					height={"60px"}
					fontWeight={"bold"}
					border={"none"}
					pt="10px"
					_active={{
						boxShadow: "none",
						borderColor: syWhite,
					}}
					onChange={onUpdateField}
				></Input>
			</Box>
			<Box textAlign={"right"} mt="2">
				<Logout 
					align={'center'}
					size={"xs"}
					// ml={logoutMl ? logoutMl : "10"}
					// icon={"true"}
				>Logout </Logout>
				<Button
					size="xs"
					type="button"
					bg="black"
					color={'gray.100'}
					isDisabled={loading ? true : false}
					isLoading={loading ? true : false}
					onClick={e => {
						updateUserData(e);
					}}
					ml={5}
				>
					Update
				</Button>
			</Box>
		</Box>
	);
}

export default ProfileDetails;
