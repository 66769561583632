// Chakra Imports
import { BiDollar } from "react-icons/bi";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Select,
	Heading,
	Spinner,
	useToast,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import CommonWidget from "../../common/CommonWidget";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syGrey,
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syPurple,
} from "../../../constants/color";

// Api Imports
import {
	updateGoalPlan,
	fetchSelectedGoalPlan,
} from "../../../services/goalServices";

function EditGoal() {
	const toast = useToast();
	const navigate = useNavigate();
	const { state } = useLocation();
	const todayDate = new Date();
	const goalPlanningId = state?.goalPlanningId;
	const [goalDays, setGoalDays] = useState(0);
	const [loading, isLoading] = useState(false);
	const [date, setDate] = useState(new Date());
	const [goalAmount, setGoalAmount] = useState(2000);
	const [saveAmount, setSavedAmount] = useState(null);
	const [savedSoFarAmount, setSavedSoFarAmount] = useState(0.0);
	const [interval, setInterval] = useState("daily");
	const [numberTimes, setNumberOfTimes] = useState(null);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [selectedGoalData, setSelectedGoalData] = useState([]);

	useEffect(() => {
		fetchGoalDetails();
	}, [goalPlanningId]);

	// The fetchGoalDetails function remains the same
	const fetchGoalDetails = () => {
		setFetchLoading(true);
		let obj = {
			goal_planning_uuid: goalPlanningId,
		};
		return fetchSelectedGoalPlan(obj)
			.then(res => {
				setFetchLoading(false);
				if (res?.result) {
					setSelectedGoalData(res?.data[0]);
                    setGoalDays(res?.data[0]?.goal_plan[0]?.goal_duration_days);
                    setSavedSoFarAmount(res?.data[0]?.goal_events[0]?.saved_amount);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				setFetchLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const onChange = date => {
		setDate(date);
	};

	// Todo:Render Hashtag
	const renderHashTag = () => {
		if (selectedGoalData?.goal_plan?.[0]?.goal_tags?.length) {
			const goalHastag =
				selectedGoalData?.goal_plan?.[0]?.goal_tags?.split(",");
			return goalHastag.map((data, index) => {
				return (
					<Text
						key={data + index}
						bg={syBlack}
						mr="3"
						mb="0.5rem"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						{data}
					</Text>
				);
			});
		}
	};

	// Todo:Update Plan Details navigate("/genz/goal-overview");
	const updatePlanDetails = () => {
		isLoading(true);
		let subObj = {
			goal_planning_uuid:
				selectedGoalData?.goal_plan?.[0]?.goal_planning_uuid,
			goal_uuid: selectedGoalData?.goal_plan?.[0]?.goal_type_uuid,
			goal_name: selectedGoalData?.goal_plan?.[0]?.goal_name,
			goal_target_amount: parseInt(goalAmount),
			goal_currency: "'$",
			goal_duration_unit: interval,
			goal_duration_days: goalDays,
			goal_completion_datetime: moment(date)?.format("MM/DD/YYYY"),
			goal_event_amount: parseInt(saveAmount),
		};

		updateGoalPlan(subObj)
			.then(res => {
				isLoading(false);
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));
					navigate("/genz/goal-summary", {
						state: {
							data: subObj,
							selectedData: selectedGoalData,
							planningId: goalPlanningId,
						},
					});
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isLoading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	useEffect(() => {
		const { periods, amountPerPeriod } = calculateAmount(interval);
		setSavedAmount(amountPerPeriod.toFixed(2)); // Set the amount saved per period
		setNumberOfTimes(periods); // Set the number of times
	}, [date, interval]);

	// Todo:Interval Calculation
	const calculateAmount = interval => {
		if (date <= todayDate) {
			return { periods: 0, amountPerPeriod: 0 };
		}

		const days = calculateDays(todayDate, date);
		let periods;

		switch (interval) {
			case "daily":
				periods = days; // 1 period per day
				break;
			case "twiceAWeek":
				periods = Math.ceil(days / 3.5); // 2 periods per week (~3.5 days per period)
				break;
			case "weekly":
				periods = Math.ceil(days / 7); // 1 period per week
				break;
			case "biweekly":
				periods = Math.ceil(days / 14); // 1 period every 2 weeks
				break;
			case "monthly":
				periods = Math.ceil(days / 30); // 1 period per month (rough estimate)
				break;
			case "twiceAMonth":
				periods = Math.ceil(days / 15); // 2 periods per month (every ~15 days)
				break;
			default:
				periods = Math.ceil(days / 7); // Default to weekly
		}

		const amountPerPeriod = (goalAmount - savedSoFarAmount) / periods;
		return { periods, amountPerPeriod }; // Return both the number of periods and amount per period
	};

	const calculateDays = (start, end) => {
		const millisecondsPerDay = 1000 * 60 * 60 * 24;
		const timeDifference = end.getTime() - start.getTime();
		const totalDays = Math.ceil(timeDifference / millisecondsPerDay);
		setGoalDays(totalDays);
		return totalDays; // Get total number of days
	};

	useEffect(() => {
		if (goalDays > 0) {
			addDaysToDate();
		}
	}, [goalDays]);

	// Function to add input days to current date
	const addDaysToDate = () => {
		const currentDate = new Date();
		const newDate = new Date(currentDate.getTime()); // Clone current date to avoid modification
		newDate.setDate(newDate.getDate() + parseInt(goalDays));
		setDate(newDate);
	};

	return (
		<Box
			bgGradient={[
				'linear(to-b, #f1ffbd, #DAF66F)',
			]}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				{fetchLoading ? (
					<Flex
						alignItems="center"
						justifyContent="center"
						h="70vh"
						color={syBlack}
					>
						<Spinner size="xl" color={syBlack} />
					</Flex>
				) : (
					<>
						<Box
							bg={syBlack}
							my="1rem"
							color={'white'}
							borderRadius={"10px"}
						>
                            {/* <Text textAlign={"center"}
                            >Edit Goal Plan</Text> */}
							<Heading
								fontWeight={"900"}
								textAlign={"center"}
								py="1rem"
								fontSize={"24px"}
							>
								{selectedGoalData?.goal_plan?.[0]?.goal_name}
							</Heading>
						</Box>

						{/* <Flex my="1rem" borderRadius={"10px"} flexWrap={"wrap"}>
							{renderHashTag()}
						</Flex> */}

						<Box
							mt="1rem"
							mb="4rem"
							px="20px"
							py="30px"
							// bg={syThemeBgYellowColor}
							// borderRadius={"8px"}
						>
							<Box mb="2rem">
								<Heading
									fontSize={"15px"}
									fontWeight={"900"}
									color={syBlack}
									mb="1rem"
								>
									How much do you want to save in total?
								</Heading>
								<InputGroup
									border="none"
									bg={syWhite}
									borderRadius={"10px"}
									h="50px"
								>
									<InputLeftElement
										pointerEvents="none"
										top="5px"
									>
										<BiDollar />
									</InputLeftElement>
									<Input
										type="number"
										fontWeight={"bold"}
										color={syBlack}
										border="0"
										_hover={{ boxShadow: "none" }}
										textAlign={"center"}
										h="50px"
										value={goalAmount}
										onChange={e => {
											setGoalAmount(e.target.value);
										}}
                                        disabled
									/>
								</InputGroup>
							</Box>
                            {/* <Box mb="2rem">
								<Heading
									fontSize={"15px"}
									fontWeight={"900"}
									color={syBlack}
									mb="1rem"
								>
									Amount Saved So Far:
								</Heading>
								<InputGroup
									border="none"
									bg={syWhite}
									borderRadius={"10px"}
									h="50px"
								>
									<InputLeftElement
										pointerEvents="none"
										top="5px"
									>
										<BiDollar />
									</InputLeftElement>
									<Input
										type="number"
										fontWeight={"bold"}
										color={syBlack}
										border="0"
										_hover={{ boxShadow: "none" }}
										textAlign={"center"}
										h="50px"
										value={savedSoFarAmount}
                                        disabled
									/>
								</InputGroup>
							</Box> */}
							<Box my="2rem">
								<Heading
									fontSize={"15px"}
									fontWeight={"900"}
									color={syBlack}
									mb="1rem"
								>
									How often do you want to save?
								</Heading>
								<Select
									bg={syWhite}
									size="lg"
									_focus={{
										borderColor: syWhite,
										boxShadow: "none",
									}}
									fontWeight={"bold"}
									onChange={e => setInterval(e.target.value)}
								>
									<option value="daily">
										Daily/ Everyday
									</option>
									<option value="twiceAWeek">
										Twice a Week
									</option>
									<option value="weekly">Weekly</option>
									<option value="twiceAMonth">
										Twice a Month
									</option>
									<option value="monthly">Monthly</option>
								</Select>
							</Box>
							<Box mb="2rem">
								<Heading
									fontSize={"15px"}
									fontWeight={"900"}
									color={syBlack}
									mb="1rem"
								>
									How long do you want to do this?
								</Heading>
								<Flex justifyContent={"space-between"}>
									<Input
										value={goalDays}
										onChange={e => {
											setGoalDays(e.target.value);
										}}
										type="number"
										placeholder="Enter"
										textAlign={"center"}
										fontWeight={"bold"}
										color={syBlack}
										border="0"
										_hover={{ boxShadow: "none" }}
										h="50px"
										w="50%"
										bg={syWhite}
									/>
									<Flex
										alignItems={"center"}
										justifyContent={"center"}
										fontWeight={"bold"}
										color={syBlack}
										border="0"
										_hover={{ boxShadow: "none" }}
										bg={syWhite}
										h="50px"
										w="30%"
									>
										Days
									</Flex>
								</Flex>
							</Box>
							<Box mb="2rem">
								<Heading
									fontSize={"15px"}
									fontWeight={"900"}
									color={syBlack}
									mb="1rem"
									textAlign={"center"}
								>
									Or Save By
								</Heading>
								<Calendar
									onChange={onChange}
									value={date}
									minDate={new Date()}
								/>
							</Box>
							<Box
								textAlign={"center"}
								// borderWidth={"1px"}
								// borderColor={syThemeBgBlueColor}
								// borderRadius={"10px"}
								p="2"
								// bg={syGrey}
							>
								{/* {goalDays ? (
									<Text mb="2" fontWeight={"600"}>
										{" "}
										To Save total ${goalAmount - savedSoFarAmount} , you will
										transfer around ${saveAmount} every
										time, around {numberTimes}
										{"	 "}times in {goalDays} days
										{"	 "}
									</Text>
								) : null} */}
								<Button
									borderRadius={"50px"}
									size="lg"
									w="100%"
									bg={syPurple}
									color={syWhite}
									boxShadow={"xs"}
									onClick={() => {
										updatePlanDetails();
									}}
									isDisabled={
										date.toDateString() ===
										new Date().toDateString()
											? true
											: false
									}
									isLoading={loading ? true : false}
								>
									Update Goal
								</Button>
							</Box>
						</Box>
					</>
				)}
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default EditGoal;
