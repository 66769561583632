import { CgAsterisk } from "react-icons/cg";
import React, { useState, useEffect } from "react";
import {
	Flex,
	Input,
	Box,
	Select,
	Heading,
	Button,
	Text,
	useToast,
	Drawer,
	useDisclosure,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports

import {
	syBgGrey,
	syGrey,
	syThemeBgBlueColor,
	syWhite,
} from "../../../constants/color";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	addPromoItemApi,
	updatePromoItemApi,
} from "../../../services/goalServices";
import { MdSignalCellularNull } from "react-icons/md";


function CreatePromoItem({
	promoCategories,
	editData,
	isEditClicked,
	triggerCall,
}) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [errors, setErrors] = useState({});
	const [loading, isloading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [promoId, setPromoId] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedGender, setSelectedGender] = useState("General");
	const [promoPrice, setPromoPrice] = useState("");
	const [promoName, setPromoName] = useState("");
	const [promoSource, setPromoSource] = useState("");
	const [promoUrl, setPromoUrl] = useState(null);

	// Todo:Update Fields
	useEffect(() => {
		if (editData != null && Object?.keys(editData)?.length) {
			setPromoId(
				editData?.promo_uuid ? editData?.promo_uuid : null
			);
			setSelectedCategory(
				editData?.promo_category ? editData?.promo_category : ""
			);
			setSelectedGender(
				editData?.promo_gender ? editData?.promo_gender : ""
			);
			setPromoPrice(
				editData?.promo_price ? editData?.promo_price : ""
			);
			setPromoName(
				editData?.promo_name ? editData?.promo_name : ""
			);
			setPromoSource(
				editData?.promo_source ? editData?.promo_source : ""
			);
			setPromoUrl(
				editData?.promo_url ? editData?.promo_url : ""
			);
			onOpen();
		}
	}, [editData, isEditClicked]);

	// Validation Function
	const validateForm = () => {
		let formErrors = {};
		if (!selectedCategory) formErrors.selectedCategory = "Category is required";
		// if (!selectedGender) formErrors.selectedGender = "Gender is required";
		if (!promoName) formErrors.promoName = "Name is required";
		if (!promoPrice) formErrors.promoPrice = "Price is required";
		// if (!promoSource) formErrors.promoSource = "Source is required";
		// if (!promoUrl) formErrors.promoUrl = "URL is required";

		setErrors(formErrors);
		return Object.keys(formErrors).length === 0;
	};

	// Handle form submission
	const handleSubmit = () => {
		if (validateForm()) {
			isloading(true);
			let obj = {
				promo_category: selectedCategory,
				promo_gender: selectedGender ? selectedGender : 'general',
				promo_name: promoName,
				promo_price: promoPrice,
				promo_source: promoSource,
				promo_url: promoUrl
			};
			if (promoId != null) {
				obj["promo_uuid"] = promoId;
				updatePromoItemApi(obj)
					.then(res => {
						isloading(false);
						if (res?.result) {
							triggerCall();
							toast(
								toastFunctionToaster(res?.message, "success")
							);
							onClose();
						} else {
							toast(toastFunctionToaster(res?.message, "error"));
						}
					})
					.catch(err => {
						isloading(false);
						if (err) {
							toast(toastFunctionToaster(err?.message, "error"));
						}
					});
			} else {
				addPromoItemApi(obj)
					.then(res => {
						isloading(false);
						if (res?.result) {
							triggerCall();
							toast(
								toastFunctionToaster(res?.message, "success")
							);
							onClose();
						} else {
							toast(toastFunctionToaster(res?.message, "error"));
						}
					})
					.catch(err => {
						isloading(false);
						if (err) {
							toast(toastFunctionToaster(err?.message, "error"));
						}
					});
			}
		}
	};

	// Todo:Reset Fiels
	const handleClose = () => {
		onClose();
		setErrors({});
		setPromoId(null);
		setSelectedCategory(null);
		setSelectedGender('general');
		setPromoPrice(null);
		setPromoName(null);
		setPromoSource(null);
		setPromoUrl(null);
	};

	const resetAll = () => {
		setErrors({});
		setPromoId(null);
		setSelectedCategory(null);
		setSelectedGender('general');
		setPromoPrice(null);
		setPromoName(null);
		setPromoSource(null);
		setPromoUrl(null);
	};

	return (
		<Box>
			<Button colorScheme="green" size="xs" 
				onClick={ () => {
					setPromoId(null);
					resetAll();
					onOpen();
				}} 
				ref={btnRef}>
				Add New Item
			</Button>
			<Drawer
				isOpen={isOpen}
				onClose={() => {
					handleClose();
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={syThemeBgBlueColor}>
					<DrawerHeader color={syWhite} borderBottomWidth={"0.5px"}>
						{promoId ? "Update" : "Add"} Promo Item
					</DrawerHeader>
					<DrawerCloseButton color="#d7d7d7" />
					<DrawerBody>
						{/* Class Selection */}
						<Box my="1rem">
							<Flex>
								<Heading
									fontSize={"14px"}
									fontWeight={"600"}
									color={syWhite}
									mb="1rem"
								>
									Select Category
								</Heading>
								<CgAsterisk color={"red"} />
							</Flex>
							<Select
								bg="#1c181e"
								color={syWhite}
								border="0px"
								size="md"
								value={selectedCategory}
								onChange={e => setSelectedCategory(e.target.value)}
								_focus={{
									borderColor: syWhite,
									boxShadow: "none",
								}}
								sx={{
									option: {
										color: syWhite,
										backgroundColor: syBgGrey,
									},
								}}
							>
								<option value="">Select Category</option>
								{promoCategories.map(promCat => (
								<option key={promCat.id} value={promCat.id}>
									{promCat.label}
								</option>
							))}

							</Select>

							{errors.selectedClass && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.selectedClass}
								</Text>
							)}
						</Box>

						{/* Group Selection */}
						<Box my="1rem">
							<Flex>
								<Heading
									fontSize={"14px"}
									fontWeight={"600"}
									color={syWhite}
									mb="1rem"
								>
									Select Gender
								</Heading>
								<CgAsterisk color={"green"} />
							</Flex>
							<Select
								bg="#1c181e"
								color={syWhite}
								border="0px"
								size="md"
								value={selectedGender}
								onChange={e => setSelectedGender(e.target.value)}
								_focus={{
									borderColor: syWhite,
									boxShadow: "none",
								}}
								sx={{
									option: {
										color: syWhite,
										backgroundColor: syBgGrey,
									},
								}}
							>
								<option value="">Select Gender</option>
								<option value="general">general </option>
								<option value="male">male </option>
								<option value="female">female </option>
							</Select>
							{errors.selectedGender && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.selectedGender}
								</Text>
							)}
						</Box>

						{/* Promo Item Name */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Promo Item Name:
								</Text>
								<CgAsterisk color={"red"} />
							</Flex>
							<Input
								placeholder="Enter Promo Item Name"
								size="sm"
								value={promoName}
								onChange={e => setPromoName(e.target.value)}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{errors.promoName && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.promoName}
								</Text>
							)}
						</Box>

						{/* Promo Item Price */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Promo Item Price:
								</Text>
								<CgAsterisk color={"red"} />
							</Flex>
							<Input
								type="number"
								placeholder="Enter Promo Item Price"
								size="sm"
								value={promoPrice}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								onChange={e => setPromoPrice(e.target.value)}
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{errors.promoPrice && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.promoPrice}
								</Text>
							)}
						</Box>

						{/* Source */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Promo Item Source:
								</Text>
								<CgAsterisk color={"green"} />
							</Flex>
							<Input
								placeholder="Enter Promo Source"
								size="sm"
								value={promoSource}
								onChange={e =>
									setPromoSource(e.target.value)
								}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{/* {errors.promoSource && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.promoSource}
								</Text>
							)} */}
						</Box>
						{/* Url */}
						<Box mb="10px">
							<Flex>
								<Text mb="8px" color="#fff">
									Promo Item Url:
								</Text>
								<CgAsterisk color={"green"} />
							</Flex>
							<Input
								placeholder="Enter Promo URL"
								size="sm"
								value={promoUrl}
								onChange={e =>
									setPromoUrl(e.target.value)
								}
								borderWidth={"0px"}
								borderRadius={"5px"}
								h="45px"
								bg="#1c181e"
								color="#fff"
								_placeholder={{ color: "white" }}
							/>
							{/* {errors.promoUrl && (
								<Text
									color="red.400"
									fontSize={"14px"}
									fontWeight={"600"}
								>
									{errors.promoUrl}
								</Text>
							)} */}
						</Box>
					</DrawerBody>

					<DrawerFooter borderTopWidth={"1px"}>
						<Button
							colorScheme="red"
							size="sm"
							mr={3}
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							colorScheme="green"
							size="sm"
							onClick={handleSubmit}
							isDisabled={loading ? true : false}
							isLoading={loading ? true : false}
						>
							{promoId ? "Update" : "Create"}
						</Button>
					</DrawerFooter>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}

export default CreatePromoItem;
