import React, { useEffect, useState } from "react";
import {
	Img,
	Box,
	Flex,
	Text,
	Input,
	Switch,
	Spinner,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import dummyImage from "../../../images/common/dummy_profile.png";
import { getUserName } from "../../../utils/localStorageIndex";
import ResponsivePagination from "react-responsive-pagination";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syGrey,
	syWhite,
	syThemeBgYellowColor,
} from "../../../constants/color";
import moment from "moment";

// Api Imports
import { fetchBetaUsers } from "../../../services/sharedServices";

function BetaUsers() {
	const toast = useToast();
	const [loading, isloading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [userEnable, setUserEnable] = useState(true);

	useEffect(() => {
		fetchBetaUserDetails();
	}, [userEnable, currentPage]);

	const fetchBetaUserDetails = () => {
		isloading(true);
		let objBody = {
			enabled: userEnable,
			filter_key: getUserName(),
			filter_value: "string",
			records_per_page: 10,
			page_id: currentPage,
		};
		fetchBetaUsers(objBody)
			.then(res => {
				isloading(false);
				if (res.data.length > 0) {
					setTotalPages(res.data[0].total_pages);
					setTableData(res.data[0].records);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setTotalPages(0);
					setTableData([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				isloading(false);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const handleSearch = e => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);
	};

	const filteredData = tableData.filter(data => {
		return (
			data?.first_name?.toLowerCase().includes(searchTerm) ||
			data?.last_name?.toLowerCase().includes(searchTerm) ||
			data?.user_email?.toLowerCase().includes(searchTerm)
		);
	});

	function renderUserDetails() {
		if (filteredData && filteredData.length > 0) {
			var betaUserResults = filteredData.map((data, index) => (
				<Flex
					p="15px"
					pl="0px"
					key={data?.uuid}
					color={syWhite}
					borderTopWidth={index === 0 ? "0.5px" : "0pc"}
					borderTopColor={syGrey}
					borderBottomWidth={"0.5px"}
					borderBottomColor={syGrey}
					cursor={"pointer"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Flex>
						<Flex
							alignItems={"center"}
							flexWrap={"wrap"}
							borderLeftWidth={"20px"}
							pl={5}
							borderLeftColor={
								data?.verification_status
									? "green.200"
									: "red.300"
							}
						>
							<Img
								h="60px"
								w="60px"
								mx="auto"
								objectFit={"cover"}
								bg="#fff"
								src={
									data?.photo_url
										? data?.photo_url
										: dummyImage
								}
								borderRadius={"50px"}
							/>
							<Box ml="6">
								<Flex w={'100%'}>
									<Text mb="5px" fontSize={"14px"} w="50%">
										First Name :{" "}
										<Text as="span" ml="10px">
											{data?.first_name ? data?.first_name : "N/A"}
										</Text>
									</Text>
									<Text mb="5px" fontSize={"14px"} w="50%">
										Last Name :{" "}
										<Text as="span" ml="50px">
											{data?.last_name ? data?.last_name : "N/A"}
										</Text>
									</Text>
								</Flex>
								<Text mb="5px" fontSize={"14px"} w="100%">
									Email Address :{" "}
									<Text as="span" ml="28px">
										{data?.user_email
											? data?.user_email
											: "N/A"}
									</Text>
								</Text>
								<Text mb="5px" fontSize={"14px"} w="100%">
									Status :{" "}
									<Text
										as="span"
										ml="85px"
										color={
											data?.verification_status
												? "green.200"
												: "red.300"
										}
									>
										{data?.verification_status
											? "Verified"
											: "Not Verified"}
									</Text>
								</Text>
								<Flex w={'100%'}>
									<Text>Created </Text>
									<Text ml={1} color={'blue.300'}>
									{data?.datetime_created
												? moment(data?.datetime_created)?.format("DD/MM/YYYY HH:MM")
												: "-"}
									</Text>
									<Text ml={5}>Updated </Text>
									<Text ml={1} color={'blue.300'}>
									{data?.datetime_updated
												? moment(data?.datetime_updated)?.format("DD/MM/YYYY HH:MM")
												: "-"}
									</Text>
								</Flex>
							</Box>
						</Flex>
					</Flex>
				</Flex>
			));
			return betaUserResults;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Users Found!!"}
					</Text>
				</Box>
			);
		}
	}

	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" color={syWhite} />
				</Flex>
			) : (
				<Box>
					<Box p="15px">
						<Flex alignItems={"center"}>
							<Box
								as="span"
								flex="1"
								textAlign="left"
								color={syWhite}
								fontWeight={"600"}
								fontSize="20px"
							>
								Beta Users List
							</Box>
							<Flex alignItems={"center"}>
								<Input
									type="search"
									placeholder="Search Beta User"
									value={searchTerm}
									onChange={handleSearch} // Handle input change
									color={syWhite}
								/>
								<Switch
									colorScheme="green"
									ml="2"
									isChecked={userEnable}
									onChange={() => {
										setUserEnable(!userEnable);
									}}
								/>
							</Flex>
						</Flex>

						<Box
							borderRadius={"20px"}
							mt="3"
							p="15px"
							h="calc(100vh - 200px)"
							overflow="auto"
							css={{
								"&::-webkit-scrollbar": {
									width: "4px",
								},
								"&::-webkit-scrollbar-track": {
									width: "6px",
								},
								"&::-webkit-scrollbar-thumb": {
									background: { syGrey },
									borderRadius: "24px",
								},
							}}
						>
							{renderUserDetails()}
						</Box>
						{totalPages > 1 && (
							<Box>
								<ResponsivePagination
									current={currentPage}
									total={totalPages}
									onPageChange={pageNumber => {
										setCurrentPage(pageNumber);
									}}
								/>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</>
	);
}

export default BetaUsers;
