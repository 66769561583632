import React, { useState } from "react";
import { Flex, Box, Text, Grid, GridItem } from "@chakra-ui/react";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import {
	syGrey,
	syBgGrey,
	syWhite,
	syBorderGrey,
} from "../../../constants/color";
import UsersList from "../users/UsersList";
import UsersGoalList from "./UsersGoalList";
import GoalBasedUsers from "./GoalBasedUsers";

function GoalPlans() {
	const [configurationCollapseFlag, setConfiguationCollapseFlag] =
		useState(true);
	const [userId, setUserId] = useState("");

	return (
		<Flex h="90vh">
			<Box
				w={!configurationCollapseFlag ? "95%" : "70%"}
				bg={syBgGrey}
				p="3"
				borderRadius={"10px"}
				overflow="hidden"
			>
				<GoalBasedUsers userId={userId} />
			</Box>
			{configurationCollapseFlag ? (
				<Box
					w="30%"
					position="relative"
					p="3"
					borderRadius="15px"
					bg={syBgGrey}
					ml="2"
				>
					<Text color={syWhite} pb="15px" fontWeight={"600"}>
						Goal Plans
					</Text>
					<Box
						position="absolute"
						top="0"
						right="0"
						mr="2"
						mt="3"
						zIndex="999"
					>
						<AiFillRightCircle
							color="#fff"
							cursor="pointer"
							size="20px"
							bg={syGrey}
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
						/>
					</Box>
					<Box
						w="100%"
						h="90vh"
						overflow={"hidden"}
						overflowY="auto"
						borderTopWidth={"1px"}
						borderTopColor={syBorderGrey}
						css={{
							"&::-webkit-scrollbar": {
								width: "4px",
							},
							"&::-webkit-scrollbar-track": {
								width: "6px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: { syBgGrey },
								borderRadius: "24px",
							},
						}}
					>
						<UsersGoalList userId={setUserId} />
					</Box>
				</Box>
			) : (
				<Box
					w="5%"
					p="3"
					borderRadius="10px"
					bg={syBgGrey}
					h="100%"
					ml="2"
					pl="3"
				>
					<Box w={"100%"} h="100%">
						<AiFillLeftCircle
							cursor="pointer"
							color="#fff"
							onClick={() => {
								setConfiguationCollapseFlag(
									!configurationCollapseFlag
								);
							}}
							size="20px"
						/>
						<Text
							fontSize="16px"
							color="#fff"
							mt="2"
							css={{
								writingMode: "vertical-lr",
								textOrientation: "mixed",
							}}
						>
							Expand Goal Plans
						</Text>
					</Box>
				</Box>
			)}
		</Flex>
	);
}

export default GoalPlans;
