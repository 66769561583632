import axios from "./axios";
import { serviceAPIs } from "./serviceApis";

export const fetchSystemToken = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().tokenApi, data, config);
};

export const systemLogin = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().login, data, config);
};

export const registerSystem = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().registerUser, data, config);
};

export const verifyUserByCode = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().verifyUser, data, config);
};

export const regenerateEmailCode = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().regenerateCode, data, config);
};

export const forgotSystemPassword = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().forgotPassword, data, config);
};

export const changeDefaultPassword = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(
		serviceAPIs().changeDefaultPassword,
		data,
		config
	);
};

export const applicationLogout = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().logout, data, config);
};

// User Services
export const fetchUserList = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().userList, data, config);
};

export const handleUserStatus = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().enableDisableUser, data, config);
};

export const addBetaRequest = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().addBetaEmail, data, config);
};

export const verifyBetaMailId = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().verifyBetaEmail, data, config);
};

export const fetchBetaUsers = (data, config = null, axiosInstance = axios) => {
	return axiosInstance.post(serviceAPIs().getBetaUsers, data, config);
};

export const fetchUsersWithPlan = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().usersWithPlan, data, config);
};

export const fetchUserGoalPlanList = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().userGoalPlanList, data, config);
};

export const fetchGoalPlanEvent = (
	data,
	config = null,
	axiosInstance = axios
) => {
	return axiosInstance.post(serviceAPIs().goalPlanEvent, data, config);
};
