// Chakra Imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, 
    Img, 
    Flex,
    Heading,
    InputGroup,
    Icon,
    Input,
    Spinner,
    InputLeftElement,
    useToast,
    Text, Button } from "@chakra-ui/react";
import axios from "../../services/axios"
import { AiOutline, AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";
import { MdOutlineAlternateEmail } from "react-icons/md";

// Component Imports
import syRocket from "../../images/boarding/rocket.png";
import syLogo from "../../images/boarding/syval_logo.png";
import promoback01 from '../../../src/images/promo/promoback01.jpg';
import promoback02 from '../../../src/images/promo/promoback02.jpg';
import snowglobe01 from '../../../src/images/promo/sg01.png';
import snowglobe02 from '../../../src/images/promo/sg02.png';
import snowglobe03 from '../../../src/images/promo/sg03.png';
import ytlogo from '../../../src/images/promo/ytlogo.png';

import {
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
    syGrey,
    syBorderGrey
} from "../../constants/color.js";

import useResponsiveHeight from "../../customHook/ResponsiveHeight.js";
import { toastFunctionToaster } from "../../utils/toasterFunction";

// Api Services
import { getYouTubePreferences } from "../../services/userServices";

function YTConnection() {
    const toast = useToast();
	const navigate = useNavigate();
    const randNum = Math.floor(Math.random() * 10) + 1;
    // const globeId = (Math.floor(Math.random() * 10) + 1) % 3;
    const globeId = getRandomInt(1, 3);
	const height = useResponsiveHeight();

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    const YOUTUBE_CHANNEL_INFO_API = "https://www.googleapis.com/youtube/v3/channels";
    const apiKey = process.env.REACT_APP_YOUTUBE_KEY;
    const apiUrl = 'https://www.googleapis.com/youtube/v3/search';

    const [videos, setVideos] = useState([]);
    const [youTubeChannelName, setYouTubeChannelName] = useState(null);
    const [preferenceId, setPreferenceId] = useState(null);
    const [preferenceList, setPreferenceList] = useState([]);
    const [loading, isloading] = useState(false);
    const [connSuccess, isConnSuccess] = useState(false);
    const [connProgress, isConnProgress] = useState(false);


    // useEffect(() => {
	// 	fetchYouTubePrefDetails();
	// }, []);

	const fetchYouTubePrefDetails = () => {
        if (youTubeChannelName != null){
            isloading(true);
            let obj = {
                channelName: youTubeChannelName,
                channelType: 'channel', // username
                prefData: ''
            };
            getYouTubePreferences(obj)
                .then(res => {
                    isloading(false);
                    if (res?.result) {
                        if (res?.data[0]?.status){
                            if (res?.data[0]?.uuid != null){
                                isConnSuccess(true);
                                setPreferenceList(res?.data[0]?.promo_categories);
                                setPreferenceId(res?.data[0]?.uuid);
                                toast(toastFunctionToaster(res?.message, "success"));
                            }
                        } else {
                            toast(toastFunctionToaster(res?.message, "error"));
                        }
                    } else {
                        toast(toastFunctionToaster(res?.message, "error"));
                    }
                })
                .catch(err => {
                    isloading(false);
                    if (err) {
                        toast(toastFunctionToaster(err?.message, "error"));
                    }
                });
        }
	};

    // useEffect(() => {
    //     const fetchData = async () => {
    //     try {
    //         const response = await axios.get(apiUrl, {
    //         params: {
    //             part: 'snippet',
    //             type: 'channel',
    //             q: '650ailab',
    //             key: apiKey,
    //         },
    //         });
    //         setVideos(response.data.items);
    //     } catch (error) {
    //         console.error('Error fetching videos:', error);
    //     }
    //     };

    //     fetchData();
    // }, []);

    // async function getChannelInfoX(){
    //     debugger;
    //     // fetch(`${YOUTUBE_CHANNEL_INFO_API}?channelId=650ailab&part=snippet,contentDetails,statistics,contentOwnerDetails,id,topicDetails,status,auditDetails,brandingSettings&key=${apikey}`)
    //     fetch(`${YOUTUBE_CHANNEL_INFO_API}?forUsername=650ailab&part=snippet&key=${apiKey}`)
    //         .then(results => {
    //             const videosObj = results.json();
    //             console.log(JSON.stringify(videosObj));
    //             // this.setState({
    //             //     videos: videosObj,
    //             //     playingVideoId: videosObj[this.index]
    //             // }, (updatedState) => {
    //             //     console.log("videos", updatedState.videos);
    //             //     console.log("videos", updatedState.playingVideoId);
    //             // });
    //         })
    // }

    // function getChannelInfo() {
    //     // dispatch(setAccountUploadFlag(false));
    //     // const { url, fields } = `${YOUTUBE_CHANNEL_INFO_API}?forUsername=650ailab&part=snippet&key=${apikey}`;
    //     // const payload = new FormData();

    //     // add all the other fields
    //     // Object.entries(fields).forEach(([key, val]) => {
    //     //     payload.append(key, val);
    //     // });
    //     // payload.append("file", selectedFile);
    //     var ax_instance = axios.create();
    //     delete ax_instance.defaults.headers.common["Authorization"];
    //     const { data: result } = ax_instance
    //         .get(`${YOUTUBE_CHANNEL_INFO_API}?forUsername=650ailab&part=snippet&key=${apiKey}`)
    //         .then((res) => {
    //             if (res?.data?.length > 0) {
    //                 console.log(res?.data);
    //                 // setUploadStatus("File is uploaded successfully");
    //                 // dispatch(setAccountUploadFlag(true));
    //             } else {
    //                 // setUploadStatus("File is uploaded successfully");
    //                 // dispatch(setAccountUploadFlag(true));
    //             }
    //         })
    //         .catch((err) => {
    //             // setUploadStatus("Upload failed with Error :" + err);
    //         });
    // }

	return (
		<Box
            backgroundImage={randNum % 2 == 0 ? promoback01 : promoback01}
			// bg={syThemeBgBlueColor}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
		>
			<Box textAlign={"center"}>
				<Heading
                    mt={20}
                    w={'100%'}
                    rounded={'lg'}
                    // bg={'gray.600'}
                    fontSize={'2xl'}
					textAlign={"center"}
					fontWeight="500"
					color={syWhite}
				>
					Please provide your YouTube channel name to connect and get preferences
				</Heading>
                {!connProgress
                ?<Flex w={'100%'} direction={'column'} h={'calc(100vh - 500px)'}>
                    <Box w={'100%'}  mt="1rem">
                        <InputGroup                    
                                border="none"
                                bg={syWhite}
                                w={'100%'}
                                borderRadius={'lg'}
                                borderTopLeftRadius={"10px"}
                                borderTopRightRadius={"10px"}
                            >
                                <InputLeftElement 
                                    borderRightWidth={'1px'}
                                    pointerEvents="none" top="5px">
                                    <Text mr={1}>@</Text>
                                </InputLeftElement>
                                <Input
                                w={'100%'}
                                    borderWidth="1px"
                                    boxShadow="lg"
                                    borderColor={syBorderGrey}
                                    type="text"
                                    placeholder=" Your YouTube channel name"
                                    _hover={{ boxShadow: "none" }}
                                    h="50px"
                                    value={youTubeChannelName}
                                    onChange={e => {
                                        setYouTubeChannelName(e.target.value);
                                    }}
                                />
                            </InputGroup>
                    </Box>
                    <Box w={'100%'} mt="1rem" align={'center'}>
                        {youTubeChannelName != null && youTubeChannelName?.length > 5
                        ?<Button mt="1rem"
                            w={'80%'}
                            rounded={'lg'}
                            align={'center'}
                            justify={'center'}
                            bg={syWhite}
                            onClick={() => {
                                isConnProgress(true);
                                fetchYouTubePrefDetails();
                            }}
                            color={syThemeBgBlueColor}
                            _hover={{ bg: syThemeBgYellowColor, cursor:'pointer' }}
                            textAlign={"center"}>
                                <Text mr={1}>Connect</Text>
                                <Img src={ytlogo} h={'40px'} />
                                <Text ml={1}>YouTube</Text>
                        </Button>
                        :<Button mt="1rem"
                            rounded={'lg'}
                            w={'80%'}
                            align={'center'}
                            justify={'center'}
                            bg={syGrey}
                            // color={syThemeBgBlueColor}
                            _hover={{ bg: syThemeBgYellowColor, cursor:'pointer' }}
                            color={'red.600'}
                            textAlign={"center"}>
                                <Text mr={1}>Enter</Text>
                                <Img src={ytlogo} h={'40px'} />
                                <Text ml={1}>YouTube channel name</Text>
                        </Button>}
                    </Box>
                </Flex>
                :<Box w={'100%'} h={'calc(100vh - 500px)'} mt="1rem" textAlign={"center"}>
                    <Flex w={'100%'} 
                        pb={10} pl={2}
                        bg={'blue.100'} rounded={'lg'}
                        direction={'column'} mt={'1px'} mx={'20px'}>
                        <Flex w={'100%'} mt={2}>
                            <Text fontWeight={600} fontSize={'lg'}>Connecting YouTube Account: {youTubeChannelName}</Text>
                        </Flex>
                        <Flex w={'100%'} mt={5}>
                            {connProgress && loading
                            ?<Spinner size="md" color={syWhite} mr={1} />
                            :(
                                connSuccess
                                ?<Icon as={AiFillCheckCircle} color={'green.400'} h={6} w={6} mr={1} />
                                :<Icon as={AiFillExclamationCircle} color={'red.400'} h={6} w={6} mr={1} />
                            )}
                            <Text>Analyzing your YouTube Account..</Text>
                        </Flex>
                        <Flex w={'100%'} mt={'10'}>
                            {connProgress && loading
                            ?<Spinner size="md" color={syWhite} mr={1} />
                            :(
                                connSuccess
                                ?<Icon as={AiFillCheckCircle} color={'green.400'} h={6} w={6} mr={1} />
                                :<Icon as={AiFillExclamationCircle} color={'red.400'} h={6} w={6} mr={1} />
                            )}
                            <Text>Designing your wishlist..</Text>
                        </Flex>
                        <Flex w={'100%'} mt={'10'}>
                            {connProgress && loading
                            ?<Spinner size="md" color={syWhite} mr={1} />
                            :(
                                connSuccess
                                ?<Icon as={AiFillCheckCircle} color={'green.400'} h={6} w={6} mr={1} />
                                :<Icon as={AiFillExclamationCircle} color={'red.400'} h={6} w={6} mr={1} />
                            )}
                            <Text>Loading your wishes..</Text>
                        </Flex>
                        <Flex w={'100%'} mt={'10'} justify={'center'}>
                            {connSuccess && preferenceId != null
                            ?<Button
                                rounded={'lg'}
                                w={'80%'}
                                align={'center'}
                                justify={'center'}
                                bg={syGrey}
                                onClick={() => {
                                    navigate("/promotion/wishlist", {
                                        state: { 
                                            prefId: preferenceId
                                         },
                                    });
                                }}
                                >
                                Proceed with Preferences
                            </Button>
                            :<Button
                                rounded={'lg'}
                                w={'80%'}
                                align={'center'}
                                justify={'center'}
                                bg={syGrey}
                                onClick={() => {
                                    isConnProgress(false);
                                    setYouTubeChannelName(null);
                                }}
                                >
                                Retry
                            </Button>}
                        </Flex>
                    </Flex>
                </Box>}
                <Box mt="1rem" textAlign={"center"}>
					<Text
						mt="1rem"
						textAlign={"center"}
						color={syWhite}
					>
						Powered By Syval with ❤️
					</Text>
				</Box>
			</Box>
		</Box>
	);
}

export default YTConnection;
