import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom';
// React Chakra Imports
import {
	Text,
	Box,
	Flex,
	Heading,
	Tabs,

	Tab,
	TabList,
	TabPanel,
	TabPanels,
} from "@chakra-ui/react";

// Component Imports
import Profile from "./profile/Profile";
import DreamLife from "./dreamLife/DreamLife";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import CommonWidget from "../../common/CommonWidget";
import {
	syThemeBgBlueColor,
	syThemeBgGreenColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../../constants/color";

function DreamLifeMain() {
	const navigate = useNavigate();
	const location = useLocation();

	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState('profile');
	const [tabIndex, setTabIndex] = useState(0);
	// Get a specific query parameter
	const page = searchParams.get('page'); 
	// const [tabName, setTabName] = useState(searchParams.get('page'));
	// const [tabIndex, setTabIndex] = useState(page === 'profile'  ? 1 : 0 );
	
	// debugger;
	// setTabName(page === 'profile'  ? "profile" :  "dream");

	useEffect(() => {
		const tabFromQuery = searchParams.get('page');
		if (tabFromQuery) {
			setActiveTab(tabFromQuery);
			setTabIndex(tabFromQuery === 'profile' ? 1 : 0)
		}
	}, [searchParams]);

	const handleTabChange = (newTab) => {
		setActiveTab(newTab);
		setTabIndex(newTab)
	};

	// useEffect(() => {
	// 	//setTabName(page === 'profile'  ? "profile" :  "dreamlife")
	// 	//debugger;
	// 	setTabName(searchParams.get('page'))
	// 	setTabIndex(searchParams.get('page') === 'profile'  ? 1 : 0 )
	// 	console.log(tabName + " - " + tabIndex);
	// }, [page]);

	return (
		<Box
			// bg={activeTab === "dreamlife" ? syThemeBgGreenColor : syThemeBgBlueColor}
			bgGradient={[
				'linear(to-b, #a4a9f4, #33376d)',
			]}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
			minH="940px"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box px="10px" py="30px">
					<Flex
						alignContent={"center"}
						justifyContent={"center"}
						flexWrap={"wrap"}
						textAlign={"center"}
						mt="1rem"
						mb={3}
					>
						<Heading
							fontSize={"22px"}
							fontWeight={"600"}
							color={syWhite}
						>
							My Dream Life
						</Heading>
					</Flex>
					<Flex w={'100%'} align={'center'}>
						{/* <Text						
							textAlign={"center"}
							fontWeight={"bold"}
							fontSize={"18px"}
							pl="0"
							w="100%"
							onClick={() => {
								handleTabChange('dreamlife');
								setActiveTab("dreamlife");
								setTabIndex(0);
							}}
							color={
								activeTab === "dreamlife"
									? syThemeBgBlueColor
									: syWhite
							}
							_focus={{ boxShadow: "none" }}
						>
							My Dream Life
						</Text> */}
					</Flex>
					<DreamLife tabValue={activeTab} />
					{/* <Tabs index={tabIndex} onChange={handleTabChange}>
						<TabList>
							<Tab
								key={0}
								id={'dreamlife'}
								name={'dreamlife'}
								textAlign={"center"}
								fontWeight={"bold"}
								fontSize={"18px"}
								pl="0"
								w="50%"
								onClick={() => {
									handleTabChange('dreamlife');
									setActiveTab("dreamlife");
									setTabIndex(0);
								}}
								color={
									activeTab === "dreamlife"
										? syThemeBgBlueColor
										: syWhite
								}
								_focus={{ boxShadow: "none" }}
							>
								My Dream Life
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel px="0" key={0}>
								<DreamLife tabValue={activeTab} />
							</TabPanel>
						</TabPanels>
					</Tabs> */}
				</Box>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
				userRoute="/genz/user-profile"
			/>
		</Box>
	);
}

export default DreamLifeMain;
