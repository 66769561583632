// React Chakra Imports
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Box, Img, Icon } from "@chakra-ui/react";

// Component Imports
import { syWhite } from "../../constants/color";
// import addImage from "../../images/genz/common-footer/plus.png";
// import profileImg from "../../images/genz/common-footer/profile.png";
// import ratingImg from "../../images/genz/common-footer/rating.png";
// import calenderImg from "../../images/genz/common-footer/calendar.png";
import helpRobot from "../../images/genz/common-footer/help-robot.png";

import { AiOutlineHome, AiFillHome, AiOutlineStar, AiFillStar, AiOutlinePlusCircle, AiFillPlusCircle, AiOutlineUser } from "react-icons/ai";

import { FaUser, FaRegUser } from "react-icons/fa";

function getCurrentPage() {
	const current_url = window.location.href;
	if (current_url.includes("/dashboard")) {
		return 1;
	} else if (current_url.includes("/dreamlife")) {
		return 2;
	} else if (current_url.includes("/create-goal")) {
		return 3;
	} else if (current_url.includes("/user-profile")) {
		return 4;
	}
	return 0;
}

function FooterProfile({ position, top, left, right, bottom, borderRadius, userRoute }) {
	const current_url = window.location.href;

	const navigate = useNavigate();

	const [showCount, setShowCount] = useState(0);

	return (
		<Flex position={position} bg={"#fff"} bottom={bottom} w="100%" justifyContent={"space-between"} py="25px" px="60px" borderTopLeftRadius={"30px"} borderTopRightRadius={"30px"} zIndex={2000}>
			<Box
				h="30px"
				onClick={() => {
					navigate("/genz/dashboard");
				}}
				cursor={"pointer"}
				position={"relative"}
			>
				{/* <Img h="100%" src={calenderImg} /> */}
				<Icon as={getCurrentPage() === 1 ? AiFillHome : AiOutlineHome} color={getCurrentPage() === 1 ? "#5F67EC" : null} h={8} w={8} />
				{showCount > 0 ? (
					<Box top="-10px" right="-10px" h="25px" w="25px" textAlign="center" position="absolute" bg="red.500" p="1px" color={syWhite} borderRadius="50px">
						1
					</Box>
				) : null}
			</Box>
			<Box
				h="30px"
				cursor={"pointer"}
				position={"relative"}
				onClick={() => {
					// navigate("/genz/playground");
					navigate("/genz/dreamlife");
				}}
			>
				{/* <Img h="100%" src={ratingImg} /> */}
				<Icon as={getCurrentPage() === 2 ? AiFillStar : AiOutlineStar} color={getCurrentPage() === 2 ? "#5F67EC" : null} h={8} w={8} />
				{showCount > 0 ? (
					<Box top="-10px" right="-10px" h="25px" w="25px" textAlign="center" position="absolute" bg="red.500" p="1px" color={syWhite} borderRadius="50px">
						1
					</Box>
				) : null}
			</Box>
			<Box
				h="30px"
				cursor={"pointer"}
				onClick={() => {
					navigate("/genz/create-goal");
				}}
			>
				<Icon as={getCurrentPage() === 3 ? AiFillPlusCircle : AiOutlinePlusCircle} color={getCurrentPage() === 3 ? "#5F67EC" : null} h={8} w={8} />
				{/* <Img h="100%" src={addImage} /> */}
			</Box>
			<Box
				h="30px"
				cursor={"pointer"}
				position={"relative"}
				onClick={() => {
					navigate("/genz/user-profile");
				}}
			>
				{/* <Img h="100%" src={profileImg} /> */}
				<Icon as={getCurrentPage() === 4 ? FaUser : FaRegUser} color={getCurrentPage() === 4 ? "#5F67EC" : null} h={7} w={7} />
				{showCount > 0 ? (
					<Box top="-10px" right="-10px" h="25px" w="25px" textAlign="center" position="absolute" bg="red.500" p="1px" color={syWhite} borderRadius="50px">
						1
					</Box>
				) : null}
			</Box>
			{/* Avkash Disabled the Help Robot */}
			{/* <Box
				h="50px"
				w="50px"
				bottom="70px"
				right="25px"
				position={"absolute"}
				onClick={() => {
					navigate("/genz/help");
				}}
			>
				<Img src={helpRobot} />
			</Box> */}
		</Flex>
	);
}

export default FooterProfile;
