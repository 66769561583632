// React - Chakra Imports
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

// Component Imports
// import { getGroupId } from "../utils/localStorageIndex";
import { userRole } from "../constants/constant";
import Unauthorized from "../components/common/Unauthorized";

export default function UserProtectedLayout() {
	// const group_id = getGroupId();
	const group_id = 200;
	const location = useLocation();
	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		userRole.forEach(subData => {
			if (subData.roleName === "User") {
				if (subData.group.includes(group_id)) {
					setIsAuthenticated(true);
				}
			}
		});
	}, [location, group_id]);

	return <>{isAuthenticated ? <Outlet /> : <Unauthorized />}</>;
}
