// Chakra Imports
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import {
	Box,
	Flex,
	Text,
	Input,
	Button,
	Heading,
	Grid,
	SimpleGrid,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Card,
	Link,
	Icon,
	Image,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import bgGiff from "../../images/landingPage/bg-video.gif";
import HeroBack from '../../images/landingPage/hero-back.png';
import halfCircleBack from '../../images/landingPage/halfCircleBack.png';
import footerLogo from "../../images/landingPage/footerLogo.png";
import { toastFunctionToaster } from "../../utils/toasterFunction";
import { syBlack, syWhite, syTitle, syGrey, syPurple } from "../../constants/color";

import { InstagramOutlined, YoutubeOutlined, TikTokOutlined, LinkedinOutlined } from "@ant-design/icons";
import { AiFillLinkedin, AiFillStar, AiOutlineInstagram, AiOutlineLinkedin, AiOutlineYoutube } from "react-icons/ai";
import { Typewriter } from "react-simple-typewriter";


// Api Services
import { addBetaRequest } from "../../services/sharedServices";

function LandingPage() {
	const toast = useToast();
	const navigate = useNavigate();
	const [emailId, setEmailId] = useState("");
	const [loading, setLoading] = useState(false);
	const [emailError, setEmailError] = useState("");
	const faqDiv = useRef(null);
	const userSayDiv = useRef(null);

	// Todo:Validate Email
	const validateEmail = () => {
		let formIsValid = true;
		let emailPattern = new RegExp(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
		);
		if (!emailId) {
			formIsValid = false;
			setEmailError("*Email is required.");
		} else if (!emailPattern.test(emailId)) {
			formIsValid = false;
			setEmailError("*Please enter valid email address.");
		} else {
			setEmailError();
		}
		return formIsValid;
	};

	const storeData = () => {
		if (validateEmail()) {
			setLoading(true);
			let obj = {
				user_email: emailId,
				first_name: "",
				last_name: "",
			};
			addBetaRequest(obj)
				.then(res => {
					setLoading(false);
					if (res?.result) {
						toast(toastFunctionToaster(res?.message, "success"));
						navigate("/verify", { state: { emailId: emailId } });
					} else {
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					setLoading(false);
					if (err) {
						toast(toastFunctionToaster(err?.message, "error"));
					}
				});
		}
	};
	  
	const scrollToDiv = (divType) => {
		if (divType === "faq") {
		  if (faqDiv.current) {
			ReactDOM.findDOMNode(faqDiv.current).scrollIntoView({
			  behavior: "smooth",
			  block: "start",
			});
		  }
		}
		if (divType === "app") {
			if (userSayDiv.current) {
			  ReactDOM.findDOMNode(userSayDiv.current).scrollIntoView({
				behavior: "smooth",
				block: "start",
			  });
			}
		  }
	  };

	return (
		<Box>
			<Box
				// backgroundImage={bgGiff}
				// bgSize={"cover"}
				h={'100vh'}
				// h={{
				// 	base: "auto",
				// 	md: "90vh",
				// 	lg: "90vh",
				// 	sm: "auto",
				// }}
			>
				<Box
					mx="auto"
					p="2"
					position={"fixed"}
					w="100%"
					zIndex={"4"}
					boxShadow={'xl'}
					transition="background-color 0.5s ease"
					display={{ base: "block", md: "block", lg: "block", sm: "block" }}
				>
					<Flex
						// className="header"
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Box w={{ base: "20%", md: "20%", lg: "10%", sm: "20%" }}>
							<Image w="90%" src={footerLogo} />
						</Box>
						<Box
							w={{ base: "50%", md: "30%", lg: "65%", sm: "50%" }}
							textAlign={"center"}
						>
							<Button 
								size={{
									base: "sm",
									md: "md",
									lg: "md",
									sm: "sm",
								}}
								onClick={() => {
									navigate('/app')
								}}							
								// onClick={() => {
								// 	scrollToDiv("app");
								// }}
								bg={'gray.200'}>App</Button>
							
							<Button 
								size={{
									base: "sm",
									md: "md",
									lg: "md",
									sm: "sm",
								}}							
								onClick={() => {
									scrollToDiv("faq");
								}}
								bg={'gray.200'} ml={1}>FAQ
							</Button>
							
						</Box>
						{/* <Link href="/login">
							<Button bg={'blue.200'}>Parter Login</Button>
						</Link> */}
					</Flex>
				</Box>
				<Flex
					w="100%"
					h="100%"
					flexWrap={"wrap"}
					textAlign={{
						base: "center",
						md: "start",
						lg: "start",
						sm: "center",
					}}
					pt={{
						base: "4rem",
						md: "0px",
						lg: "0px",
						sm: "4rem",
					}}
				>
					<Flex
						w={{ base: "100%", md: "50%", lg: "50%", sm: "100%" }}
						pl={{ base: "0", md: "4rem", lg: "4rem", sm: "0" }}
						alignItems={"center"}
						justifyContent={"center"}
					>
						<Box
							w={{
								base: "90%",
								md: "80%",
								lg: "80%",
								sm: "90%",
							}}
						>
							<Flex w={'100%'}>
								<Heading
									mb="1.5rem"
									fontSize={{
										base: "2.0rem",
										md: "3.0rem",
										lg: "3.0rem",
										sm: "2.0rem",
									}}
									fontWeight={"900"}
									color={syTitle}
								>
									Dream
								</Heading>
								<Heading
									mb="1.5rem"
									ml={1}
									fontSize={{
										base: "2.0rem",
										md: "3.0rem",
										lg: "3.0rem",
										sm: "2.0rem",
									}}
									fontWeight={"900"}
									color={syTitle}
								>
									Big,
								</Heading>
								<Heading
									ml={2}
									mb="1.5rem"
									fontSize={{
										base: "2.0rem",
										md: "3.0rem",
										lg: "3.0rem",
										sm: "2.0rem",
									}}
									fontWeight={"900"}
									color={syPurple}
								>
									Save
								</Heading>
								<Heading
									mb="1.5rem"
									ml={2}
									fontSize={{
										base: "2.0rem",
										md: "3.0rem",
										lg: "3.0rem",
										sm: "2.0rem",
									}}
									fontWeight={"900"}
									color={syTitle}
								>
									Smart
								</Heading>
							</Flex>
							{/* <Heading
								className="typewriter-text"
								mb="1rem"
								fontSize={{
									base: "2.5rem !important",
									md: "3.5rem !important",
									lg: "3.5rem !important",
									xl: "3rem !important",
									sm: "2.5rem !important",
								}}
							>
								<Typewriter
									words={[
										"Smart Spendings",
										"Better Savings",
										"Together",
									]}
									loop={0}
									cursor
									cursorStyle="."
									typeSpeed={70}
									deleteSpeed={50}
									delaySpeed={1000}
								/>
							</Heading> */}
							<Flex w={'100%'}>
								<Text
									color={syTitle}
									fontWeight={"400"}
									pb="2"
									fontSize={"16px"}
								>
									The money saving app for college students
								</Text>
							</Flex>

							<Flex w={'100%'} 
								align={{
									base: "center",
									md: 'start',
									lg: 'start',
									sm: "center",
								}} 
								justify={{
									base: "center",
									md: 'start',
									lg: 'start',
									sm: "center",
								}} 
								// align={'center'} justify={'center'}
								>
								{/* <SimpleGrid
									w={'100%'}
									spacing={5}
									columns={{ base: 1, md: 1, lg: 1, sm: 1 }}
								> */}
									{/* <Button
										// borderLeftRadius={'5%'}
										// borderRadius={'20%'}
										rounded={'xl'}
										boxShadow={'xl'}
										color={'white'}
										bg={'black'}
										>
										Create My X'mas Wishlist
									</Button> */}
									<Button
										w={'220px'}
										onClick={() => {
											navigate('/app')
										}}
										mt={4}
										ml={1}
										boxShadow={'xl'}
										rounded={'xl'}
										color={'white'}
										bg={'black'}
										>
										Sign up and Start Saving
									</Button>
								{/* </SimpleGrid> */}
							</Flex>
							{/* <Flex
								mt="4rem"
								alignItems={"center"}
								flexWrap={{
									base: "wrap",
									md: "wrap",
									lg: "wrap",
									xl: "noWrap",
									sm: "wrap",
								}}
							>
								<Input
									w={{
										base: "100%",
										md: "100%",
										lg: "100%",
										xl: "50%",
										sm: "100%",
									}}
									mb={{
										base: "2rem",
										md: "2rem",
										lg: "2rem",
										xl: "0px",
										sm: "2rem",
									}}
									h="60px"
									bg={syWhite}
									borderWidth={"1px"}
									borderColor={syGrey}
									value={emailId}
									onChange={e => {
										setEmailId(e.target.value);
									}}
									placeholder="Your email address"
								></Input>
								<Button
									ml="2"
									w={{
										base: "100%",
										md: "100%",
										lg: "100%",
										xl: "auto",
										sm: "100%",
									}}
									borderRadius={"50px"}
									size={{
										base: "md",
										md: "lg",
										lg: "lg",
										sm: "md",
									}}
									bg={syBlack}
									color={syWhite}
									fontWeight={"normal"}
									_hover={{ bg: syBlack }}
									onClick={() => {
										storeData();
									}}
									isDisabled={loading ? true : false}
									isLoading={loading ? true : false}
								>
									Sign up for Beta Testing
								</Button>
							</Flex> */}
							{/* <Text color="red" mt="2" fontWeight={"600"}>
								{emailError}
							</Text> */}
						</Box>
					</Flex>
					<Flex
						w={{ base: "100%", md: "50%", lg: "50%", sm: "100%" }}
						alignItems={"center"}
						justifyContent={"center"}
						mt={{
							base: "1rem",
							md: "none",
							lg: "none",
							sm: "1rem",
						}}
					>
						<Image
							h={{
								base: "auto", //"70%",
								md: "auto", //"60%",
								lg: "auto", //"60%",
								sm: "auto", //"70%",
							}}
							w={{
								base: "100%",
								md: "100%",
								lg: "100%",
								sm: "100%",
							}}
							src={HeroBack}
							// src={
							// 	"https://assets.softr-files.com/applications/b935a0b4-9f41-4bb2-8938-0b0607c93421/assets/8ab27ee8-0ee3-4e52-b17e-e3d9fce13764.svg"
							// }
						/>
					</Flex>
				</Flex>
			</Box>
			<Box 
				backgroundImage={halfCircleBack}
				backgroundSize={'cover'}
				backgroundRepeat={'no-repeat'}
				backgroundPosition={'center'}					
				w="90%" mx={'10'} align={'center'}>
				<Flex
					ref={userSayDiv}
					direction={'column'}
					w={{ base: "100%", md: "50%", lg: "50%", sm: "100%" }}
					align={'center'}
					justify={'center'}
					mt={{
						base: "4rem",
						md: "none",
						lg: "none",
						sm: "4rem",
					}}
				>
					<Heading
						mt={20}
						mb={10}
						// w={'100%'}
						// align={'center'}
						// justify={'center'}
						fontSize={{
							base: "2.5rem",
							md: "3.5rem",
							lg: "3.5rem",
							sm: "2.5rem",
						}}
						fontWeight={"900"}						
						>
							What our users say
					</Heading>
					<SimpleGrid
						w={'100%'}
						spacing={5}
						columns={{ base: 1, md: 2, lg: 2, sm: 1 }}
					>
						<Card 
							// h={'180px'}
							p={5}
							rounded={'lg'}
							boxShadow={'xl'}
							w={'90%'}>
							<Flex w={'100%'}>
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Box w={'100%'} align={'start'}>
								<Text 
								fontSize={{
									base: "sm",
									md: "md",
									lg: "md",
									sm: "sm",
								}}
								>
								I love how visual and fun the app is! The Dream Life feature keeps me motivated to save without feeling guilty.
								</Text>
								</Box>
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Text
									fontSize={{
										base: "sm",
										md: "md",
										lg: "md",
										sm: "sm",
									}}
								>
									Oct 27, 2024
								</Text>
							</Flex>

						</Card>
						<Card 
							// h={'180px'}
							p={5}
							rounded={'lg'}
							boxShadow={'xl'}
							w={'90%'}
							>
							<Flex w={'100%'}>
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Box w={'100%'} align={'start'}>
								<Text 
								fontSize={{
									base: "sm",
									md: "md",
									lg: "md",
									sm: "sm",
								}}
								>
								This app makes saving easy and fun. If it were no longer available, I’d be really disappointed because it’s such a useful tool for tracking my financial goals.
								</Text>
								</Box>
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Text
									fontSize={{
										base: "sm",
										md: "md",
										lg: "md",
										sm: "sm",
									}}
								>
									Nov 7, 2024
								</Text>
							</Flex>

						</Card>
						<Card 
							// h={'180px'}
							p={5}
							rounded={'lg'}
							boxShadow={'xl'}
							w={'90%'}
							>
							<Flex w={'100%'}>
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Box w={'100%'} align={'start'}>
								<Text 
								fontSize={{
									base: "sm",
									md: "md",
									lg: "md",
									sm: "sm",
								}}
								>
								The app is perfect to keep me motivated while managing my photography equipment fund while staying on top of rent
								</Text>
								</Box>
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Text
									fontSize={{
										base: "sm",
										md: "md",
										lg: "md",
										sm: "sm",
									}}
									>
									Nov 10, 2024
								</Text>
							</Flex>

						</Card>
						<Card 
							// h={'180px'}
							p={5}
							rounded={'lg'}
							boxShadow={'xl'}
							w={'90%'}
							>
							<Flex w={'100%'}>
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
								<Icon as={AiFillStar} h={6} w={6} color={syPurple} />
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Box w={'100%'} align={'start'}>
								<Text 
								fontSize={{
									base: "sm",
									md: "md",
									lg: "md",
									sm: "sm",
								}}
									>
								The app helps me track exactly how much I need to save each week, and the sense of accomplishment I get from it is incredible — it’s much more satisfying than using Excel.
								</Text>
								</Box>
							</Flex>
							<Flex w={'100%'} mt={3}>
								<Text
									fontSize={{
										base: "sm",
										md: "md",
										lg: "md",
										sm: "sm",
									}}
								>
									Nov 15, 2024
								</Text>
							</Flex>

						</Card>
					</SimpleGrid> 
				</Flex>
			</Box>
			<Box w="90%"  mx={'10'} align={'center'}>
				<Flex
					ref={faqDiv}
					direction={'column'}
					w={{ base: "100%", md: "50%", lg: "50%", sm: "100%" }}
					align={'center'}
					justify={'center'}
					mt={{
						base: "4rem",
						md: "none",
						lg: "none",
						sm: "4rem",
					}}
				>
					<Heading
						mb={10}
						fontSize={{
							base: "2.5rem",
							md: "3.5rem",
							lg: "3.5rem",
							sm: "2.5rem",
						}}
						fontWeight={"900"}						
						>
							Frequently Asked Questions
					</Heading>
				</Flex>
				<Flex w={'100%'} mt={3}>
					<Box w={'90%'} 
						ml={{
							base: 1,
							md: 20,
							lg: 20,
							sm: 1,
						}}
						align={'start'}>
					<Accordion allowToggle>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								What is Syval?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Syval is an app that empowers college students to build strong financial habits. It turns saving money into a fun and rewarding experience through gamification and engagement!
							</AccordionPanel>
						</AccordionItem>

						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								Who is Syval for?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Syval is designed for college students and young adults who want to manage their finances better, save for their dreams, and improve their financial habits in an engaging way.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								How does Syval work?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Syval encourages you to set aside money mentally, like a virtual piggy bank. We don’t hold your money—all your funds stay in your bank account, and you stay in control. You’ll get reminders, progress updates, and rewards to keep you motivated!
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								Is Syval free to use?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Yes, Syval is completely free to use.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								Where is Syval available?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Syval is currently available online through a web browser and works great on your phone or computer. A mobile app for easier access will be launched in early 2025!
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								Do I need any financial knowledge to use Syval?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Not at all! Syval is designed for everyone, even if you’re new to saving and budgeting. The app guides you step-by-step and makes managing money simple.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								What are Syval stars, and how can I use them?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Syval stars are rewards you earn by saving towards your goals, engaging with the app, or reaching milestones. You can use them to personalize and decorate your virtual room, making it reflect your achievements!
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								What are digital items, and how can I get them?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Digital items are virtual decorations that can be added to your personalized room within the app. You earn 10 stars each time you complete a savings goal. These stars can then be used as currency to purchase new, fun virtual items for your room.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								Is my financial data safe with Syval?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							Absolutely. Syval uses 256-bit bank-grade encryption to ensure your financial data is secure. We do not share your personal data with third parties without your consent.
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								What if I encounter a problem with the app?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							You can reach out to our support team directly through the app or email us at support@mysyval.com. We're here to help!
							</AccordionPanel>
						</AccordionItem>
						<AccordionItem>
							<h2>
							<AccordionButton>
								<Box as='span' flex='1' textAlign='left'>
								How can I provide feedback on Syval?
								</Box>
								<AccordionIcon />
							</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
							We love hearing from our users! You can submit feedback via the app or by emailing support@mysyval.com
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
					</Box>
				</Flex>
			</Box>
			{/* <Box w="90%"  mx={'10'} mt={10} align={'center'}>
				<Flex w={'100%'} mt={3}>
					<Box w={'100%'} 
						ml={{
							base: 1,
							md: 20,
							lg: 20,
							sm: 1,
						}}
						align={'start'}>
							<Flex w={'50%'}>
								<Image
									w={{
										base: "20%",
										md: "5%",
										lg: "5%",
										sm: "20%",
									}}
									mx={{
										base: "auto",
										md: "0",
										lg: "0",
										sm: "auto",
									}}
									src={footerLogo}
								/>
							</Flex>
							<Flex w={'50%'}>
								<Icon as={AiOutlineLinkedin}  color={syPurple} />
								<Icon as={TikTokOutlined}  color={syPurple} />
								<Icon as={AiOutlineInstagram}  color={syPurple} />
								<Icon as={AiOutlineYoutube}  color={syPurple} />
							</Flex>
					</Box>
				</Flex>
			</Box> */}
			<Flex w="90%" mx="auto" 
				borderBottomWidth={'1px'} borderBottomColor={'gray.400'}
				pb={10}
				mt={10} direction={'row'}>
				<Box w={'100%'} ml={10}>
					<Image
						w={{
							base: "80%",
							md: "20%",
							lg: "20%",
							sm: "80%",
						}}
						mx={{
							base: 0,
							md: 10,
							lg: 10,
							sm: 0,
						}}
						src={footerLogo}
					/>
				</Box>
				<Flex w={'200px'} align={'end'}
					 mr={{
						base: 0,
						md: 20,
						lg: 20,
						sm: 0,
					 }}>
					<Link
						mr="1"
						target="_blank"
						href="https://www.linkedin.com/company/mysyval/"
						w="35px"
					>
					<Icon as={LinkedinOutlined}  style={{ fontSize: '150%'}} color={syPurple} />
					</Link>
					<Link
						mr="1"
						target="_blank"
						href="https://www.tiktok.com/@syvalapp"
						w="35px"
					>
						<Icon as={TikTokOutlined} ml={2} style={{ fontSize: '150%'}} color={syPurple} />
					</Link>
					<Link
						mr="1"
						target="_blank"
						href="https://www.instagram.com/syval.app/"
						w="35px"
					>
					<Icon as={InstagramOutlined} ml={2} style={{ fontSize: '150%'}} color={syPurple} />
					</Link>
					<Link
						mr="1"
						target="_blank"
						href="https://www.youtube.com/@syvalapp"
						w="35px"
					>
					<Icon as={YoutubeOutlined} ml={2} style={{ fontSize: '150%'}} color={syPurple} />
					</Link>
				</Flex>
			</Flex>
			<Flex w="90%" mx="auto" 
				// borderBottomWidth={'1px'} borderBottomColor={'gray.400'}
				pb={10}
				mt={10} 
				align={'start'}
				direction={{
					base: "column",
					md: "row",
					lg: "row",
					sm: "column",
				}}>
				<Box w={'100%'} ml={10}>
					<Flex w={'100%'}>
					<Text
						fontWeight={"normal"}
						fontSize={{
							base: "10px",
							md: "10px",
							lg: "10px",
							sm: "10px",
						}}
					>
						Terms of Use
					</Text>
					<Text
						ml={2}
						fontWeight={"normal"}
						fontSize={{
							base: "10px",
							md: "10px",
							lg: "10px",
							sm: "10px",
						}}
					>
						Privacy Policy
					</Text>
					</Flex>

				</Box>
				<Flex w={'300px'} align={'start'} mr={10}>
					<Text
						fontWeight={"normal"}
						fontSize={{
							base: "10px",
							md: "10px",
							lg: "10px",
							sm: "10px",
						}}
					>
						© 2024 Syval, Inc. All rights reserved.
					</Text>
				</Flex>
			</Flex>
		</Box>
	);
}

export default LandingPage;
