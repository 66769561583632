import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate, useLocation} from 'react-router-dom';
// React Chakra Imports
import {
	Box,
	Flex,
	Tabs,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Heading,
} from "@chakra-ui/react";

// Component Imports
import Profile from "./profile/Profile";
import DreamLife from "./dreamLife/DreamLife";
import CommonHeader from "../../common/CommonHeader";
import FooterProfile from "../../common/FooterProfile";
import CommonWidget from "../../common/CommonWidget";
import {
	syThemeBgBlueColor,
	syThemeBgGreenColor,
	syThemeBgYellowColor,
	syThemeBgGrayColor,
	syWhite,
} from "../../../constants/color";

function UserProfile() {
	const navigate = useNavigate();
	const location = useLocation();

	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState('profile');
	const [tabIndex, setTabIndex] = useState(0);
	// Get a specific query parameter
	const page = searchParams.get('page'); 
	// const [tabName, setTabName] = useState(searchParams.get('page'));
	// const [tabIndex, setTabIndex] = useState(page === 'profile'  ? 1 : 0 );
	
	// debugger;
	// setTabName(page === 'profile'  ? "profile" :  "dream");

	useEffect(() => {
		const tabFromQuery = searchParams.get('page');
		if (tabFromQuery) {
			setActiveTab(tabFromQuery);
			setTabIndex(tabFromQuery === 'profile' ? 1 : 0)
		}
	}, [searchParams]);

	const handleTabChange = (newTab) => {
		setActiveTab(newTab);
		setTabIndex(newTab)
	};

	// useEffect(() => {
	// 	//setTabName(page === 'profile'  ? "profile" :  "dreamlife")
	// 	//debugger;
	// 	setTabName(searchParams.get('page'))
	// 	setTabIndex(searchParams.get('page') === 'profile'  ? 1 : 0 )
	// 	console.log(tabName + " - " + tabIndex);
	// }, [page]);

	return (
		<Box
			// bg={syThemeBgGrayColor}
			bgGradient={[
				'linear(to-b, #e6e6e6, #060606)',
			]}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
			minH="940px"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Flex w={'100%'} mt={10} align={'center'} justify={'center'}>
					<Heading fontStyle={'bold'}>
						My Profile
					</Heading>
				</Flex>
				<Flex w={'100%'} mt={10} p={4} align={'center'} justify={'center'}>
					<Profile tabValue={activeTab} />
				</Flex>
			</Box>
			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
				userRoute="/genz/user-profile"
			/>
		</Box>
	);
}

export default UserProfile;
