// Constant Color
export const syBlack = "#000000";
export const syWhite = "#ffffff";
export const syTitle = "#152237";
export const syGreen = "#DAF66F";
export const syGrey = "#F6F7FF";
export const syBgGrey = "#151117";
export const syPurple = "#5F67EC";
export const syBorderGrey = "#979797";
export const syArrowRedColor = "#B22222";
export const syArrowGreenColor = "#4ab493";
export const syArrowWhiteColor = "#E6E6E6";
export const syThemeLabelColor = "#8F94A3";
export const syThemeBgRedColor = "#ffb2b2";
export const syThemeBgBlackColor = "#000000";
export const syThemeBgBlueColor = "#5F67EC"; // "#5455D1";
export const syThemeBgGrayColor = "#D6D6D6";
export const syThemeBgYellowColor = "#DAF66F";
export const syThemeBgGreenColor = "#BEFF6C";
