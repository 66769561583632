// React Chakra Imports
import React, { useEffect, useState } from "react";

import {
	Box,
	Img,
	Text,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	InputLeftAddon,
	InputRightAddon,
	ModalCloseButton,
} from "@chakra-ui/react";

// Component Imports
import FooterProfile from "../../common/FooterProfile";
import robot from "../../../images/genz/common-footer/help-robot.png";
import {
	syBlack,
	syThemeBgGreenColor,
	syWhite,
} from "../../../constants/color";
import { useNavigate } from "react-router-dom";

const Help = () => {
	const navigate = useNavigate();
	const ModalBgOpacityOverlay = () => (
		<ModalOverlay
			bg="blackAlpha.300"
			// backdropFilter="blur(10px) hue-rotate(90deg)"
		/>
	);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [overlay, setOverlay] = React.useState(<ModalBgOpacityOverlay />);

	useEffect(() => {
		onOpen();
		setOverlay(<ModalBgOpacityOverlay />);
	}, []);

	// Todo:Handle Modal Close
	const handleClose = () => {
		onClose();
		navigate(-1);
	};

	return (
		<Box bg={syThemeBgGreenColor} h="100vh">
			<Modal
				isCentered
				isOpen={isOpen}
				onClose={() => {
					handleClose();
				}}
				size={"md"}
			>
				{overlay}
				<ModalContent w="90%">
					<>
						<ModalHeader textAlign={"center"}>Help</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							<Box w="50%" mx="auto">
								<Img src={robot} />
							</Box>
							<Box h="400px">
								<Text mb="10px">
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
								<Text mb="10px">
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
								<Text>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
								<Text mb="10px">
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
								<Text>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
								<Text mb="10px">
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
								<Text>
									Lorem Ipsum is simply dummy text of the
									printing and typesetting industry.{" "}
								</Text>
							</Box>
						</ModalBody>
					</>

					<ModalFooter borderTopWidth={"1px"}>
						<Button
							onClick={() => {
								handleClose();
							}}
							colorScheme="red"
						>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default Help;
