// Chakra Imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, 
    Img, 
    Flex,
    Icon,
    Heading,
    Text, Button } from "@chakra-ui/react";

// Component Imports
import syRocket from "../../images/boarding/rocket.png";
import syLogo from "../../images/boarding/syval_logo.png";
import syLogoSmall from '../../images/common/syval-logo-small.png';
import promoback01 from '../../../src/images/promo/promoback01.jpg';
import stripe from '../../images/promo/strip30.png';
import promoback02 from '../../../src/images/promo/promoback02.jpg';
import snowglobe01 from '../../../src/images/promo/sg01.png';
import snowglobe02 from '../../../src/images/promo/sg02.png';
import snowglobe03 from '../../../src/images/promo/sg03.png';
import ytlogo from '../../../src/images/promo/ytlogo.png';
import { AiOutlineYoutube, AiSpot } from "react-icons/ai";
import { FaSpotify, FaYoutube } from "react-icons/fa";

import {
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../constants/color.js";
import useResponsiveHeight from "../../customHook/ResponsiveHeight.js";

function Promotions() {
    // console('data : ' + data);
	const navigate = useNavigate();
    const randNum = Math.floor(Math.random() * 10) + 1;
    // const globeId = (Math.floor(Math.random() * 10) + 1) % 3;
    const globeId = getRandomInt(1, 3);
	const height = useResponsiveHeight();

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [topArtists, setTopArtists] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [error, setError] = useState(null);

    const CLIENT_ID = "c784371ef3aa44bab02e174acc34ceab"
    // const CLIENT_SECRET = "2f4697deada54f08b69c2a151adbc4ea"
    const REDIRECT_URI = "https://mysyval.com/promotion"
    // const REDIRECT_URI = "http://localhost:3000/promotion"
    const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
    const RESPONSE_TYPE = "token"
    const ARTIST_LIMIT = 1

    // Spotify API Configuration
    const SCOPES = [
        'user-read-private', 
        'user-read-email', 
        'user-top-read'
    ].join(' ');

    // Initiate Spotify Authentication
    const initiateSpotifyLogin = () => {
        const authEndpoint = 'https://accounts.spotify.com/authorize';
        const responseType = 'token';
        
        const authUrl = `${authEndpoint}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPES}&response_type=${responseType}&show_dialog=true`;
        
        window.location = authUrl;
    };
    
    // Extract Access Token from URL
    useEffect(() => {
        const hash = window.location.hash
        .substring(1)
        .split('&')
        .reduce((acc, curr) => {
            const parts = curr.split('=');
            acc[parts[0]] = decodeURIComponent(parts[1]);
            return acc;
        }, {});

        if (hash.access_token) {
            setAccessToken(hash.access_token);
            // setIsConnected(true);
            window.location.hash = ''; // Clear the hash
        }
    }, []);
    
    // Fetch User Profile
    useEffect(() => {
    const fetchData = async () => {
        if (!accessToken) return;

        try {
            const response = await fetch('https://api.spotify.com/v1/me', {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
            });

            if (!response.ok) {
            throw new Error('Failed to fetch profile');
            }

            const profData = await response.json();
            setProfileData(profData);

            // Fetch Top Artists
            const topArtistsResponse = await fetch(`https://api.spotify.com/v1/me/top/artists?time_range=medium_term&limit=${ARTIST_LIMIT}`, {
                headers: {
                'Authorization': `Bearer ${accessToken}`
                }
            });
    
            if (!topArtistsResponse.ok) {
                throw new Error('Failed to fetch top artists');
            }
    
            const topArtistsData = await topArtistsResponse.json();
            setTopArtists(topArtistsData.items);
            setIsConnected(true);

            if (topArtistsData.items?.length > 0 && profData?.email){
                //fetchSpotifyPrefDetails(profData?.email, topArtistsData.items);
                navigate("/promotion/spotify", {
                    state: { 
                        isConnected: true,
                        profileData: profData,
                        topArtistsData: topArtistsData
                    },
                });
            }
        } catch (err) {
            setError(err.message);
        }
    };

    fetchData();
    }, [accessToken]);
    

	return (
		<Box
            // backgroundImage={randNum % 2 == 0 ? promoback01 : promoback01}
            backgroundImage={stripe}
			// bg={syThemeBgBlueColor}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
		>
			<Box mt={10} textAlign={"center"}>
				<Heading
                    color={'black'}
                    mt={10}
                    rounded={'lg'}
                    // bg={'gray.600'}
                    fontSize={'5xl'}
					textAlign={"center"}
					fontWeight="900"
					// color={syWhite}
				>
					Your
				</Heading>
				<Heading
                    rounded={'lg'}
                    // bg={'gray.600'}
                    fontSize={'5xl'}
					textAlign={"center"}
					fontWeight="900"
                    color={'black'}
				>
					Holiday Gift
				</Heading>
                <Heading
                    rounded={'lg'}
                    // bg={'gray.600'}
                    fontSize={'5xl'}
					textAlign={"center"}
					fontWeight="900"
                    color={'black'}
				>
                     Personality
				</Heading>
            </Box>
            <Box textAlign={"center"}>
                <Text
                    fontSize={'lg'}
					mt="4rem"
					textAlign={"center"}
					fontWeight="500"
                    color={'black'}
                    >
                        Plan, save and celebrate - 
                    {/* Share your 🎄 wishlist to make holiday gifting easier and more affordable for everyone. */}
                </Text>
                <Text
                    fontSize={'lg'}
					textAlign={"center"}
					fontWeight="500"
                    color={'black'}
                    >
                        Syval brings your gifting goals to life this holiday 
                </Text>
				<Box mt="1rem">
					<Img 
                    h={'200px'}
                    src={(Math.floor(Math.random() * 10) + 1) % 2 == 0 ? snowglobe01 : snowglobe02} 
                    // src={`${'snowglobe0'}${globeId}`}
                    mx="auto" />
				</Box>
                <Flex w={'100%'} align={'center'} justify={'center'}>
                    {/* <Flex mt="1rem"
                        w={'45%'}
                        h={'40px'}
                        align={'center'}
                        justify={'center'}
                        bg={syWhite}
                        onClick={() => navigate('/promotion/youtube')}
                        color={syThemeBgBlueColor}
                        _hover={{ bg: syThemeBgYellowColor, cursor:'pointer' }}
                        borderRadius={"50px"}
                        textAlign={"center"}>
                            <Icon as={FaYoutube} h={8} w={8} color={'red'} mr={2} />
                            <Text>YouTube</Text>
                    </Flex> */}
                    <Flex mt="1rem"
                        ml={1}
                        boxShadow={'xl'}
                        w={'80%'}
                        h={'40px'}
                        align={'center'}
                        justify={'center'}
                        bg={syWhite}
                        onClick={initiateSpotifyLogin}
                        // onClick={() => navigate('/promotion/spotify')}
                        color={syThemeBgBlueColor}
                        _hover={{ bg: syThemeBgYellowColor, cursor:'pointer' }}
                        borderRadius={"50px"}
                        textAlign={"center"}>
                            <Icon as={FaSpotify}  h={8} w={8} color={'green.300'} mr={2} />
                            <Text fontWeight={'800'} color={'black'}>Connect to Start</Text>
                    </Flex>
                </Flex>
                <Box mt="1rem" textAlign={"center"}>
					<Text
						mt="1rem"
						textAlign={"center"}
						color={'black'}
					>
						Powered By Syval with ❤️
					</Text>
                    <Box rounded={'lg'}>
                        <Img mx="auto" src={syLogoSmall} h={'40px'} />
                    </Box>
				</Box>
			</Box>
		</Box>
	);
}

export default Promotions;
