// React Chakra Imports
import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { Flex, Img, Box, Text, Modal, Link, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, ModalCloseButton, useToast } from "@chakra-ui/react";

// Component Imports
import Settings from "./Settings";
import ProfileDetails from "./ProfileDetails";
import UserBankDetails from "./UserBankDetails";
import UploadProfileImage from "./UploadProfileImage";
import userIcon from "../../../../images/genz/accordion/user.png";
import { getUserDetails } from "../../../../services/userServices";
import bankIcon from "../../../../images/genz/accordion/bank.png";
import helpIcon from "../../../../images/genz/accordion/help.png";
import settingsIcon from "../../../../images/genz/accordion/setting.png";
import { toastFunctionToaster } from "../../../../utils/toasterFunction";
import userProfileImage from "../../../../images/genz/user-profile.png";
import { syBlack, syWhite, syBorderGrey, syGrey, syThemeBgBlueColor } from "../../../../constants/color";

function Profile({ tabValue }) {
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [userDetails, setUserDetails] = useState({});

	const bankConnection = process.env.REACT_APP_BANK_CONNECTION;

	useEffect(() => {
		fetchUserDetails();
	}, [tabValue]);

	const fetchUserDetails = () => {
		getUserDetails()
			.then(res => {
				if (res?.result) {
					setUserDetails(res?.data[0]);
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const refreshGoalData = value => {
		if (value === "true") {
			fetchUserDetails();
			onClose();
		}
	};

	return (
		<Box w={"100%"} bg={syWhite} borderRadius={"10px"} mb="2rem">
			<Box px="20px" py="30px">
				<Box w={"100%"} h={"150px"} position="relative">
					<Flex w={"100%"} direction={"column"} align={"center"} p={2}>
						<Img
							h="100px"
							w="100px"
							border={"1px"}
							borderColor={syBorderGrey}
							p="5px"
							// bg={syThemeBgGreenColor}
							src={userDetails?.photo_url ? userDetails?.photo_url : userProfileImage}
							borderRadius={"50%"}
							mx="auto"
						/>
						<Text mt={1} color={"black"} fontSize={12} textDecoration={"underline"} fontWeight={"700"} onClick={onOpen}>
							Update Photo
						</Text>
					</Flex>
				</Box>
				<Accordion allowMultiple mt="8">
					<AccordionItem border={"0"} mb="5">
						{({ isExpanded }) => (
							<>
								<AccordionButton _hover={{ bg: "none" }} p="0">
									<Flex alignItems={"center"} w="100%">
										<Img bg={syBlack} p="10px" borderRadius={"50px"} src={userIcon} mr="3" h="45px" w="45px" objectFit={"contain"} />
										<Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
											My Accounts
										</Box>
									</Flex>
									{isExpanded ? <IoIosArrowDown fontSize="16px" /> : <IoIosArrowForward fontSize="16px" />}
								</AccordionButton>
								<AccordionPanel pb={4} px="0">
									<ProfileDetails isOpen={isExpanded} />
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
					<AccordionItem border={"0"} mb="5">
						{({ isExpanded }) => (
							<>
								<AccordionButton _hover={{ bg: "none" }} p="0">
									<Flex alignItems={"center"} w="100%">
										<Img bg={syBlack} p="10px" borderRadius={"50px"} src={bankIcon} mr="3" h="45px" w="45px" objectFit={"contain"} />
										<Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
											My Banks
										</Box>
									</Flex>
									{isExpanded ? <IoIosArrowDown fontSize="16px" /> : <IoIosArrowForward fontSize="16px" />}
								</AccordionButton>
								<AccordionPanel pb={4} px="0">
									{bankConnection == "1" ? <UserBankDetails /> : <Text p={3}>Bank Connection: Coming Your Way Soon!</Text>}
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
					<AccordionItem border={"0"} mb="5">
						{({ isExpanded }) => (
							<>
								<AccordionButton _hover={{ bg: "none" }} p="0">
									<Flex alignItems={"center"} w="100%">
										<Img bg={syBlack} p="10px" borderRadius={"50px"} src={settingsIcon} mr="3" h="45px" w="45px" objectFit={"contain"} />
										<Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
											Settings
										</Box>
									</Flex>
									{isExpanded ? <IoIosArrowDown fontSize="16px" /> : <IoIosArrowForward fontSize="16px" />}
								</AccordionButton>
								<AccordionPanel pb={4} px="0">
									<Settings />
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
					<AccordionItem border={"0"} mb="5">
						{({ isExpanded }) => (
							<>
								<AccordionButton _hover={{ bg: "none" }} p="0">
									<Flex alignItems={"center"} w="100%">
										<Img bg={syBlack} p="10px" borderRadius={"50px"} src={helpIcon} mr="3" h="45px" w="45px" objectFit={"contain"} />
										<Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
											Help Center
										</Box>
									</Flex>
									{isExpanded ? <IoIosArrowDown fontSize="16px" /> : <IoIosArrowForward fontSize="16px" />}
								</AccordionButton>
								<AccordionPanel pb={4} px="0">
									<Flex w={"100%"} p={3}>
										<Text>
											Have any questions? Feel free to contact{" "}
											<Link color={"blue"} textDecoration={"underline"} href="mailto:support@mysyval.com" ml={1} mr={1}>
												{" "}
												support@mysyval.com
											</Link>{" "}
											for more information.
										</Text>
									</Flex>
								</AccordionPanel>
							</>
						)}
					</AccordionItem>
				</Accordion>
			</Box>
			<Modal isOpen={isOpen} onClose={onClose} size="sm">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader borderBottomWidth={"1px"}>Upload Profile Image</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<UploadProfileImage passSuccessFlag={refreshGoalData} />
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	);
}

export default Profile;
