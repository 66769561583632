// Chakra Imports
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, 
    Img, 
    Image,
    Flex,
    Heading,
    useToast,
    Text, Button, 
    SimpleGrid} from "@chakra-ui/react";

// Component Imports
import syRocket from "../../images/boarding/rocket.png";
import christmaslights from "../../images/promo/christmaslights.png";
import promoback01 from '../../../src/images/promo/promoback01.jpg';
import snowback from '../../../src/images/promo/snowback.jpg';
import { toastFunctionToaster } from "../../utils/toasterFunction";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import {
    syBlack,
    syGrey,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../constants/color.js";
import useResponsiveHeight from "../../customHook/ResponsiveHeight.js";

// Api Services
import { collectWishlist2024 } from "../../services/userServices";

function Wishlist2024() {
    const toast = useToast();
    const { state } = useLocation();
    const navigate = useNavigate();
	const height = useResponsiveHeight();
    const imageRef = useRef();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sharedId = urlParams.get('promoId') ? urlParams.get('promoId') : null;

    const [fetchLoading, setFetchLoading] = useState(false);
    const [wishlistItems, setWishlistItems] = useState([]);
    const [personalityId, setPersonalityId] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [pageBg, setPageBg] = useState(snowback);

    const personalityKeywords = useState([
        {'id': 'AvantGarde', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/AvantGardeHoliday30.jpg'},
        {'id': 'ClassicalHoliday', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/ClassicalHolidayMastreo30.jpg'},
        {'id': 'CountryChristmas', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/CountryChristmasCharmer30.jpg'},
        {'id': 'CozyNiche', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/CozyNicheCollector30.jpg'},
        {'id': 'ElectricHoliday', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/ElectricHolidayVibes30.jpg'},
        {'id': 'FestiveBeats', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/FestiveBeatsVibe30.jpg'},
        {'id': 'GlamRockstar', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/GlamRockstar30.png'},
        {'id': 'GlobalFestive', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/GlobalFestiveExplorer30.jpg'},
        {'id': 'RapRoyale', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/RapRoyale30.jpg'},
        {'id': 'SoulfulSnowflake', 'image': 'https://syvalpub.s3.us-west-1.amazonaws.com/promo/SoulfulSnowflake30.jpg'}
    ])


    useEffect(() => {
		fetchWishlistDetails();
	}, [sharedId]);

	// The fetchGoalDetails function remains the same
	const fetchWishlistDetails = () => {
        if (sharedId != null){
            setFetchLoading(true);
            let obj = {
                sharedId: sharedId,
            };
            return collectWishlist2024(obj)
                .then(res => {
                    setFetchLoading(false);
                    if (res?.result) {
                        if (res?.data[0]?.status && res?.data[0]?.items?.length > 0)
                        {
                            setWishlistItems(res?.data[0]?.items);
                            setPersonalityId(res?.data[0]?.personality_id);
                            setBackgroundImage(updatePersonalityBackground(res?.data[0]?.personality_id));
                            toast(toastFunctionToaster(res?.message, "success"));
                        } else {
                            toast(toastFunctionToaster(res?.message, "error"));
                        }
                    } else {
                        toast(toastFunctionToaster(res?.message, "error"));
                    }
                })
                .catch(err => {
                    setFetchLoading(false);
                    if (err) {
                        toast(toastFunctionToaster(err?.message, "error"));
                    }
                });
        }
	};

    const renderWishlistItems = () => {
		if (wishlistItems?.length) {
			return wishlistItems?.map((data, index) => {
				return (
					<Flex key={index} 
                        p={2}
                        onClick={() => {
                            // isUploadPanel(false);
                            // isDetailPanel(true);
                            // setImageText(data?.resource_content_details ? data?.resource_content_details  : null);
                            // setSelectedResUuid(data?.resource_content_uuid);
                            // setImageUrl(data?.resource_content_url);
                            // onOpen();
                        }}
                        align={'center'}>
						<Img
							h="120px"
							w="120px"
							mr="2"
                            rounded={'lg'}
							// border="1px"
							// borderRadius={"5px"}
							src={data?.promo_photo_url}
						/>
					</Flex>
				);
			});
		} 
	};

    const updatePersonalityBackground = (pxId) => {
        if (pxId != null || personalityId != null){
            let pId = pxId || personalityId;
            pId = pId.replaceAll(" ","");
            pId = pId.replaceAll("-","");
            for (var item in personalityKeywords[0]){
                if (pId.toLowerCase().includes(personalityKeywords[0][item]['id'].toLowerCase()) === true){
                    setPageBg(personalityKeywords[0][item]['image']);
                    return(personalityKeywords[0][item]);

                }
            }
            // return personalityKeywords?.map((item, index) => {
            //     debugger;
            //     if (pId.toLowerCase().includes(item.toLowerCase()) === true){
            //         return(item);
            //     }
            // })
            return snowback;
        }
        return snowback;
    }
        
    const onSaveImageClick = useCallback(() => {
        if (imageRef.current === null) {
          return
        }
    
        toPng(imageRef.current, { cacheBust: true, })
          .then((dataUrl) => {
            const link = document.createElement('a')
            link.download = 'syval-wishlist.png'
            link.href = dataUrl
            link.click()
          })
          .catch((err) => {
            console.log(err)
          })
    }, [imageRef])


	return (
		<Box
            backgroundImage={promoback01}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
		>
            {wishlistItems != null && wishlistItems?.length > 0
			?<Flex w={'100%'} direction={'column'}>
                <Box 
                    h={'600px'}
                    rounded={'lg'}
                    backgroundImage={pageBg ? pageBg : snowback}
                    ref={imageRef}
                    textAlign={"center"}>
                    <Image src={christmaslights} />
                    <Heading
                        rounded={'lg'}
                        fontSize={'4xl'}
                        textAlign={"center"}
                        fontWeight="500"
                        color={'gray.700'}
                    >
                        {personalityId
                        ?personalityId
                        :'Holiday 2024 Wishlist'}
                    </Heading>
                    <Box w={'100%'} 
                        rounded={'lg'}
                        h={'400px'} mt="1rem" textAlign={"center"}>
                        <Flex w={'100%'} h={'100%'} align={'center'} justify={'center'}>
                            <SimpleGrid columns={2} spacing={10}>
                                {renderWishlistItems()}
                            </SimpleGrid>
                        </Flex>
                    </Box>
                </Box>
                <Flex w={'100%'} align={'center'} direction={'column'} justify={'center'}>
                    <Button
                        mt={5}
                        size={'lg'}
                        w={'300px'}
                        rounded={'xl'}
                        align={'center'}
                        justify={'center'}
                        color={syGrey}
                        bg={syBlack}
                        onClick={() => {
                            navigate("/")
                        }}
                        >
                        Start Saving with Syval
                    </Button>
                    <Button
                        size={'lg'}
                        w={'300px'}
                        mt={3}
                        rounded={'xl'}
                        align={'center'}
                        justify={'center'}
                        bg={syGrey}
                        onClick={() => {
                            onSaveImageClick()
                        }}
                        >
                        Download & Share
                    </Button>
                    <Button
                            mt={3}
                            rounded={'lg'}
                            w={'80%'}
                            align={'center'}
                            justify={'center'}
                            bg={syGrey}
                            onClick={() => {
                                navigate("/promotion")
                            }}
                            >
                            Create your wishlist 2024
                        </Button>
                </Flex>
            </Flex>
            :<Box w={'100%'} 
                rounded={'lg'}
                boxShadow={'lg'}
                borderColor={'red.500'}
                borderWidth={'0.5px'}
                h={'calc(100vh - 250px)'} mt="1rem" textAlign={"center"}>
                    <Flex w={'100%'} justify={'center'} align={'center'} direction={'column'} >
                        <Heading mt={5} w={'60%'} fontSize={'2xl'}>
                            You don't have proper credentials to access this page..
                        </Heading>
                        <Button
                            mt={10}
                            rounded={'lg'}
                            w={'80%'}
                            align={'center'}
                            justify={'center'}
                            bg={syGrey}
                            onClick={() => {
                                navigate("/promotion")
                            }}
                            >
                            Create your own wishlist 2024
                        </Button>
                    </Flex>
            </Box>}
		</Box>
	);
}

export default Wishlist2024;
