// Chakra Imports
import React from "react";
import { useSelector } from "react-redux";
import { Img, Box, Flex, Text, Icon } from "@chakra-ui/react";

// Component Imports
import rating from "../../images/genz/rating.png";
import money from "../../images/common/money.png";
import star from "../../images/common/star.png";
import cash from "../../images/genz/cash.png";
import { getMetrixData } from "../../utils/localStorageIndex";

import { syThemeBgBlueColor, syWhite } from "../../constants/color";
import { AiFillStar } from "react-icons/ai";
// import { BsCashCoin } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";

function CommonWidget() {
	let metrixData = getMetrixData();
	let widgetData = JSON.parse(metrixData);
	return (
		// <Box
		// 	w="80px"
		// 	h="75px"
		// 	bg={"#eff0fd"}
		// 	borderRadius={"5px"}
		// 	p="10px"
		// 	px="15px"
		// >
		// 	<Flex alignItems={"center"} justifyContent={"space-between"}>
		// 		<Text
		// 			fontSize={"16px"}
		// 			fontWeight={"700"}
		// 			color={syThemeBgBlueColor}
		// 		>
		// 			{widgetData?.stars}
		// 		</Text>
		// 		<Img src={rating} />
		// 	</Flex>
		// 	<Flex alignItems={"center"} justifyContent={"space-between"}>
		// 		<Text
		// 			fontSize={"16px"}
		// 			fontWeight={"700"}
		// 			color={syThemeBgBlueColor}
		// 		>
		// 			{widgetData?.saving}
		// 		</Text>
		// 		<Img src={chip} />
		// 	</Flex>
		// </Box>
		<Flex
		// bg={syWhite} borderRadius="50px"
		>
			<Box bg={syWhite} px="2" py={1} borderRadius="50px" mr="2">
				<Flex alignItems={"center"}>
					{/* 89f336 */}
					{/* <Icon 
						//sx={{ boxShadow: "md" }} 
						as={FaMoneyBillAlt} h={6} w={6} color={'#118c4f'}/> */}
					<Img src={money} h={7} w={7} />
					<Text pl="2" fontWeight={"600"}>
						${widgetData?.saving ? widgetData?.saving : 0}
					</Text>
					{/* {", "} */}
				</Flex>
			</Box>
			<Box bg={syWhite} px="2" py={1} borderRadius="50px">
				<Flex>
					{/* <Icon 
						//sx={{ boxShadow: "md" }}  
						as={AiFillStar} h={6} w={6} color={'#FFD700'} /> */}
					<Img src={star} h={5} w={5} />
					<Text pl="2" fontWeight={"600"}>
						{widgetData?.stars ? widgetData?.stars : 0}
					</Text>
				</Flex>
			</Box>
		</Flex>
	);
}

export default CommonWidget;
