import store from "./store/index";
import "../src/style.css";
import { Provider } from "react-redux";
import RouteComponent from "./routes/mainRoutes";
import { ChakraProvider } from "@chakra-ui/react";
import "react-responsive-pagination/themes/classic.css";

export default function Main() {
	return (
		<Provider store={store}>
			<ChakraProvider>
				<RouteComponent />
			</ChakraProvider>
		</Provider>
	);
}
