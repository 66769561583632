// Chakra Imports
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, 
    Img, 
    Flex,
    Heading,
    InputGroup,
    List,
    ListItem,
    ListIcon,
    Icon,
    Input,
    Spinner,
    InputLeftElement,
    useToast,
    Text, Button } from "@chakra-ui/react";
import axios from "../../services/axios"
import { AiOutline, AiFillCheckCircle, AiFillExclamationCircle } from "react-icons/ai";
import { MdOutlineAlternateEmail } from "react-icons/md";

// Component Imports
import syRocket from "../../images/boarding/rocket.png";
import syLogo from "../../images/boarding/syval_logo.png";
import stripe from '../../images/promo/strip30.png';

import {
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
    syGrey,
    syBorderGrey
} from "../../constants/color.js";

import useResponsiveHeight from "../../customHook/ResponsiveHeight.js";
import { toastFunctionToaster } from "../../utils/toasterFunction";

// Api Services
import { getYouTubePreferences } from "../../services/userServices";
import { FaExclamationCircle, FaSpotify } from "react-icons/fa";

function ConnectSpotify() {
    const toast = useToast();
    const { state } = useLocation();
	const navigate = useNavigate();
    const randNum = Math.floor(Math.random() * 10) + 1;
    const globeId = getRandomInt(1, 3);
	const height = useResponsiveHeight();

    const isConnected = state?.isConnected ? state?.isConnected : false;
    const profileData = state?.profileData ? state?.profileData : [];
    const topArtistsData =  state?.topArtistsData ? state?.topArtistsData : [];
    
    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    const [preferenceId, setPreferenceId] = useState(null);
    const [preferenceList, setPreferenceList] = useState([]);
    const [loading, isloading] = useState(false);
    const [connSuccess, isConnSuccess] = useState(false);
    const [connProgress, isConnProgress] = useState(false);
    const [userEmail, setUserEmail] = useState(null);

    useEffect(() => {
        if (isConnected == true && profileData != null && profileData?.email != null){
            let localUserEmail = profileData?.email;
            fetchSpotifyPrefDetails(localUserEmail, topArtistsData);
        }
	}, [isConnected, profileData]);


	const fetchSpotifyPrefDetails = (localUserEmail, artistItem) => {
        if (localUserEmail != null){
            isloading(true);
            isConnProgress(true);
            let finalEmail = localUserEmail;
            let obj = {
                channelName: finalEmail,
                channelType: 'spotify',
                prefData: JSON.stringify({"items": [{
                    'channel_name': finalEmail,
                    'channelId': finalEmail,
                    'snippet_description': 'Spotify Channel',
                    'topArtists': artistItem ? artistItem : []
                    }]
                })
            };
            getYouTubePreferences(obj)
                .then(res => {
                    isloading(false);
                    if (res?.result) {
                        if (res?.data[0]?.status){
                            if (res?.data[0]?.uuid != null){
                                isConnSuccess(true);
                                setPreferenceList(res?.data[0]?.promo_categories);
                                setPreferenceId(res?.data[0]?.uuid);
                                // navigate("/promotion/wishlist", {
                                //     state: { 
                                //         prefId: res?.data[0]?.uuid
                                //     },
                                // });
                                startTimer(res?.data[0]?.uuid);
                                toast(toastFunctionToaster(res?.message, "success"));
                            }
                        } else {
                            toast(toastFunctionToaster(res?.message, "error"));
                        }
                    } else {
                        toast(toastFunctionToaster(res?.message, "error"));
                    }
                })
                .catch(err => {
                    isloading(false);
                    if (err) {
                        toast(toastFunctionToaster(err?.message, "error"));
                    }
                });
        }
	};

    const [timeLeft, setTimeLeft] = useState(5); // Initial timer value in seconds
    const [isRunning, setIsRunning] = useState(false);
    const intervalRef = useRef();

    const startTimer = (uuid) => {
        if (!isRunning) {
        setIsRunning(true);
        intervalRef.current = setInterval(() => {
            setTimeLeft((prevTime) => {
            if (prevTime <= 0) {
                clearInterval(intervalRef.current);
                setIsRunning(false);
                // handleClick(); 
                navigate("/promotion/wishlist", {
                    state: { 
                        prefId: uuid
                    },
                });
                return 0;
            } else {
                return prevTime - 1;
            }
            });
        }, 1000);
        }
    };

    useEffect(() => {
        return () => clearInterval(intervalRef.current);
    }, []);

	return (
		<Box
            backgroundImage={stripe}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
		>
			<Box textAlign={"center"}>
				<Heading
                    mt={20}
                    w={'100%'}
                    rounded={'lg'}
                    // bg={'gray.600'}
                    fontSize={'2xl'}
					textAlign={"center"}
					fontWeight="500"
					color={syWhite}
				>
					{/* Let's connect your spotify account and get preferences */}
				</Heading>
                {isConnected
                ?<Flex w={'100%'} mt={5} direction={'column'} color={'gray.100'}>
                    {/* {profileData?.email ? (
                    <Flex w={'100%'} direction={'column'} align={'flex-start'} mx={5}>
                        <Text>Name: {profileData?.display_name}</Text>
                        <Text>Email: {profileData?.email}</Text>
                    </Flex>
                    ) : (
                    )} */}
                    <Box w={'100%'} h={'calc(100vh - 500px)'} mt="1rem" textAlign={"center"}>
                        <Flex w={'100%'} 
                            pb={10} pl={2}
                            color={'black'}
                            rounded={'lg'}
                            direction={'column'} mt={'1px'} mx={'20px'}>
                            <Flex w={'100%'} mt={2}>
                                <Text fontWeight={600} fontSize={'lg'}>Connecting Spotify Account: {userEmail}</Text>
                            </Flex>
                            <Flex w={'100%'} mt={5}>
                                {connProgress && loading
                                ?<Spinner size="md" color={syWhite} mr={1} />
                                :(
                                    connSuccess
                                    ?<Spinner size="md" color={'green'} mr={1} />
                                    :<Spinner size="md" color={'red.300'} mr={1} />
                                )}
                                <Text>Analyzing your Spotify Account..</Text>
                            </Flex>
                            <Flex w={'100%'} mt={'10'}>
                                {connProgress && loading
                                ?<Spinner size="md" color={syWhite} mr={1} />
                                :(
                                    connSuccess
                                    ?<Spinner size="md" color={'green'} mr={1} />
                                    :<Spinner size="md" color={'red.300'} mr={1} />
                                )}
                                <Text>Designing your wishlist..</Text>
                            </Flex>
                            <Flex w={'100%'} mt={'10'}>
                                {connProgress && loading
                                ?<Spinner size="md" color={syWhite} mr={1} />
                                :(
                                    connSuccess
                                    ?<Spinner size="md" color={'green'} mr={1} />
                                    :<Spinner size="md" color={'red.300'} mr={1} />

                                )}
                                <Text>Loading your wishes..</Text>
                            </Flex>
                            <Flex w={'100%'} mt={'10'} justify={'center'}>
                                {connSuccess && preferenceId != null
                                ?<Button
                                    rounded={'lg'}
                                    w={'80%'}
                                    align={'center'}
                                    justify={'center'}
                                    bg={syGrey}
                                    >
                                    {isRunning ? `Generating Wishlist in ${timeLeft}s` : 'Error..'}
                                </Button>
                                :<Button
                                    rounded={'lg'}
                                    w={'80%'}
                                    align={'center'}
                                    justify={'center'}
                                    bg={syGrey}
                                    onClick={() => {
                                        isConnProgress(false);
                                        isConnSuccess(false);
                                        setUserEmail(null);
                                        setPreferenceList([]);
                                        setPreferenceId(null);
                                        navigate("/promotion", {
                                            state: { 
                                                prefId: null
                                            },
                                        });
                                    }}
                                    >
                                    Error... Retry
                                </Button>}
                            </Flex>
                        </Flex>
                    </Box>
                </Flex>
                :<Flex w={'100%'} color={'black'} direction={'column'}>
                    <Text>Error connecting Spotify</Text>
                    <Text>Please Retry</Text>
                    <Button
                        onClick={() => {
                            navigate("/promotion/spotify", {
                                state: { 
                                    prefId: null
                                },
                            });
                        }}
                        >
                        Retry
                    </Button>
                </Flex>}
			</Box>
		</Box>
	);
}

export default ConnectSpotify;
