// Chakra React Imports
import React, { useState, useEffect } from "react";
import {
	Box,
	Text,
	Input,
	Button,
	Spinner,
	useToast,
	Drawer,
	useDisclosure,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
} from "@chakra-ui/react";

// Component Imports
import axios from "../../../services/axios";
import { syGrey } from "../../../constants/color";
import { syThemeBgBlueColor, syWhite } from "../../../constants/color";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import { uploadRewardIcon } from "../../../services/goalServices";

function UploadRewardStoreImage({ rewardId, passSuccessFlag }) {
	const toast = useToast();
	const btnRef = React.useRef();
	const [loading, isLoading] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [uploadFile, setUploadFile] = useState(null);

	const handleFileChange = e => {
		e.preventDefault();
		if (e.target.files?.[0]) {
			setUploadFile(e.target.files[0]);
		}
	};

	useEffect(() => {
		handleSubmitNew();
	}, [uploadFile]);

	function postObjectData(s3Data, selectedFile) {
		isLoading(true);

		const { url, fields } = s3Data;
		const payload = new FormData();

		// add all the other fields
		Object.entries(fields).forEach(([key, val]) => {
			payload.append(key, val);
		});
		// payload.append("Content-Type", "image/jpeg");
		// This must be the last line in the payload otherwise 412 error may occur
		// this is the file blob, eg from <input type="file">
		payload.append("file", selectedFile);

		// No need to manually set content-type header, your browser knows what to do
		// const { data: result } = axios.post(url, payload);
		const { data: result } = axios
			.post(url, payload)
			.then(res => {
				if (res?.data?.length > 0) {
					toast(
						toastFunctionToaster(
							"File uploaded successfully",
							"success"
						)
					);
					passSuccessFlag("true");
					handleCloseData();
				} else {
					toast(
						toastFunctionToaster(
							"File uploaded successfully",
							"success"
						)
					);
					passSuccessFlag("true");
					handleCloseData();
				}
			})
			.catch(err => {
				if (err) {
					toast(
						toastFunctionToaster("Something Went Wrong", "error")
					);
				}
			})
			.finally(() => {
				isLoading(false);
				handleCloseData();
			});
	}
	const handleSubmitNew = async () => {
		if (uploadFile) {
			isLoading(true);
			let objData = {
				reward_item_uuid: rewardId,
				file_name: uploadFile.name,
				file_type: uploadFile.type,
				content_choice: "icon",
			};

			try {
				const response = await uploadRewardIcon(objData);
				if (
					response &&
					response?.data &&
					response?.data?.length > 0 &&
					response.data[0]?.signedPackage?.signedUrl
				) {
					const finalUrl = response.data[0].signedPackage.signedUrl;
					const s3UploadResult = await postObjectData(
						finalUrl,
						uploadFile
					);
				} else {
					toast(toastFunctionToaster(response?.message, "error"));
					handleCloseData();
				}
			} catch (error) {
				handleCloseData();
				toast(
					toastFunctionToaster(
						"An error occurred while uploading.",
						"error"
					)
				);
			} finally {
				isLoading(false);
			}
		}
	};

	// Todo:Handle After Modal Close
	const handleCloseData = () => {
		onClose();
	};

	return (
		<Box ml="2">
			<Button
				colorScheme="yellow"
				size="xs"
				onClick={() => {
					onOpen();
				}}
				ref={btnRef}
			>
				Upload Image
			</Button>
			{/* Add Upload Goal Drawer */}
			<Drawer
				isOpen={isOpen}
				onClose={() => {
					handleCloseData();
				}}
				size="md"
			>
				<DrawerOverlay />
				<DrawerContent bg={syThemeBgBlueColor}>
					<DrawerHeader color={syWhite} borderBottomWidth={"0.5px"}>
						Upload Store Reward Image
					</DrawerHeader>
					<DrawerCloseButton color="#d7d7d7" />
					<DrawerBody>
						<Box h="200px" mb="2" cursor={"pointer"} bg="">
							<Box
								borderWidth={"1px"}
								borderStyle={"dashed"}
								borderColor={syGrey}
								borderRadius={"10px"}
								position={"relative"}
								bg="#1c181e"
								h="100%"
								cursor={"pointer"}
							>
								<Input
									p="0px"
									type="file"
									onChange={handleFileChange}
									accept=".jpg,.jpeg,.png"
									position="relative"
									opacity="0"
									h="100%"
									zIndex={"1"}
								></Input>
								<Button
									cursor={"pointer"}
									mt={2}
									size={"sm"}
									color={"gray.600"}
									onClick={handleSubmitNew}
									position="absolute"
									top="50%"
									left="50%"
									transform="translate(-50%, -50%)"
									colorScheme="green"
									textColor={"#fff"}
									isDisabled={loading ? true : false}
									isLoading={loading ? true : false}
								>
									Upload{" "}
									<Text
										as="span"
										mx="1"
										textTransform={"capitalize"}
									></Text>
								</Button>
							</Box>
						</Box>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Box>
	);
}

export default UploadRewardStoreImage;
