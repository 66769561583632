// Chakra Imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Img, Text, Button } from "@chakra-ui/react";

// Component Imports
import syRocket from "../../../images/boarding/rocket.png";
import syLogo from "../../../images/boarding/syval_logo.png";
import {
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syWhite,
} from "../../../constants/color.js";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight.js";

function Boarding() {
	const navigate = useNavigate();
	const height = useResponsiveHeight();
	return (
		<Box
			bg={syThemeBgBlueColor}
			p="30px"
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
			h={height}
			mx="auto"
		>
			<Box textAlign={"center"}>
				<Box mt="4rem">
					<Img mx="auto" src={syLogo} />
				</Box>
				<Text
					mt="4rem"
					textAlign={"center"}
					fontSize="14px"
					fontWeight="500"
					color={syWhite}
				>
					Build your dream life :)
				</Text>
				<Box mt="6rem">
					<Img src={syRocket} mx="auto" />
				</Box>
				<Box mt="9rem" textAlign={"center"}>
					<Button
						w="80%"
						bg={syThemeBgYellowColor}
						color={syThemeBgBlueColor}
						_hover={{ bg: syThemeBgYellowColor }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							navigate("/boarding");
						}}
					>
						Get Started
					</Button>
					<Text
						mt="1rem"
						textAlign={"center"}
						color={syWhite}
						textDecoration={"underline"}
						onClick={() => {
							navigate("/login");
						}}
					>
						Login
					</Text>
				</Box>
			</Box>
		</Box>
	);
}

export default Boarding;
