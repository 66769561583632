import React, { useEffect, useState } from "react";
import {
	Img,
	Box,
	Flex,
	Text,
	Input,
	Switch,
	Spinner,
	useToast,
} from "@chakra-ui/react";

// Component Imports
import dummyImage from "../../../images/common/dummy_profile.png";
import { getUserName } from "../../../utils/localStorageIndex";
import ResponsivePagination from "react-responsive-pagination";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syGrey,
	syWhite,
	syThemeBgYellowColor,
} from "../../../constants/color";

// Api Imports
import {
	handleUserStatus,
	fetchUserList,
} from "../../../services/sharedServices";

function UsersList() {
	const toast = useToast();
	const [loading, isloading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchTerm, setSearchTerm] = useState("");
	const [userEnable, setUserEnable] = useState(true);
	const [switchDisableFlag, setSwitchDisableFlag] = useState(false);

	useEffect(() => {
		fetchUserDetails();
	}, [userEnable, currentPage]); // Update fetch when page changes

	const fetchUserDetails = () => {
		isloading(true);
		let objBody = {
			enabled: userEnable,
			filter_key: getUserName(),
			filter_value: "string",
			records_per_page: 10,
			page_id: currentPage,
		};
		fetchUserList(objBody)
			.then(res => {
				isloading(false);
				if (res.data.length > 0) {
					setTotalPages(res.data[0].total_pages);
					setTableData(res.data[0].records);
				} else {
					setTotalPages(0);
					setTableData([]);
				}
			})
			.catch(err => {
				isloading(false);
			});
	};

	const handleSearch = e => {
		const value = e.target.value.toLowerCase();
		setSearchTerm(value);
	};

	const filteredData = tableData.filter(data => {
		// Implement filtering logic here
		return (
			data?.first_name?.toLowerCase().includes(searchTerm) ||
			data?.last_name?.toLowerCase().includes(searchTerm) ||
			data?.phone_number?.includes(searchTerm) ||
			data?.email?.toLowerCase().includes(searchTerm)
		);
	});

	// Todo:Update User Status
	const updateUserStatus = data => {
		setSwitchDisableFlag(true);
		let obj = {
			user_uuid: data?.uuid,
		};
		handleUserStatus(obj)
			.then(res => {
				setSwitchDisableFlag(false);
				fetchUserDetails();
				if (res?.result) {
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				setSwitchDisableFlag(false);
				toast(
					toastFunctionToaster("Something Went Wrong!!!!", "error")
				);
			});
	};

	function renderUserDetails() {
		if (filteredData && filteredData.length > 0) {
			var usersResult = filteredData.map((data, index) => (
				<Flex
					p="15px"
					pl="0px"
					key={data?.uuid}
					color={syWhite}
					borderTopWidth={index === 0 ? "0.5px" : "0pc"}
					borderTopColor={syGrey}
					borderBottomWidth={"0.5px"}
					borderBottomColor={syGrey}
					cursor={"pointer"}
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Flex>
						<Flex
							alignItems={"center"}
							flexWrap={"wrap"}
							borderLeftWidth={"20px"}
							pl={5}
							borderLeftColor={
								data?.disabled ? "red.300" : "green.200"
							}
						>
							<Img
								h="60px"
								w="60px"
								mx="auto"
								objectFit={"cover"}
								bg="#fff"
								src={
									data?.photo_url
										? data?.photo_url
										: dummyImage
								}
								borderRadius={"50px"}
							/>
							<Box ml="6">
								<Text mb="5px" fontSize={"14px"} w="100%">
									First Name :{" "}
									<Text as="span" ml="55px">
										{data?.first_name
											? data?.first_name
											: "Not Available"}
									</Text>
								</Text>
								<Text mb="5px" fontSize={"14px"} w="100%">
									Last Name :{" "}
									<Text as="span" ml="55px">
										{data?.last_name
											? data?.last_name
											: "Not Available"}
									</Text>
								</Text>
								<Text mb="5px" fontSize={"14px"} w="100%">
									Phone No :{" "}
									<Text as="span" ml="60px">
										{data?.phone_number
											? data?.phone_number
											: "Not Available"}
									</Text>
								</Text>
								<Text mb="5px" fontSize={"14px"} w="100%">
									Email Address :{" "}
									<Text as="span" ml="28px">
										{data?.email
											? data?.email
											: "Not Available"}
									</Text>
								</Text>
							</Box>
						</Flex>
					</Flex>

					<Box>
						{switchDisableFlag ? (
							<Spinner my="2" mt="2" size="sm" />
						) : (
							<Switch
								ml="2"
								colorScheme={!data?.disabled ? "green" : "red"}
								isChecked={
									data?.disabled
										? data?.disabled
										: !data?.disabled
								}
								isDisabled={switchDisableFlag}
								onChange={() => {
									updateUserStatus(data);
								}}
							/>
						)}
					</Box>
				</Flex>
			));
			return usersResult;
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text mb="5px" fontSize={16} color={syThemeBgYellowColor}>
						{"No Users Found!!"}
					</Text>
				</Box>
			);
		}
	}

	return (
		<>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" color={syWhite} />
				</Flex>
			) : (
				<Box>
					<Box p="15px">
						<Flex alignItems={"center"}>
							<Box
								as="span"
								flex="1"
								textAlign="left"
								color={syWhite}
								fontWeight={"600"}
								fontSize="20px"
							>
								Users List
							</Box>
							<Flex alignItems={"center"}>
								<Input
									type="search"
									placeholder="Search User"
									value={searchTerm}
									onChange={handleSearch} // Handle input change
									color={syWhite}
								/>
								<Switch
									colorScheme="green"
									ml="2"
									isChecked={userEnable}
									isDisabled={switchDisableFlag}
									onChange={() => {
										setUserEnable(!userEnable);
									}}
								/>
							</Flex>
						</Flex>

						<Box
							borderRadius={"20px"}
							mt="3"
							p="15px"
							h="calc(100vh - 20vh)"
							overflow="auto"
							css={{
								"&::-webkit-scrollbar": {
									width: "4px",
								},
								"&::-webkit-scrollbar-track": {
									width: "6px",
								},
								"&::-webkit-scrollbar-thumb": {
									background: { syGrey },
									borderRadius: "24px",
								},
							}}
						>
							{renderUserDetails()}
						</Box>
						{totalPages > 1 && (
							<Box>
								<ResponsivePagination
									current={currentPage}
									total={totalPages}
									onPageChange={pageNumber => {
										setCurrentPage(pageNumber);
									}}
								/>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</>
	);
}

export default UsersList;
