import React from "react";
import { Box, Heading, Stack, Text } from "@chakra-ui/react";

export default function Unauthorized() {
	return (
		<Box overflow="auto">
			<Stack
				h="80vh"
				direction="column"
				align="center"
				justify="center"
				spacing={3}
				p={4}
			>
				<Heading fontSize={"9xl"}>401</Heading>
				<Heading fontSize={"2xl"}>Unauthorized</Heading>
				<Text textAlign={"center"}>
					You don't have the permission to access the resource
					requested!
				</Text>
			</Stack>
		</Box>
	);
}
