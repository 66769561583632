import React from "react";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { syThemeBgGreenColor } from "../../constants/color";
import { Box } from "@chakra-ui/react";

function CalenderComponent({ goalDetails }) {
	const startDate = moment().format("YYYY-MM-DD");
	const endDate = moment(goalDetails?.goal_completion_datetime, "MM/DD/YYYY").format("YYYY-MM-DD");
	const goal_duration_unit = goalDetails?.goal_duration_unit;

	// Function to get all dates for daily
	const getDailyOccurrences = (start, end) => {
		const occurrences = [];
		let current = moment(start);

		while (current.isSameOrBefore(end, "day")) {
			occurrences.push(current.clone());
			current.add(1, "day");
		}

		return occurrences;
	};

	// Function to get all weekly occurrences (same weekday)
	const getWeeklyOccurrences = (start, end) => {
		const occurrences = [];
		let current = moment(start);

		while (current.isSameOrBefore(end, "day")) {
			occurrences.push(current.clone());
			current.add(1, "week"); // Increment by 1 week
		}

		return occurrences;
	};

	// Function to get all twice-a-week occurrences (15-day interval)
	const getTwiceWeeklyOccurrences = (start, end) => {
		const occurrences = [];
		let current = moment(start);

		while (current.isSameOrBefore(end, "day")) {
			// Add the same day (start date)
			occurrences.push(current.clone());

			// Add 3 days from the current date
			const threeDaysLater = current.clone().add(3, "days");
			if (threeDaysLater.isSameOrBefore(end, "day")) {
				occurrences.push(threeDaysLater);
			}

			// Move to the same day next week
			current = current.clone().add(1, "week");
		}

		return occurrences;
	};

	// Function to get all monthly occurrences (same day each month)
	const getMonthlyOccurrences = (start, end) => {
		const occurrences = [];
		const startDay = moment(start).date(); // Get the same day to highlight
		let current = moment(start).startOf("month");

		while (current.isSameOrBefore(end, "day")) {
			const occurrence = current.clone().date(startDay);
			if (occurrence.isSameOrBefore(end, "day")) {
				occurrences.push(occurrence.clone());
			}
			current.add(1, "month");
		}

		return occurrences;
	};

	// Function to get twice-a-month occurrences based on a 15-day interval
	const getTwiceMonthlyOccurrences = (start, end) => {
		const occurrences = [];
		let current = moment(start);

		while (current.isSameOrBefore(end, "day")) {
			// Push the start date of the month
			occurrences.push(current.clone());

			// Add 15 days for the next occurrence in the same month
			const midMonthDate = current.clone().add(15, "days");
			if (midMonthDate.isSameOrBefore(end, "day")) {
				occurrences.push(midMonthDate);
			}

			// Move to the same date next month
			current = current.clone().add(1, "month").startOf("month").date(current.date());
		}

		return occurrences;
	};

	// Calculate occurrences based on the goal duration unit
	let occurrences = [];
	if (goal_duration_unit === "daily") {
		occurrences = getDailyOccurrences(startDate, endDate);
	} else if (goal_duration_unit === "weekly") {
		occurrences = getWeeklyOccurrences(startDate, endDate);
	} else if (goal_duration_unit === "twiceAWeek") {
		occurrences = getTwiceWeeklyOccurrences(startDate, endDate);
	} else if (goal_duration_unit === "monthly") {
		occurrences = getMonthlyOccurrences(startDate, endDate);
	} else if (goal_duration_unit === "twiceAMonth") {
		occurrences = getTwiceMonthlyOccurrences(startDate, endDate);
	}

	// Check if a date is in the occurrences
	const isHighlightedDate = date => {
		return occurrences.some(occurrence => moment(date).isSame(occurrence, "day"));
	};

	// Tile content for calendar
	const tileContent = ({ date, view }) => {
		if (view === "month" && isHighlightedDate(date)) {
			return <TileHighlight />;
		}
		return null;
	};

	// Reusable TileHighlight Component
	const TileHighlight = () => (
		<div
			style={{
				backgroundColor: syThemeBgGreenColor,
				borderRight: "1px solid #deff71",
				height: "100%",
				width: "100%",
				position: "absolute",
				top: 0,
				left: 0,
				opacity: 0.5,
			}}
		/>
	);

	return (
		<Box>
			<Calendar tileContent={tileContent} minDate={new Date()} />
		</Box>
	);
}

export default CalenderComponent;
