import i18n from "i18next";
import axios from "axios";
import { getAuthToken, clearLocalStorage } from "../utils/localStorageIndex";

const baseURLs = {
	local: "http://localhost:7001",
	server: "https://mysyval.com/",
	dev: "http://ec2-54-241-216-205.us-west-1.compute.amazonaws.com:7001",
};

const currentEnvironment = baseURLs?.server; // default to 'local' if not set
console.log(currentEnvironment);

// Set the baseURL based on the current environment
const baseURL = baseURLs.server;

const instance = axios.create({
	baseURL: baseURL,
	// baseURL: 'http://localhost:7001',	
	headers: {
		"Access-Control-Allow-Origin": "*",
		"Accept-Language": i18n.language,
	},
});

instance.interceptors.request.use(
	async requestConfig => {
		if (requestConfig.url.startsWith("https://")) {
			return requestConfig;
		}
		let token = await getAuthToken(); // Retrieve the token
		if (token && token.trim().length > 0) {
			requestConfig.headers["Authorization"] = `Bearer ${token}`; // Use the token in the request headers
		}
		return requestConfig;
	},
	error => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	response => {
		return response.data;
	},
	error => {
		if (error.response.status === 401) {
			clearLocalStorage();
			window.location = "/";
		} else if (error.response.status === 404) {
			window.location = "/";
		}
		throw error;
	}
);

export default instance;
