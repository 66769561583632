import moment from "moment";
import React, { useEffect, useState } from "react";
import {
	Box,
	Flex,
	Text,
	Image,
	Icon,
	Spinner,
	useToast,
} from "@chakra-ui/react";
import {
	syWhite,
	syGrey,
	syThemeLabelColor,
	syThemeBgYellowColor,
	syBlack,
	syThemeBgGreenColor,
	syThemeBgBlueColor,
} from "../../../constants/color";
import { fetchRewardListTransaction } from "../../../services/goalServices";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import dummyImage from "../../../images/common/dummy_profile.png";
import { AiFillStar } from "react-icons/ai";

function RewardUserList({ userId }) {
	const toast = useToast();
	const [transactionList, setTransactionList] = useState([]);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (userId) {
			fetchTransactionDetails();
		}
	}, [userId]);

	const fetchTransactionDetails = () => {
		setLoading(true);
		let obj = {
			user_uuid: userId,
			filter_key: "string",
			filter_value: "string",
		};
		fetchRewardListTransaction(obj)
			.then(res => {
				setLoading(false);

				if (res?.result && res?.data?.length) {
					setTransactionList(res?.data);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setTransactionList([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				setLoading(false);

				console.log(err);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	const renderTransactionList = () => {
		if (transactionList && transactionList?.length) {
			return transactionList?.map((data, index) => (
				<Flex
					bg={syBlack}
					w="100%"
					mb="2"
					p="10px"
					alignItems={"center"}
					borderRadius={"10px"}
				>
					<Flex w="70%">
						<Box h="50px" w="50px">
							<Image
								h="100%"
								w="100%"
								borderRadius={"50px"}
								src={
									data?.reward_transaction_image_url
										? data?.reward_transaction_image_url
										: dummyImage
								}
								bg={syThemeLabelColor}
							/>
						</Box>
						<Box ml="3">
							{/*Todo:Needs to be change api doesnot return the data */}
							<Text>Play Station</Text>
							<Text>Demo</Text>
							<Text>
								{data?.datetime_added
									? moment(data?.datetime_added)?.format(
											"YYYY-MM-DD"
									  )
									: "-"}
							</Text>
						</Box>{" "}
					</Flex>
					<Flex alignItems={"center"}>
						<Box
							bg={syThemeBgBlueColor}
							borderRadius={"5px"}
							p="5px"
						>
							{data?.reward_item_class
								? data?.reward_item_class
								: "-"}
						</Box>
						<Box
							bg={syThemeBgBlueColor}
							borderRadius={"5px"}
							p="5px"
							ml="2"
						>
							{data?.reward_item_group
								? data?.reward_item_group
								: "-"}
						</Box>
						<Flex ml="5" alignItems={"center"}>
							<Icon
								fontSize={"24px"}
								fontWeight="600"
								as={AiFillStar}
								color={syThemeBgYellowColor}
							/>
							<Text
								as="span"
								fontSize={"18px"}
								fontWeight={"600"}
								ml="1"
							>
								{data?.reward_transaction_stars
									? data?.reward_transaction_stars
									: "-"}
							</Text>
						</Flex>
					</Flex>
				</Flex>
			));
		} else {
			return (
				<Text
					p="10px"
					color={syBlack}
					borderRadius={"5px"}
					fontWeight={"bold"}
					bg={syThemeBgYellowColor}
				>
					No Transactions Found
				</Text>
			);
		}
	};

	return (
		<Box color={syWhite}>
			<Text fontWeight={"bold"}>Reward Transactions</Text>
			{loading ? (
				<Flex alignItems="center" justifyContent="center" h="50vh">
					<Spinner size="xl" color={syWhite} />
				</Flex>
			) : (
				<Box
					mt="3"
					h="calc(100vh - 30vh)"
					overflow="auto"
					css={{
						"&::-webkit-scrollbar": {
							width: "4px",
						},
						"&::-webkit-scrollbar-track": {
							width: "6px",
						},
						"&::-webkit-scrollbar-thumb": {
							background: { syGrey },
							borderRadius: "24px",
						},
					}}
				>
					{renderTransactionList()}
				</Box>
			)}
		</Box>
	);
}

export default RewardUserList;
