// Chakra Imports
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Img, Box, Flex, Text, Button, Heading } from "@chakra-ui/react";

// Component Imports
import CommonHeader from "../../common/CommonHeader";
import CommonWidget from "../../common/CommonWidget";
import FooterProfile from "../../common/FooterProfile";
import lockImg from "../../../images/genz/goal/lock.png";
import standImg from "../../../images/genz/goal/stand.png";
import lightImg from "../../../images/genz/goal/lighting.png";
import { syBlack, syWhite, syGrey, syThemeBgBlueColor, syThemeBgYellowColor, syThemeBgGreenColor } from "../../../constants/color";
import CalenderComponent from "../../common/CalenderComponent";

function GoalSummary() {
	const navigate = useNavigate();
	const { state } = useLocation();
	const createdGoalDetails = state?.data;
	const selectedGoalDetails = state?.selectedData;
	const goalPlanningId = state?.data ? state?.data?.goal_planning_uuid : null;
	const goalDataName = createdGoalDetails?.goal_name;
	const goalHastag = createdGoalDetails?.goal_tags?.length ? createdGoalDetails?.goal_tags.split(",") : [];

	// Todo:Render Hashtag
	const renderHashTag = () => {
		if (goalHastag?.length) {
			return goalHastag.map((data, index) => {
				return (
					<Text key={data + index} bg={syBlack} mr="3" mt="3" color={syWhite} borderRadius={"10px"} textAlign={"center"} p="1rem" fontWeight={"bold"}>
						{data}
					</Text>
				);
			});
		}
	};

	return (
		<Box
			bg={syThemeBgYellowColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box bg={syBlack} my="1rem" borderRadius={"10px"}>
					<Heading color={syWhite} fontWeight={"900"} textAlign={"center"} py="1.5rem" fontSize={"24px"}>
						{goalDataName}
					</Heading>
				</Box>

				{/* <Flex
					my="1rem"
					borderRadius={"10px"}
					flexWrap={"wrap"}
					mb="2rem"
				>
					{renderHashTag()}
				</Flex> */}
				<Box px="20px" py="30px" bg={syWhite} borderRadius={"8px"} textAlign={"center"}>
					<Heading fontSize={"24px"} fontWeight={"700"} color={syBlack}>
						$ {createdGoalDetails?.goal_target_amount}
					</Heading>
					<Heading fontSize={"18px"} fontWeight={"700"} mt="1rem" color={syBlack}>
						in
					</Heading>
					<Heading fontSize={"24px"} fontWeight={"700"} mt="1rem" color={syBlack}>
						{createdGoalDetails?.goal_duration_days} days
					</Heading>
				</Box>
				<Box mt="0.5rem" borderRadius={"8px"} textAlign={"center"}>
					<Heading textAlign={"center"} fontSize={"20px"} fontWeight={"700"} color={syBlack}>
						=
					</Heading>
				</Box>
				<Box mt="0.5rem" px="20px" py="30px" bg={syBlack} borderRadius={"8px"} textAlign={"center"}>
					<Heading textAlign={"center"} fontSize={"20px"} fontWeight={"700"} color={syThemeBgYellowColor}>
						$ {createdGoalDetails?.goal_event_amount}
						<Text as="span" fontSize={"16px"} mx="2" color={syWhite}>
							Every
						</Text>{" "}
						{createdGoalDetails?.goal_duration_unit === "daily"
							? "day"
							: createdGoalDetails?.goal_duration_unit.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
									return str.toUpperCase();
							  })}
					</Heading>
					{/* <Heading
						textAlign={"center"}
						fontSize={"16px"}
						fontWeight={"700"}
						color={syWhite}
						mt="2rem"
					>
						Edit
					</Heading> */}
				</Box>

				<Box mb="1rem" mt={5} bg={syWhite}>
					<Box>
						<Img mx="auto" src={lightImg}></Img>
					</Box>
					{selectedGoalDetails?.goal_plan?.[0]?.goal_reward_icon ? (
						<Box position={"relative"} pb="2rem" w="40%" mx="auto">
							<Img w="100%" borderRadius={"10px"} borderWidth="1px" borderStyle={"solid"} p="2" borderColor={syThemeBgBlueColor} src={selectedGoalDetails?.goal_plan?.[0]?.goal_reward_icon} />
							<Box position={"absolute"} top="25px" left={"30%"}>
								<Img src={lockImg}></Img>
							</Box>
						</Box>
					) : (
						<Box position={"relative"} pb="2rem">
							<Img mx="auto" src={standImg}></Img>
							<Box position={"absolute"} top="25px" left={"40%"}>
								<Img src={lockImg}></Img>
							</Box>
						</Box>
					)}
				</Box>
				<CalenderComponent goalDetails={createdGoalDetails} />
				<Box textAlign={"center"} mt="2rem" mb="4rem">
					{goalPlanningId ? (
						<Button
							borderRadius={"50px"}
							size="lg"
							w="80%"
							bg={syThemeBgBlueColor}
							color={syBlack}
							boxShadow={"xs"}
							onClick={() => {
								navigate("/genz/goal-event", {
									state: {
										goalPlanningId: goalPlanningId,
										data: createdGoalDetails,
									},
								});
							}}
						>
							Let's do it
						</Button>
					) : null}
				</Box>
			</Box>
			<FooterProfile position={"fixed"} bottom={"0px"} borderRadius="10px" />
		</Box>
	);
}

export default GoalSummary;
