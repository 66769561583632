// Chakra - React Imports
import moment from "moment";
import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { Box, Text, Flex, Button, Spinner, useToast } from "@chakra-ui/react";

// Component Imports
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import {
	syThemeBgYellowColor,
	syWhite,
	syBlack,
	syThemeLabelColor,
	syBgGrey,
} from "../../../constants/color";

// Api Services
import {
	fetchGoalPlanEvent,
	fetchUserGoalPlanList,
} from "../../../services/sharedServices";

function GoalBasedUsers({ userId }) {
	const toast = useToast();
	const selectedUserId = userId;
	const [status, setStatus] = useState("active");
	const [loading, isLoading] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [storeGoalPlanList, setStoreGoalPlanList] = useState([]);
	const [selectedGoalPlan, setSelectedGoalPlan] = useState("");
	const [goalPlanData, setGoalPlanData] = useState([]);
	const [goalPlanLoading, setGoalPlanLoading] = useState(false);

	// Fetch goals when userId, status, or page changes
	useEffect(() => {
		if (selectedUserId && status) {
			fetchGoalBasedOnUsers();
			setSelectedGoalPlan("");
		}
	}, [status, selectedUserId, currentPage]);

	const fetchGoalBasedOnUsers = () => {
		isLoading(true);
		let obj = {
			filter_key: "uuid",
			filter_value: selectedUserId,
			by_status: status,
			records_per_page: 10,
			page_id: currentPage,
		};
		fetchUserGoalPlanList(obj)
			.then(res => {
				isLoading(false);
				setTotalPages(res?.data[0]?.total_pages || 0);
				if (res?.data[0]?.total_records) {
					setStoreGoalPlanList(res?.data[0]?.records);
				} else {
					setStoreGoalPlanList([]);
				}
			})
			.catch(err => {
				isLoading(false);
			});
	};

	// Render the list of user goals
	function renderGoalPlanList() {
		if (storeGoalPlanList && storeGoalPlanList.length > 0) {
			return storeGoalPlanList.map((data, index) => (
				<Flex
					key={index}
					justifyContent={"space-between"}
					color={syWhite}
					p="15px"
					borderRadius={"5px"}
					mb="3"
					alignItems={"center"}
					bg={syBlack}
					cursor={"pointer"}
					flexWrap={"wrap"}
				>
					<Flex w="50%">
						<Text>Goal Plan Name :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_name}
						</Text>
					</Flex>
					<Flex w="50%">
						<Text>Amount for each save event :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_target_amount
								? data?.goal_target_amount
								: "-"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Target Saving :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_target_amount
								? `$ ${data?.goal_target_amount}`
								: "-"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Started :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_started ? "Yes" : "No"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Saving Frequently :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_duration_unit
								? data?.goal_duration_unit
								: "-"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Active :-</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_active ? "Yes" : "No"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Days to Complete Savings :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_completion_datetime
								? data?.goal_completion_datetime
								: "-"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Completed :</Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_completed ? "Yes" : "No"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Project Completion Date : </Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_completion_datetime
								? data?.goal_completion_datetime
								: "-"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2">
						<Text>Cancelled : </Text>
						<Text ml="2" fontWeight={"600"}>
							{data?.goal_canceled ? "Yes" : "No"}
						</Text>
					</Flex>
					<Flex w="50%" mt="2"></Flex>
					<Flex w="50%" mt="2" textAlign={"right"}>
						{selectedGoalPlan === data?.goal_planning_uuid ? (
							<Button
								size="xs"
								colorScheme={"red"}
								isDisabled={goalPlanLoading ? true : false}
								isLoading={goalPlanLoading ? true : false}
								onClick={() => {
									setSelectedGoalPlan("");
								}}
							>
								Click here to close goal events
							</Button>
						) : (
							<Button
								size="xs"
								colorScheme={"green"}
								onClick={() => {
									setSelectedGoalPlan(
										data?.goal_planning_uuid
									);
									fetchGoalPlanDetails(
										data?.goal_planning_uuid
									);
								}}
							>
								Click here to see goal events
							</Button>
						)}
					</Flex>
					<Box w="100%">
						{selectedGoalPlan === data?.goal_planning_uuid ? (
							goalPlanLoading ? (
								<Flex
									alignItems="center"
									justifyContent="center"
									w="100%"
									borderWidth={"1px"}
									p="10px"
									mt="4"
									borderRadius={"5px"}
									borderColor={syThemeLabelColor}
								>
									<Spinner size="sm" color={syWhite} />
								</Flex>
							) : (
								<>
									{goalPlanData?.length ? (
										<Box
											p="10px"
											mt="4"
											bg={syBgGrey}
											borderRadius={"5px"}
										>
											<Flex
												alignItems={"center"}
												justifyContent={"space-between"}
											>
												<Text
													w="24%"
													color={"green.500"}
													fontWeight={"600"}
												>
													Date
												</Text>
												<Text
													w="24%"
													color={"green.500"}
													fontWeight={"600"}
												>
													Amount Saved
												</Text>
												<Text
													w="24%"
													color={"green.500"}
													fontWeight={"600"}
												>
													Amount Left
												</Text>
												<Text
													w="24%"
													color={"green.500"}
													fontWeight={"600"}
												>
													Event Type
												</Text>
											</Flex>
											{renderGoalEventList()}
										</Box>
									) : (
										<Text
											borderWidth={"1px"}
											p="10px"
											mt="4"
											fontWeight={"600"}
											borderRadius={"5px"}
											color={"red.300"}
											borderColor={"red.300"}
										>
											{" "}
											No Goal Events Found
										</Text>
									)}
								</>
							)
						) : null}
					</Box>
				</Flex>
			));
		} else {
			return (
				<Box bg="#1c181e" m="1" p="10px" borderRadius={"10px"}>
					<Text
						mb="5px"
						fontSize={"16px"}
						fontWeight={"bold"}
						color={syThemeBgYellowColor}
					>
						{"No Goal Plan Found!!"}
					</Text>
				</Box>
			);
		}
	}

	// Fetch Goal Plan Based On GoalPlanning Id
	const fetchGoalPlanDetails = goalPlanId => {
		setGoalPlanLoading(true);
		let obj = {
			goal_planning_uuid: goalPlanId,
			user_uuid: selectedUserId,
		};
		fetchGoalPlanEvent(obj)
			.then(res => {
				setGoalPlanLoading(false);

				if (res?.result && res?.data?.length) {
					setGoalPlanData(res?.data);
					toast(toastFunctionToaster(res?.message, "success"));
				} else {
					setGoalPlanData([]);
					toast(toastFunctionToaster(res?.message, "error"));
				}
			})
			.catch(err => {
				setGoalPlanLoading(false);
				setGoalPlanData([]);
				if (err) {
					toast(toastFunctionToaster(err?.message, "error"));
				}
			});
	};

	// Render Goal Plan Details
	const renderGoalEventList = () => {
		return goalPlanData?.map((data, index) => {
			return (
				<Flex
					w="100%"
					my="2"
					alignItems={"center"}
					justifyContent={"space-between"}
				>
					<Text color="#fff" w="24%" fontWeight={"600"}>
						{data?.datetime_created
							? moment(data?.datetime_created)?.format(
									"DD/MM/YYYY"
							  )
							: "-"}
					</Text>
					<Text color="#fff" w="24%" fontWeight={"600"}>
						{data?.goal_amount ? data?.goal_amount : "-"}
					</Text>
					<Text color="#fff" w="24%" fontWeight={"600"}>
						{data?.remaining_amount ? data?.remaining_amount : "-"}
					</Text>
					<Text color="#fff" w="24%" fontWeight={"600"}>
						{data?.event_source_details
							? data?.event_source_details
							: "-"}
					</Text>
				</Flex>
			);
		});
	};

	return (
		<>
			{selectedUserId ? (
				<Box>
					<Flex
						justifyContent={"space-between"}
						mb="2"
						alignItems={"center"}
					>
						<Text color={syWhite} fontWeight={"600"}>
							Total Goal Plans :{" "}
							{storeGoalPlanList?.length || "-"}
						</Text>
						<Flex>
							<Button
								size="xs"
								mr="2"
								onClick={() => {
									setStatus("active");
									setCurrentPage(0);
								}}
								colorScheme={
									status === "active" ? "yellow" : null
								}
							>
								Active
							</Button>
							<Button
								mr="2"
								size="xs"
								onClick={() => {
									setStatus("notstarted");
									setCurrentPage(0);
								}}
								colorScheme={
									status === "notstarted" ? "red" : null
								}
							>
								Not Started
							</Button>
							<Button
								size="xs"
								mr="2"
								onClick={() => {
									setStatus("complete");
									setCurrentPage(0);
								}}
								colorScheme={
									status === "complete" ? "green" : null
								}
							>
								Completed
							</Button>
							<Button
								size="xs"
								mr="2"
								onClick={() => {
									setStatus("canceled");
									setCurrentPage(0);
								}}
								colorScheme={
									status === "canceled" ? "purple" : null
								}
							>
								Canceled
							</Button>

						</Flex>
					</Flex>
					{loading ? (
						<Flex
							alignItems="center"
							justifyContent="center"
							h="50vh"
						>
							<Spinner size="xl" color={syWhite} />
						</Flex>
					) : (
						<Box
							h="calc(100vh - 18vh)"
							overflow="auto"
							css={{
								"&::-webkit-scrollbar": {
									width: "4px",
								},
								"&::-webkit-scrollbar-track": {
									width: "6px",
								},
								"&::-webkit-scrollbar-thumb": {
									background: "#fff",
									borderRadius: "24px",
								},
							}}
						>
							{renderGoalPlanList()}
						</Box>
					)}
					{totalPages > 1 && (
						<Box>
							<ResponsivePagination
								current={currentPage}
								total={totalPages}
								onPageChange={pageNumber => {
									setCurrentPage(pageNumber);
								}}
							/>
						</Box>
					)}
				</Box>
			) : (
				<Text color={"#fff"} fontWeight={"600"}>
					Please Select User
				</Text>
			)}
		</>
	);
}

export default GoalBasedUsers;
