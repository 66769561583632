import * as actionType from '../actionTypes';

const initialState = {
  wizardStepperLevel: 1,
  job_uuId: '',
  jobWizardStatusLevel: 1,
};

const wizardHeader = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_BUSINESS_WIZARD_STEPPER: {
      return {
        ...state,
        wizardStepperLevel: action.value,
      };
    }
    case actionType.SET_JOB_WIZARD_STATUS: {
      return {
        ...state,
        jobWizardStatusLevel: action.value,
      };
    }
    case actionType.SET_JOB_UUID: {
      return {
        ...state,
        job_uuId: action.value,
      };
    }

    default:
      return state;
  }
};
export default wizardHeader;
