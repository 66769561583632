// Chakra Imports
import React from "react";
import { useNavigate } from "react-router-dom";
import { Img, Box, Button, Heading } from "@chakra-ui/react";

// Component Imports
import backIcon from "../../../images/common/back.png";
import goalLeague from "../../../images/boarding/goal_league.png";
import boarding02 from "../../../images/boarding/boarding02.png";

import goalRating from "../../../images/boarding/goal_rating.png";
import goalPaginationThree from "../../../images/boarding/goal_pagination_three.png";
import {
	syBlack,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
} from "../../../constants/color";
import useResponsiveHeight from "../../../customHook/ResponsiveHeight";

function GoalTwo() {
	const navigate = useNavigate();
	const height = useResponsiveHeight();
	return (
		<Box
			p="30px"
			mx="auto"
			h={height}
			bg={syThemeBgYellowColor}
			w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}
		>
			<Box>
				<Img
					src={backIcon}
					onClick={() => {
						navigate("/rewards");
					}}
				/>
			</Box>
			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"700"} color={syBlack}>
					Hit Your Goals
				</Heading>
				<Heading fontWeight={"700"} color={syBlack}>
					Unlock Virtual Rewards
				</Heading>

				<Box pt="3rem" position="relative">
					<Img src={boarding02} mx="auto" />
				</Box>
				{/* <Box position="relative" ml="-1.5rem" mt="-4rem">
					<Img src={goalRating} />
				</Box> */}
				<Box pt="1rem">
					<Img mx="auto" src={goalPaginationThree} />
				</Box>
				<Box pt="2rem" textAlign={"center"}>
					<Button
						w="80%"
						bg={syThemeBgBlueColor}
						color={syThemeBgYellowColor}
						_hover={{ bg: syThemeBgBlueColor }}
						borderRadius={"50px"}
						size="lg"
						onClick={() => {
							navigate("/register");
						}}
					>
						Let’s do this!
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default GoalTwo;
