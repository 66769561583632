// Chakra Imports
import React, { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Img, Box, Flex, Text, Input, Button, Heading, Checkbox, useToast, InputGroup, InputRightElement } from "@chakra-ui/react";

// Component Imports
import backIcon from "../../../images/common/back.png";
import divider from "../../../images/common/divider.png";
import googleIcon from "../../../images/common/googleIcon.png";
import facebookIcon from "../../../images/common/facebookIcon.png";
import { toastFunctionToaster } from "../../../utils/toasterFunction";
import { syBlack, syWhite, syThemeBgBlueColor, syThemeBgYellowColor, syThemeLabelColor } from "../../../constants/color";

// Api Imports
import { registerSystem } from "../../../services/sharedServices";

function Login() {
	const toast = useToast();
	const navigate = useNavigate();
	const [error, setError] = useState({});
	const [show, setShow] = useState(false);
	const [show1, setShow1] = useState(false);
	const [loading, isloading] = useState(false);
	const [signUpflag, setSignUpFlag] = useState(false);
	const [verifyPassword, setVerifyPassword] = useState("");
	const [verifyPassErrMsg, setVerifiyPassErrMsg] = useState("");
	const [registerUserObj, setRegisterUserObj] = useState({
		first_name: "",
		last_name: "",
		email: "",
		password: "",
	});

	const onUpdateField = e => {
		const nextFormState = {
			...registerUserObj,
			[e.target.name]: e.target.value,
		};
		setRegisterUserObj(nextFormState);
	};

	const validateFieldForm = () => {
		let fields = registerUserObj;
		let errors = {};
		let formIsValid = true;
		let emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

		// Validate First Name
		if (!fields["first_name"]) {
			formIsValid = false;
			errors["first_name"] = "*First Name is required.";
		}

		// Validate Last Name
		if (!fields["last_name"]) {
			formIsValid = false;
			errors["last_name"] = "*Last Name is required.";
		}

		// Validate Email
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Email is required.";
		} else if (!emailPattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter a valid email address.";
		}

		// Validate Verification Email
		if (!verifyPassword) {
			formIsValid = false;
			setVerifiyPassErrMsg("*Please enter your password.");
		} else if (fields["password"] !== verifyPassword) {
			formIsValid = false;
			setVerifiyPassErrMsg("*Password does not match.");
		} else {
			setVerifiyPassErrMsg(""); // Clear error if emails match
		}

		// Validate Password
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter your password.";
		} else if (fields["password"].length < 8) {
			formIsValid = false;
			errors["password"] = "*Password must be at least 8 characters long.";
		} else if (!/[A-Z]/.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must contain at least one uppercase letter.";
		} else if (!/[a-z]/.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must contain at least one lowercase letter.";
		} else if (!/[0-9]/.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must contain at least one number.";
		} else if (!/[!@#$%^&*(),.?":{}|<>]/.test(fields["password"])) {
			formIsValid = false;
			errors["password"] = "*Password must contain at least one special character.";
		}

		setError(errors);
		return formIsValid;
	};

	const submitUserDetails = event => {
		event.preventDefault();
		if (validateFieldForm()) {
			isloading(true);
			setVerifiyPassErrMsg("");
			registerSystem(registerUserObj)
				.then(res => {
					isloading(false);
					if (res.result) {
						navigate("/verification", {
							state: {
								emailId: registerUserObj?.email,
							},
						});
						toast(toastFunctionToaster(res?.message, "success"));
					} else {
						isloading(false);
						toast(toastFunctionToaster(res?.message, "error"));
					}
				})
				.catch(err => {
					isloading(false);
					if (err) toast(toastFunctionToaster(err?.response, "error"));
				});
		}
	};

	return (
		<Box p="30px" mx="auto" bg={syThemeBgBlueColor} w={{ base: "100%", md: "50%", lg: "50%", xl: "20%", sm: "100%" }}>
			<Box>
				<Img
					src={backIcon}
					onClick={() => {
						navigate(-1);
					}}
				/>
			</Box>

			<Box textAlign={"center"} pt="4rem">
				<Heading fontWeight={"600"} fontSize={"24px"} color={syWhite}>
					Welcome to Syval!
				</Heading>
				<Text my="1rem" fontWeight={"500"} fontSize={"14px"} color={syWhite}>
					tell us about you to get started
				</Text>
			</Box>
			<Box pt="2rem">
				<Box>
					<Box bg={syWhite} borderRadius={"25px"} px="15px" position={"relative"}>
						<Text pl="15px" fontSize={"14px"} position={"absolute"} zIndex={"2"} top="5px" fontWeight={600} color={syThemeLabelColor}>
							First Name
						</Text>
						<Input
							value={registerUserObj.first_name}
							name="first_name"
							onChange={onUpdateField}
							type="text"
							height={"60px"}
							fontWeight={"bold"}
							border={"none"}
							pt="10px"
							_active={{
								boxShadow: "none",
								borderColor: syWhite,
							}}
						></Input>
					</Box>
					<Text fontSize="sm" color={"red"} mt="1">
						{error?.first_name}
					</Text>
				</Box>
				{/* Last Name */}
				<Box mt="1rem">
					<Box bg={syWhite} borderRadius={"25px"} px="15px" position={"relative"}>
						<Text pl="15px" fontSize={"14px"} position={"absolute"} zIndex={"2"} top="5px" fontWeight={600} color={syThemeLabelColor}>
							Last Name
						</Text>
						<Input
							value={registerUserObj.last_name}
							name="last_name"
							onChange={onUpdateField}
							type="text"
							height={"60px"}
							fontWeight={"bold"}
							border={"none"}
							pt="10px"
							_active={{
								boxShadow: "none",
								borderColor: syWhite,
							}}
						></Input>
					</Box>
					<Text fontSize="sm" color={"red"} mt="1">
						{error?.last_name}
					</Text>
				</Box>
				{/* Email */}
				<Box>
					<Box bg={syWhite} borderRadius={"25px"} px="15px" position={"relative"} mt="1rem">
						<Text pl="15px" fontSize={"14px"} position={"absolute"} zIndex={"2"} top="5px" fontWeight={600} color={syThemeLabelColor}>
							Email
						</Text>
						<Input
							value={registerUserObj.email}
							name="email"
							onChange={onUpdateField}
							type="text"
							height={"60px"}
							fontWeight={"bold"}
							border={"none"}
							pt="10px"
							_active={{
								boxShadow: "none",
								borderColor: syWhite,
							}}
						></Input>
					</Box>
					<Text fontSize="sm" color={"red"} mt="1">
						{error?.email}
					</Text>
				</Box>

				{/* Password */}
				<Box>
					<Box bg={syWhite} borderRadius={"25px"} px="15px" position={"relative"} mt="1rem">
						<Text pl="15px" fontSize={"14px"} position={"absolute"} zIndex={"2"} top="5px" fontWeight={600} color={syThemeLabelColor}>
							Password
						</Text>
						<InputGroup>
							<Input
								type={show ? "text" : "password"}
								value={registerUserObj.password}
								name="password"
								onChange={onUpdateField}
								border={"none"}
								_active={{
									boxShadow: "none",
									borderColor: syWhite,
								}}
								pt="5px"
								height={"60px"}
							/>
							<InputRightElement width="4.5rem">
								<Button
									h="1.75rem"
									size="sm"
									onClick={() => {
										setShow(!show);
									}}
									mt="10px"
									bg="none"
									_hover={{ bg: "none" }}
								>
									{show ? <BiHide size="sm" color={syBlack} /> : <BiShow size="sm" color={syBlack} />}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Text color="red" mt="2">
						{error?.password}
					</Text>
				</Box>

				{/* Re-Enter Password */}
				<Box>
					<Box bg={syWhite} borderRadius={"25px"} px="15px" position={"relative"} mt="1rem">
						<Text pl="15px" fontSize={"14px"} position={"absolute"} zIndex={"2"} top="5px" fontWeight={600} color={syThemeLabelColor}>
							Re-Enter Your Password
						</Text>
						<InputGroup>
							<Input
								type={show1 ? "text" : "password"}
								value={verifyPassword}
								name="verifyPassword"
								onChange={e => {
									setVerifyPassword(e.target.value);
								}}
								border={"none"}
								_active={{
									boxShadow: "none",
									borderColor: syWhite,
								}}
								pt="5px"
								height={"60px"}
							/>
							<InputRightElement width="4.5rem">
								<Button
									h="1.75rem"
									size="sm"
									onClick={() => {
										setShow1(!show1);
									}}
									mt="10px"
									bg="none"
									_hover={{ bg: "none" }}
								>
									{show1 ? <BiHide size="sm" color={syBlack} /> : <BiShow size="sm" color={syBlack} />}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Text color="red" mt="2">
						{verifyPassErrMsg}
					</Text>
				</Box>

				<Box mt="2rem">
					<Checkbox
						color={syWhite}
						fontSize={"14px"}
						fontWeight={"500"}
						value={signUpflag}
						onChange={() => {
							setSignUpFlag(!signUpflag);
						}}
					>
						By signing up, you agree to the Terms of Service and Privacy Policy
					</Checkbox>
				</Box>
				<Box mt="5rem" textAlign={"center"}>
					<Button
						w="40%"
						bg={syThemeBgYellowColor}
						color={syThemeBgBlueColor}
						_hover={{ bg: syThemeBgYellowColor }}
						borderRadius={"50px"}
						size="lg"
						onClick={e => {
							submitUserDetails(e);
						}}
						isDisabled={loading || !signUpflag ? true : false}
						isLoading={loading ? true : false}
						_active={{ bg: syThemeBgYellowColor }}
					>
						Sign up
					</Button>
					<Text
						mt="1rem"
						textAlign={"center"}
						color={syWhite}
						textDecoration={"underline"}
						onClick={() => {
							navigate("/login");
						}}
					>
						Login
					</Text>
				</Box>
				{/* <Box mt="3rem">
					<Img src={divider} mx="auto" />
				</Box>
				<Flex justifyContent={"center"} mt="3rem">
					<Img src={googleIcon} mr="3" />
				</Flex> */}
			</Box>
		</Box>
	);
}

export default Login;
