// React Chakra Imports
import { Box, Img } from "@chakra-ui/react";
import { useState, useEffect } from "react";

// Component Imports
import FooterProfile from "../../common/FooterProfile";
import { syThemeBgGreenColor } from "../../../constants/color";
import Play1Img from "../../../images/genz/playground/play1.png";
import Play2Img from "../../../images/genz/playground/play2.png";
import Play3Img from "../../../images/genz/playground/play3.png";
import Play4Img from "../../../images/genz/playground/play4.png";

const Playground = () => {
	const images = [Play1Img, Play2Img, Play3Img, Play4Img];
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setActiveIndex(prevIndex => (prevIndex + 1) % images.length);
		}, 2000); // Change image every 2 seconds

		return () => clearInterval(interval); // Cleanup on unmount
	}, [images.length]);

	return (
		<Box
			bg={syThemeBgGreenColor}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
			minH="940px"
		>
			<Box>
				<Box>
					{images.map((image, index) => (
						<Img
							key={index}
							src={image}
							display={index === activeIndex ? "block" : "none"}
						/>
					))}
				</Box>
				<FooterProfile
					position={"fixed"}
					bottom={"0px"}
					borderRadius="10px"
					userRoute="/genz/user-profile"
				/>
			</Box>
		</Box>
	);
};

export default Playground;
