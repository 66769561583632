// React Chakra Imports
import Calendar from "react-calendar";
import React, { useState } from "react";
import { BiDollar } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import {
	Img,
	Box,
	Flex,
	Text,
	Input,
	Button,
	Heading,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import "react-calendar/dist/Calendar.css";
import CommonHeader from "../../common/CommonHeader";
import CommonWidget from "../../common/CommonWidget";
import FooterProfile from "../../common/FooterProfile";
import ConnectBank from "../../../images/genz/connect-bank.png";
import {
	syBlack,
	syWhite,
	syThemeBgBlueColor,
	syThemeBgYellowColor,
	syGrey,
} from "../../../constants/color";

function GoalProgress() {
	const navigate = useNavigate();

	const [date, setDate] = useState(new Date());
	const onChange = date => {
		setDate(date);
	};

	return (
		<Box
			bg={syGrey}
			w={{
				base: "100%",
				md: "50%",
				lg: "50%",
				xl: "20%",
				sm: "100%",
			}}
			mx="auto"
		>
			<Box px="20px" py="30px">
				<Flex w="100%" justifyContent={"space-between"}>
					<Box>
						<CommonHeader logoutMl={"2rem"} />
					</Box>
					<CommonWidget />
				</Flex>
				<Box bg={syThemeBgYellowColor} my="1rem" borderRadius={"10px"}>
					<Heading
						fontWeight={"bolder"}
						textAlign={"center"}
						py="2rem"
						fontSize={"24px"}
					>
						Billie Eilish LA Concert
					</Heading>
				</Box>

				<Flex my="2rem" borderRadius={"10px"}>
					<Text
						bg={syBlack}
						mr="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						#Travel
					</Text>
					<Text
						bg={syBlack}
						mr="3"
						color={syWhite}
						borderRadius={"10px"}
						textAlign={"center"}
						p="1rem"
						fontWeight={"bold"}
					>
						#Besties
					</Text>
				</Flex>

				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syWhite}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						fontSize={"20px"}
						fontWeight={"700"}
						color={syBlack}
					>
						Total Savings Goal
					</Heading>
					<Heading
						fontSize={"24px"}
						fontWeight={"700"}
						mt="1rem"
						color={syThemeBgBlueColor}
					>
						$2000
					</Heading>
					<Heading
						fontSize={"18px"}
						fontWeight={"700"}
						mt="1rem"
						color={syBlack}
					>
						Edit
					</Heading>
				</Box>

				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syThemeBgBlueColor}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						textAlign={"center"}
						fontSize={"18px"}
						fontWeight={"700"}
						color={syWhite}
					>
						Save Now
					</Heading>
					<Heading
						textAlign={"center"}
						fontSize={"26px"}
						fontWeight={"700"}
						color={syThemeBgYellowColor}
						mt="2rem"
					>
						$ 250{" "}
						<Text
							as="span"
							fontSize={"16px"}
							mx="2"
							color={syWhite}
						>
							Per Week
						</Text>{" "}
					</Heading>
					<InputGroup
						border="none"
						bg={syWhite}
						borderRadius={"10px"}
						h="50px"
						mt="1rem"
					>
						<InputLeftElement pointerEvents="none" top="5px">
							<BiDollar
								color={syThemeBgBlueColor}
								fontSize={"24px"}
							/>
						</InputLeftElement>
						<Input
							type="text"
							placeholder=""
							fontWeight={"bold"}
							color={syGrey}
							border="0"
							_hover={{ boxShadow: "none" }}
							textAlign={"center"}
							h="50px"
						/>
					</InputGroup>
					<Img src={ConnectBank} mt="1rem" />
				</Box>

				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syWhite}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						textAlign={"center"}
						fontSize={"18px"}
						fontWeight={"700"}
						color={syBlack}
					>
						My Progress
					</Heading>
					<Heading
						textAlign={"center"}
						fontSize={"26px"}
						fontWeight={"700"}
						color={syThemeBgBlueColor}
						mt="1rem"
					>
						$500
					</Heading>
				</Box>

				<Box
					mt="1rem"
					px="20px"
					py="30px"
					bg={syWhite}
					borderRadius={"8px"}
					textAlign={"center"}
				>
					<Heading
						textAlign={"center"}
						fontSize={"18px"}
						fontWeight={"700"}
						color={syBlack}
					>
						My Savings History
					</Heading>
					<Box mt="1rem">
						<Calendar onChange={onChange} value={date} />
					</Box>
				</Box>
				<Box textAlign={"center"} mt="2rem" mb="4rem">
					<Button
						borderRadius={"50px"}
						size="lg"
						w="80%"
						bg={syThemeBgYellowColor}
						color={syBlack}
						boxShadow={"xs"}
						onClick={() => {
							navigate("/genz/goal-progress");
						}}
					>
						Let's do it
					</Button>
				</Box>
			</Box>

			<FooterProfile
				position={"fixed"}
				bottom={"0px"}
				borderRadius="10px"
			/>
		</Box>
	);
}

export default GoalProgress;
